import NextConfig from '@/config';

const { publicRuntimeConfig } = NextConfig;
const { referrerQuery, inviteQuery, utmKey } = publicRuntimeConfig;

export const apiQueryKeys = [referrerQuery, inviteQuery, utmKey, 'hr', 'lp'];

/**
 * Generate API headers from query parameters.
 * @param apiQuery - The query object containing key-value pairs.
 * @returns The generated headers as an object.
 */
export function generateApiHeaders(apiQuery: Record<string, unknown>): Record<string, string> {
  const headers: Record<string, string> = {};

  apiQueryKeys.forEach((key) => {
    const value = apiQuery[key];
    if (value) {
      headers[`x-refrens-qs-${key}`] = typeof value === 'string' ? value : JSON.stringify(value);
    }
  });

  return headers;
}
