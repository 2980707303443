import React from 'react';
import { Typography, UserAvatar } from '@refrens/disco';
import getOptimizedImage from '@refrens/birds/src/helpers/get-optimized-image';
import getSrcSet from '@refrens/birds/src/helpers/get-src-set';
import Nbsp from 'react-nbsp';

import {
  Wrapper,
  TextWrapper,
  AvatarWrapper,
  BodyWrapper,
} from '@/components/styles/NotificationTemplateStyles';
import NextConfig from '@/config';
import ButtonGroupRenderer from './ButtonGroupRenderer';

const { publicRuntimeConfig } = NextConfig;
const { isRefrensProd, imageUrl } = publicRuntimeConfig;
const { public: optimizedImageUrl } = imageUrl || {};

const NewLeadAutoAssign = (props) => {
  const { params, actions } = props;
  const { customerName, actorUser, actorBusiness, leadSubject, leadOrigin } = params || {};
  const { name: userName, avatar } = actorUser || {};
  const { name: bizName, logo } = actorBusiness || {};

  const notificationAvatar = avatar || logo;
  const notificationName = userName || bizName;

  return (
    <Wrapper>
      <AvatarWrapper>
        <UserAvatar
          size={42}
          src={
            notificationAvatar &&
            getOptimizedImage(
              notificationAvatar,
              { w: 88, h: 88 },
              isRefrensProd,
              optimizedImageUrl,
            )
          }
          srcSet={
            notificationAvatar &&
            getSrcSet(
              notificationAvatar,
              [
                { w: 88, h: 88 },
                { w: 42, h: 42 },
              ],
              isRefrensProd,
              optimizedImageUrl,
            )
          }
          name={notificationName}
        />
      </AvatarWrapper>
      <BodyWrapper>
        <TextWrapper>
          <Typography inline size='h5'>
            {`You have a new lead from ${customerName} for ${actorBusiness.name} via ${leadOrigin}`}
          </Typography>
          <Nbsp />
          <Typography size='span' variant='subtle'>
            {leadSubject}
          </Typography>
        </TextWrapper>
        {!!actions?.length && <ButtonGroupRenderer actions={actions} />}
      </BodyWrapper>
    </Wrapper>
  );
};

export default NewLeadAutoAssign;
