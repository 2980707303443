import React from 'react';
import { Typography } from '@refrens/disco';
import Nbsp from 'react-nbsp';
import { inject, observer } from 'mobx-react';
import { ProfileWrapper, TextWrapper } from '@/components/styles/NotificationTemplateStyles';

const ProfileBadgeAwarded = (props) => {
  const { params } = props;
  const { profile } = params || {};
  const { name: profileName = '' } = profile || {};

  return (
    <ProfileWrapper>
      <TextWrapper>
        <Typography inline size='h5'>
          Congratulations!
        </Typography>
        <Nbsp />
        <Typography bold inline size='h5'>
          {`${profileName || 'Your Profile'}`}
        </Typography>
        <Nbsp />
        <Typography inline size='h5'>
          is Featured Profile of the month. Share it to celebrate with your network 🎉
        </Typography>
      </TextWrapper>
    </ProfileWrapper>
  );
};

export default inject('store')(observer(ProfileBadgeAwarded));
