export default function getSidebarRoute(routeName) {
  if (routeName === 'LeadsPipeline') {
    return 'BusinessCustomerLeads';
  }
  if (routeName === 'ClientManagement') {
    return 'ManageClient';
  }

  return routeName;
}
