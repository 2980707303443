import React, { useState, useEffect, useCallback, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'cropperjs/dist/cropper.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'react-tooltip/dist/react-tooltip.css';

import Script from 'next/script';
import { configure } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import awaitGlobal from 'await-global';
import delegate from 'delegate';
import { ThemeProvider as DiscoThemeProvider, DiscoProvider, isBrowser } from '@refrens/disco';
import {
  AppType,
  JupiterProvider,
  redirectToPremium,
  autoTrack,
  AuroraFlags,
  refrensApiGuru,
} from '@refrens/jupiter';
import loadScript from 'tiny-script-loader/loadScriptPromised';
import debounce from 'lodash/debounce';
import { Ability } from '@casl/ability';
import appendQuery from 'append-query';
import urlJoin from 'url-join';

import NextConfig from '@/config';
import { StoreFactory } from '@/store';
import theme from '@/lib/theme';
import GlobalStyles from '@/lib/globalstyles';
import TelInputStyles from '@/lib/telStyles';
import NProgress from '@/lib/nprogress';
import Page from '@/components/layout/page';
import ignoreErrorList from '@/helpers/ignoreErrorList';
import { Link } from '@/router';
import { sentryInit } from '@/helpers/sentry';
import isDibella from '@/helpers/isDibella';
import getFy from '@/helpers/fy/getFy';
import constants from '@/constants/settings';
import PageLoader from '@/components/layout/PageLoader';
import useClientStateHydration from '@/lib/nextJs/useClientStateHydration';
import ErrorBoundary from '@/components/layout/ErrorBoundary';

configure({
  enforceActions: 'never',
});

const { localStorageVariables } = constants;

const { publicRuntimeConfig } = NextConfig;

const {
  nodeEnv,
  sentryDSN,
  refrensEnv,
  referrerQuery,
  ucStateKey,
  invisibleRecaptchaKey,
  googleClientId,
  googleClientIdMultiScope,
  outlookClientId,
  recaptchaKey,
  urlTokenQuery,
  requirementHydrationKey,
  baseDomain,
  isRefrensProd,
  isRefrensStage,
  apiDomain,
  venusUrl,
  superReferrerUrlKey,
  refrensPremiumUrlKey,
  publicDomain,
  cashfreeSdk,
  cashfreeSdkV3,
  imageUrl,
  ga4Property,
  akatoshTracker,
  webengageKey,
} = publicRuntimeConfig;

refrensApiGuru.configure(
  {
    apiDomain,
    debug: nodeEnv === 'development',
    callbacks: {
      onResponseStart: () => {
        try {
          if (isBrowser()) {
            NProgress.start();
          }
        } catch (err) {
          // nop
        }
      },
      onResponseEnd: () => {
        try {
          if (isBrowser()) {
            NProgress.done();
          }
        } catch (err) {
          // nop
        }
      },
    },
  },
  true,
);

const { public: optimizedImageBaseUrl } = imageUrl || {};

sentryInit(refrensEnv, sentryDSN);

const interactions = ['mousemove', 'scroll', 'keydown', 'click', 'touchstart'];

const ability = new Ability();

const accessibility = new Ability();

/**
 * @type {React.FC<import('../lib/nextJs/types').CustomAppProps>}
 */
const MyApp = (props) => {
  const { Component, pageProps: initialPageProps } = props;

  const [business, setBusiness] = useState(null);

  // Permissions are fetched from the service and stored in this variable
  const [permissions, setPermissions] = useState([]);

  const [wfSourcePermissions, setWfSourcePermissions] = useState([]);

  // Indicates if the permissions are fetched from the service and updated in the context
  const [permissionsReady, setPermissionsReady] = useState(false);

  // Premium features accessibility rule set are fetched from the service and stored in this variable
  const [paywall, setPaywall] = useState([]);

  // Indicates if the premium features accessibility rule set are fetched from the service and updated in the context
  const [accessibilityReady, setAccessibilityReady] = useState(false);

  /**
   * This flag indicates denial of permissions from the service for the current user
   * Used to render error screen and redirect to dashboard if user is logged in
   */
  const [permissionsDenied, setPermissionsDenied] = useState(false);

  /**
   * This flag indicates denial of premium features accessibility from the service for the current user
   * Used to render error screen and redirect to dashboard if user is logged in
   */
  const [accessibilityDenied, setAccessibilityDenied] = useState(false);

  const [userScriptsLoaded, setUserScriptsLoaded] = useState(false);

  const [setupAppCalled, setSetupAppCalled] = useState(false);

  const [crispReady, setCrispReady] = useState(false);

  const [store, setStore] = useState(() =>
    initialPageProps?.isServerRendered
      ? StoreFactory(initialPageProps?.initialState, false, true) // initialize a dry state store for SSR rendered pages
      : null,
  );

  const pageConfig = useClientStateHydration(props, store, setStore);

  const {
    ready: isPageReady,
    pauseComponentRender,
    isServerRendered,
    componentIsSSR,
    pageProps,
    statusCode,
    isNetworkError,
    initialState,
    isRouting,
    uniquePagePropsMatcher,
  } = pageConfig || {};

  const prevInitialState = useRef(initialState);
  const previousBusinessUrlKey = useRef(undefined);
  const userScriptsLoadCalledRef = useRef(false);
  /**
   * This ref is used to store a flag to indicate if the payment gateway sdks are loaded
   */
  const pgSdksLoadedRef = useRef(false);
  /**
   * This ref is used to store an array of interaction listeners to remove them when the component is unmounted
   */
  const scriptInteractionListenersRef = useRef(null);
  /**
   * This ref is used to store the visibility change listener function to remove it when the component is unmounted
   */
  const visibilityChangeListenerRef = useRef(null);

  /**
   * Fetches permissions for the given business url key.
   * If no url key is provided, sets permissions to empty and ready state.
   * NOTE: permissions ready is necessary in page.js to appropriately render the page components. route with no business present will otherwise won't have permission ready ever and would be stuck in loading state
   */
  const getPermission = async (urlKey, isBizUser, sourceDocument) => {
    setPermissionsDenied(false);
    try {
      if (!urlKey) {
        throw new Error('URL key is required');
      }

      if (!isBizUser) {
        const error = new Error('User does not have access to this business');
        error.response = { status: 403 };
        throw error;
      }

      setPermissionsReady(false);
      const permissionsResponse = await store.api.get(`/permissions/${urlKey}`);
      if (sourceDocument) {
        const permissionUri = appendQuery(`/permissions/${urlKey}`, { sourceDocument });
        const wfPermissions = await store.api.get(permissionUri);
        setWfSourcePermissions(wfPermissions.data);
      } else {
        setWfSourcePermissions([]);
      }
      setPermissions(permissionsResponse.data);
      ability.update(permissionsResponse.data);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setPermissionsDenied(true);
      }
      setPermissions([]);
      setWfSourcePermissions([]);
      ability.update([]);
    } finally {
      // update after a short delay to allow the permissions to be updated
      setTimeout(() => {
        setPermissionsReady(true);
      }, 100);

      store.setSuggestionCount(business?._id, !!ability.can('find', 'leads'));
    }
  };

  /**
   * Fetches premium features accessibility for the given business url key.
   * If no url key is provided, sets accessibility to empty and ready state.
   * make accessibility ready and empty if no url key is provided
   */
  const getPremiumAccessibility = async (urlKey, isBizUser) => {
    setAccessibilityDenied(false);
    try {
      if (!urlKey) {
        throw new Error('URL key is required');
      }

      if (!isBizUser) {
        const error = new Error('User does not have access to this business');
        error.response = { status: 403 };
        throw error;
      }

      setAccessibilityReady(false);
      const accessibilityData = await store.api.get(`/paywall/${urlKey}`);
      setPaywall(accessibilityData.data);
      accessibility.update(accessibilityData.data);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setAccessibilityDenied(true);
      }
      setPaywall({});
      accessibility.update([]);
    } finally {
      // update after a short delay to allow the accessibility to be updated
      setTimeout(() => {
        setAccessibilityReady(true);
      }, 100);
    }
  };

  // if no url key is provided, set account manager to null
  const getAccountManager = async (urlKey, isBizUser) => {
    try {
      if (!urlKey) {
        throw new Error('URL key is required');
      }

      if (!isBizUser) {
        throw new Error('User does not have access to this business');
      }

      const businessAccountManager = await store.api.get(`/account-manager/${urlKey}`);
      const { accountManager } = businessAccountManager?.data || {};
      if (accountManager?.name) {
        store.refrensAccountManager = accountManager;
      } else {
        store.refrensAccountManager = null;
      }
    } catch (err) {
      // nop
    }
  };

  const fetchAndSetBusiness = useCallback(
    async (urlKey) => {
      const biz = await store?.api.get(`/businesses/${urlKey}`);
      if (biz?.data) {
        setBusiness(biz.data);
        store.business = biz.data;
      }
    },
    [store?.api],
  );

  const getBusiness = async (forceUpdate) => {
    const { business: storeBiz } = store;
    const { activeBusinessResponse, businessResponse = activeBusinessResponse } = pageProps || {};

    if (forceUpdate) {
      await fetchAndSetBusiness(storeBiz.urlKey);
    } else if (storeBiz && businessResponse) {
      if (!business || business.urlKey !== businessResponse.urlKey) {
        setBusiness(businessResponse);
        store.business = businessResponse;
      }
    } else if (storeBiz && business && business?.urlKey !== storeBiz.urlKey) {
      await fetchAndSetBusiness(storeBiz.urlKey);
    }
  };

  const onCrispReady = () => {
    if (store) {
      store.crispReady = true;
    }
    setCrispReady(true);
  };

  const crispInit = useCallback(() => {
    awaitGlobal('$crisp')
      .then(($crisp) => {
        $crisp.push(['on', 'session:loaded', onCrispReady]);
        if ($crisp.is && $crisp.is('chat:visible')) {
          onCrispReady();
        }
      })
      .catch(() => {
        // nop
      });
  }, [onCrispReady]);

  const cashFreeInit = () => {
    if (store) {
      // prev api was deprecated, so using the current sdk to check cashfree script is loaded( returns Cashfree function )
      store.cashfreeInitialized = true;
    }
  };

  const loadPaymentGatewaySdks = () => {
    if (pgSdksLoadedRef.current) {
      return;
    }
    const { isAppUser } = store || {};
    const isAurora = AuroraFlags.isAurora();
    if (isAppUser || isAurora) {
      return;
    }

    if (cashfreeSdkV3) {
      loadScript(cashfreeSdkV3)
        .then(cashFreeInit)
        .catch(() => {
          // nop
        });
    } else {
      loadScript(cashfreeSdk)
        .then(cashFreeInit)
        .catch(() => {
          // nop
        });
    }

    loadScript('https://checkout.razorpay.com/v1/checkout.js').catch(() => {
      // nop
    });

    pgSdksLoadedRef.current = true;
  };

  const loadUserScripts = useCallback(
    debounce(() => {
      if (userScriptsLoadCalledRef.current || userScriptsLoaded) {
        return;
      }
      // maintaining ref to prevent multiple calls to loadUserScripts since it could be called on multiple events and state change for `userScriptsLoaded` would need a re-render to be effective
      userScriptsLoadCalledRef.current = true;

      const { isAppUser } = store || {};
      const isAurora = AuroraFlags.isAurora();

      if (!isAppUser) {
        if (isAurora) {
          // don't load crisp for aurora
          onCrispReady();
        } else if (isRefrensProd) {
          loadScript('https://client.crisp.chat/l.js')
            .then(crispInit)
            .catch(() => {
              // nop
            });
        }

        if (Component.withPaymentGatewaySdks) {
          loadPaymentGatewaySdks();
        }
      }

      if (isRefrensProd && !isAurora) {
        loadScript('https://browser-update.org/update.min.js').catch(() => {
          // nop
        });
      }

      setUserScriptsLoaded(true);
    }, 100),
    [
      store?.isAppUser,
      userScriptsLoaded,
      Component.withPaymentGatewaySdks,
      loadPaymentGatewaySdks,
      onCrispReady,
      crispInit,
      cashFreeInit,
    ],
  );

  /**
   * This method is called when the page visibility changes.
   * If the page is visible, it checks if the userId in the token in the API instance and the userID in cookie matches.
   *
   * This is done to ensure that the auth state is in sync with the cookie and prevents errors when login changes and multiple tabs of Lydia are open.
   */
  const handleVisibilityChange = () => {
    if (isBrowser()) {
      store.pageVisible = document.visibilityState === 'visible';
      if (document.visibilityState === 'visible') {
        if (!refrensApiGuru.checkTokenIntegrity()) {
          window.location.reload();
        }
      }
    }
  };

  const handleGlobalError = useCallback((message, source, lineno, colno, error) => {
    // Log the error or perform any other desired actions
    if (ignoreErrorList.includes(message)) {
      return true;
    }
    // eslint-disable-next-line no-console
    console.error('Global Error:', { message, source, lineno, colno, error });
    // eslint-enable-next-line no-console
    return true;
  }, []);

  /**
   * Fetch business and setup listeners for user interactions,
   * This method should only be called very selectively (either on page load or on auth change),
   * Page is 'loaded' when we want to force render SSR content, or when we perform a reload transition for client-side hydration.
   * This method will update `setupAppCalled` to true after the setup is complete, which would cause side effects to run,
   */
  const setupApp = async () => {
    if (isBrowser()) {
      // add visibility change listener to check if the page is visible
      document.addEventListener('visibilitychange', handleVisibilityChange);
      visibilityChangeListenerRef.current = () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
      window.onerror = handleGlobalError;
    }

    await getBusiness();

    if (!isDibella()) {
      if (!Component.LeanShell && !userScriptsLoaded) {
        // load user scripts only if the component does not request a lean shell
        scriptInteractionListenersRef.current = interactions.map((event) => {
          document.addEventListener(event, loadUserScripts);
          // store the remove listener function for cleanup
          return () => document.removeEventListener(event, loadUserScripts);
        });
      }

      delegate('[data-ga-on]', 'click', autoTrack, false);

      // HTMLCanvasElement.toBlob shim, required for signature draw on ie 11 and other older browsers
      // eslint-disable-next-line no-unused-expressions
      import('blueimp-canvas-to-blob');
      try {
        // eslint-disable-next-line no-new
        new File(['f'], 'f.txt', { type: 'text/plain' });
      } catch (e) {
        store.isNewFileSupported = false;
      }
    }

    store?.configureSuprSendSdk();

    setSetupAppCalled(true);
  };

  const setSelectedFy = (fyResponse) => {
    const { activeBusinessResponse = {}, businessResponse = activeBusinessResponse } =
      pageProps || {};
    const storeFy = localStorage.getItem(localStorageVariables.SelectedFy);
    let updatedFy = {};
    const parsedStoreData = JSON.parse(storeFy);

    // if the business selected fy in storage is same as the current business
    // then use the stored fy
    if (
      parsedStoreData &&
      parsedStoreData.business === businessResponse?.urlKey &&
      parsedStoreData.start_date &&
      parsedStoreData.end_date
    ) {
      updatedFy = parsedStoreData;
    } else if (fyResponse?.[0]?.label && fyResponse?.[0]?.id) {
      updatedFy = {
        label: fyResponse[0]?.label,
        id: fyResponse[0]?.id,
        start_date: fyResponse[0]?.start_date,
        end_date: fyResponse[0]?.end_date,
      };

      localStorage.setItem(
        localStorageVariables.SelectedFy,
        JSON.stringify({
          label: fyResponse[0]?.label,
          id: fyResponse[0]?.id,
          business: businessResponse?.urlKey || '',
          start_date: fyResponse[0]?.start_date,
          end_date: fyResponse[0]?.end_date,
        }),
      );
    }

    store.selectedFy = updatedFy;
  };

  const setFinancialYears = async (businessUrl) => {
    if (businessUrl) {
      const fyResponse = await getFy({
        store,
        urlKey: businessUrl,
        filters: {
          $sort: {
            start_date: -1,
          },
        },
      });

      if (fyResponse?.data?.length) {
        store.financialYears = fyResponse?.data;
      } else {
        store.financialYears = [];
      }

      setSelectedFy(fyResponse?.data?.length ? fyResponse?.data : undefined);
    }
  };

  /**
   * This method is called when the component mounts or when the page is 'reloaded'.
   */
  const onComponentMount = () => {
    store.refreshPageBusiness = getBusiness;
    previousBusinessUrlKey.current = undefined;

    setupApp();
  };

  useEffect(() => {
    return () => {
      store?.dispose(); // clean up store
      refrensApiGuru.cleanup(); // clean up api class
      if (isBrowser()) {
        window.onerror = null;
        if (visibilityChangeListenerRef.current) {
          visibilityChangeListenerRef.current();
        }
        if (
          scriptInteractionListenersRef.current &&
          scriptInteractionListenersRef.current?.length
        ) {
          scriptInteractionListenersRef.current.forEach((removeListener) => removeListener?.());
        }
      }
      awaitGlobal('$crisp').then(($crisp) => {
        $crisp.push(['off', 'session:loaded']);
      });
    };
  }, []);

  /**
   * Handling the mount and 'reload' of the component in this effect.
   * Also handling the cleanup of the component in this effect.
   */
  useEffect(() => {
    if (!isServerRendered && isPageReady && !setupAppCalled) {
      onComponentMount();
    }
  }, [isPageReady, isServerRendered]);

  /**
   * This effect is used to load payment gateway sdks for components that require it if not already loaded after app setup.
   */
  useEffect(() => {
    if (setupAppCalled && Component.withPaymentGatewaySdks && !pgSdksLoadedRef.current) {
      loadPaymentGatewaySdks();
    }
  }, [Component.withPaymentGatewaySdks]);

  /**
   * This effect is used to remove the interaction listeners when the user scripts are loaded.
   */
  useEffect(() => {
    if (userScriptsLoaded) {
      if (scriptInteractionListenersRef.current && scriptInteractionListenersRef.current?.length) {
        scriptInteractionListenersRef.current.forEach((removeListener) => removeListener?.());
      }
    }
  }, [userScriptsLoaded]);

  const requestTrial = useCallback(
    async (reason) => {
      if (store?.business?.urlKey) {
        await store.api.post(`/businesses/${store.business.urlKey}/subscriptions`, {
          trial: true,
          reason,
        });
        return true;
      }
      return Promise.resolve(false);
    },
    [store?.business?.urlKey, store?.api],
  );

  const unlockedFeature = useCallback(
    async (feature) => {
      if (store?.business?.urlKey && permissionsReady && ability.can('ui.edit.yes', 'business')) {
        await store.api.patch(`/businesses/${store.business.urlKey}`, {
          [`featuresUnlocked.${feature}`]: true,
        });
        return true;
      }
      return Promise.resolve(false);
    },
    [store?.business?.urlKey, store?.api, permissionsReady],
  );

  /**
   * @param {menuItem}
   * adding visited sidebar menu items to preferences to remove
     new tag from items i.e if user visits new tag item it will be
     added to preferences visited menu item and new tag will be removed}
   * @returns boolean status
   */
  const visitedMenuItems = async (menuItem) => {
    if (store?.auth && menuItem) {
      await store.api.patch(`/users/me`, {
        $addToSet: {
          'preferences.sidebar.visitedMenuItems': menuItem,
        },
      });
      const { visitedMenuItems: visited = [] } = store.auth?.preferences?.sidebar || {};
      if (store.auth?.preferences?.sidebar) {
        store.auth.preferences.sidebar.visitedMenuItems = [
          ...new Set([...(visited || []), menuItem]),
        ];
      }
      return true;
    }
    return Promise.resolve(false);
  };

  const requestPremium = (feature, planType) => {
    if (store?.business) {
      const { billedTo = {}, country, premium = {} } = store.business;
      const { activated = false } = premium || {};
      const bizCountry = billedTo?.country || country;
      const premiumPayload = { feature, priority: 1 };

      // user who never activated premium
      if (!activated) {
        premiumPayload.priority = 2;
      }

      store.api.post(
        appendQuery(`/businesses/${store.business.urlKey}/premiumn`, { forced: true }),
        premiumPayload,
      );
      redirectToPremium(bizCountry, store.business.urlKey, planType);
    }
  };

  const requestOutlookAccessCode = useCallback(
    async (authCode) => {
      if (store?.business) {
        await store.api.post(`/businesses/${store.business.urlKey}/sending-identities`, {
          authCode,
          applicationType: 'OUTLOOK',
        });
      }
    },
    [store?.business?.urlKey, store?.api],
  );

  const getFileSignedUrl = useCallback(
    async ({ s3Params, fileUrl, fileName }) => {
      let signedUrl = fileUrl || '';
      let s3FileName = fileName || '';
      try {
        if (!fileUrl || typeof fileUrl !== 'string') {
          return signedUrl;
        }
        if (
          !s3Params ||
          s3Params?.acl !== 'private' ||
          !s3Params?.pathPrefix ||
          !s3Params?.pathPrefixDigest
        ) {
          // if ACL is not private or required params are not present, then we don't need to get signed url
          return signedUrl;
        }
        const urlObj = new URL(signedUrl);
        if (urlObj.hostname !== 's3.ap-south-1.amazonaws.com') {
          // if fileUrl is not s3 url then we don't need to get signed url
          return signedUrl;
        }
        if (urlObj.search?.includes('X-Amz-Signature')) {
          // if signed url is already present in the fileUrl, then we don't need to get signed url
          return signedUrl;
        }
        if (!s3FileName) {
          // if file name is not provided, then extract it from pathname
          s3FileName = urlObj.pathname.split('/').pop();
        }
        const signedUrlRes = await store.api.post(
          appendQuery('uploadrequest', { getSignedUrl: true }),
          {
            pathPrefix: s3Params.pathPrefix,
            pathPrefixDigest: s3Params.pathPrefixDigest,
            fileName: s3FileName,
          },
        );
        if (signedUrlRes.data) {
          signedUrl = signedUrlRes.data;
        }
        return signedUrl;
      } catch (err) {
        return signedUrl;
      }
    },
    [store?.api],
  );

  /**
   * This effect is used to fetch permissions and accessibility and other misc business data when the business url key changes. We only run this effect after setupApp is called.
   */
  useEffect(() => {
    if (!isPageReady || !setupAppCalled) {
      return;
    }

    if (Component?.LeanShell) {
      // lean shell components are not dependent on business/auth data
      setPermissionsReady(true);
      setAccessibilityReady(true);
    }

    /**
     * Run when business url has changed or is NOT present
     * Fetch permissions and accessibility. When business is not present
     * NOTE - Permissions and Accessibility are set to ready and empty to avoid infinite loading state as per logic in `page.js`
     */
    if (!business?.urlKey || business?.urlKey !== previousBusinessUrlKey.current) {
      const { auth } = store || {};
      const { businesses } = auth || {};
      const { urlKey, configuration } = business || {};
      const { syncAccounting } = configuration || {};
      const { accountingSetup } = syncAccounting || {};
      const isBusinessUser = urlKey && businesses.some((b) => b.urlKey === urlKey);
      let sourceDoc;
      if (Component.sourceDocument) {
        sourceDoc = Component.sourceDocument(pageProps);
      }
      getPermission(business?.urlKey, isBusinessUser, sourceDoc);
      getPremiumAccessibility(business?.urlKey, isBusinessUser);

      // Run when business url has changed and is present
      if (business?.urlKey) {
        getAccountManager(business.urlKey, isBusinessUser);

        // set financial years only when accounting setup is done
        if (accountingSetup?.status === 'DONE') {
          setFinancialYears(business.urlKey);
        }
      }
    }
    previousBusinessUrlKey.current = business?.urlKey;
  }, [isPageReady, setupAppCalled, business?.urlKey, Component?.LeanShell]);

  useEffect(() => {
    if (!isPageReady) {
      return;
    }
    if (isRouting && store) {
      // this will reset the premium popup on page change
      store.showPremiumPopup('', false, undefined, {});
      // getBusiness();
    }
  }, [isPageReady, isRouting]);

  /**
   * This effect is used to reinitialize the store when the token changes.
   */
  useEffect(() => {
    if (!isPageReady || !setupAppCalled) {
      return;
    }
    if (
      // (!prevInitialState?.token && !!initialState?.token) ||
      // (!!prevInitialState?.token && !initialState?.token) ||
      prevInitialState?.auth?._id !== initialState?.auth?._id
    ) {
      // reinitialize store if token's presence changes, or if the user changes
      store?.initStore(initialState).then(() => {
        setupApp();
      });
      prevInitialState.current = initialState;
    }
  }, [isPageReady, initialState, setupAppCalled]);

  useEffect(() => {
    if (isPageReady && store) {
      store.showNetworkNotification = isNetworkError;
    }
  }, [isPageReady, isNetworkError]);

  const jupiterConfig = {
    Link,
    app: AppType.LYDIA,
    config: {
      baseUrl: baseDomain,
      oauthDomain: apiDomain,
      referrerQuery,
      venusUrl,
      ucStateKey,
      recaptcha: {
        invisibleSitekey: invisibleRecaptchaKey,
        visibleSitekey: recaptchaKey,
      },
      googleClientId,
      googleClientIdMultiScope,
      outlook: {
        clientId: outlookClientId,
        redirectUri: urlJoin(baseDomain, 'app', 'oauth', 'callback'),
        scope: 'https://graph.microsoft.com/mail.send offline_access openid profile email',
      },
      requirementHydrationKey,
      urlTokenQuery,
      isRefrensProd,
      superReferrerUrlKey,
      refrensPremiumUrlKey,
      publicDomain,
      optimizedImageBaseUrl,
    },
    crispReady,
    openHelp: store?.openHelp,
    requestTrial,
    requestPremium,
    unlockedFeature,
    visitedMenuItems,
    onAuthSubmit: store?.authenticate,
    validateSignupEmail: store?.checkExistingEmail,
    requestOutlookAccessCode,
  };

  const jupiterData = {
    activeBusiness: business,
    permissions,
    paywall,
  };

  const showSkeleton =
    !!pauseComponentRender ||
    (!!uniquePagePropsMatcher && uniquePagePropsMatcher !== Component.pagePropsMatcher);

  return (
    <>
      <DiscoThemeProvider mode='light'>
        {/* styled-component ThemeProvider */}
        <DiscoProvider value={{ getFileSignedUrl }}>
          {Component.LeanShell && statusCode < 400 && !pageProps?.withLeanShellProviders ? (
            <>
              {isPageReady ? (
                <ThemeProvider theme={{ lydia: theme }}>
                  {!showSkeleton && <Component {...pageProps} />}
                </ThemeProvider>
              ) : (
                <PageLoader />
              )}
            </>
          ) : (
            <JupiterProvider config={jupiterConfig} data={jupiterData}>
              <ThemeProvider theme={{ lydia: theme }}>
                {isPageReady ? (
                  <>
                    {/* Mobx Root Store Global state Provider */}
                    <Provider store={store}>
                      <Page
                        pageName={Component.pageName}
                        withoutLogin={Component.withoutLogin}
                        isStatelessAllowed={Component.isStatelessAllowed}
                        withoutHeader={!setupAppCalled || Component.withoutHeader}
                        withoutFooter={showSkeleton || Component.withoutFooter}
                        withoutLearnMore={Component.withoutLearnMore}
                        withoutMarketPlaceBtn={Component.withoutMarketPlaceBtn}
                        withoutVerifyPrompt={Component.withoutVerifyPrompt}
                        withoutAutoScrollTop={Component.withoutAutoScrollTop}
                        withoutScroller={Component.withoutScroller}
                        withPersistentMobileHeader={
                          showSkeleton || Component.withPersistentMobileHeader
                        }
                        withoutAside={Component.withoutAside}
                        withRefrensTagline={Component.withRefrensTagline} // appears at he header beside logo
                        withCollaspedAside={Component.withCollaspedAside}
                        withStickyFooter={Component.withStickyFooter}
                        withoutProducts={Component.withoutProducts}
                        withoutNotifications={Component.withoutNotifications}
                        withoutAuthButtons={Component.withoutAuthButtons}
                        withoutInvite={Component.withoutInvite}
                        isLargePage={Component.isLargePage}
                        isFullWidthPage={Component.isFullWidthPage}
                        isXScrollPage={Component.isXScrollPage}
                        withoutPadding={Component.withoutPadding}
                        pageBackground={Component.pageBackground}
                        statusCode={statusCode}
                        pageProps={pageProps || {}}
                        isNetworkError={isNetworkError}
                        activeBusiness={business}
                        permissionsReady={permissionsReady}
                        accessibilityReady={accessibilityReady}
                        accessibilityDenied={accessibilityDenied}
                        permissionsDenied={permissionsDenied}
                        pagePermissions={Component.permissions}
                        wfSourcePermissions={wfSourcePermissions}
                        isProxyAllowed={Component.isProxyAllowed}
                        isServerRendered={isServerRendered}
                        forceRenderSSR={initialPageProps?.componentIsSSR || false}
                        isComponentSSR={!!componentIsSSR}
                        dataWithStickyToolbar={Component.withStickyToolBar}
                        withoutGoogleOneTap={!!Component.withoutGoogleOneTap}
                        showSkeleton={showSkeleton}
                      >
                        <ErrorBoundary>
                          {!showSkeleton && <Component {...pageProps} />}
                        </ErrorBoundary>
                      </Page>
                    </Provider>
                  </>
                ) : (
                  <PageLoader />
                )}
                <GlobalStyles />
                <TelInputStyles />
              </ThemeProvider>
            </JupiterProvider>
          )}
        </DiscoProvider>
      </DiscoThemeProvider>
      <Script
        id='recaptcha-script'
        async
        src={`https://www.google.com/recaptcha/api.js?render=${invisibleRecaptchaKey}`}
        strategy='lazyOnload'
      />
      {!Component.LeanShell && (
        <>
          <Script id='ga-script' async src='https://www.google-analytics.com/analytics.js' />
          <Script
            id='gtag-script'
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${ga4Property}`}
            strategy='lazyOnload'
          />
          {isRefrensProd && <Script async src={akatoshTracker} />}
          {(isRefrensProd || isRefrensStage) && (
            <Script
              id='_webengage_script_tag'
              dangerouslySetInnerHTML={{
                __html: `var webengage;!function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="feedback survey notification".split(z),c="options render clear abort".split(z),p="Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],is_spa:1,__v:"6.0",user:{}},i=0;i < l.length;i++)o(r,[l[i]]);for(i=0;i < a.length;i++){for(r[a[i]]={},s=0;s < c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s < p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i < u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://ssl.widgets.webengage.com":"http://cdn.widgets.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}(window,document,"webengage");webengage.init("${webengageKey}");`,
              }}
              strategy='lazyOnload'
            />
          )}
        </>
      )}
    </>
  );
};

export default observer(MyApp);
