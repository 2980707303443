import appendQuery from 'append-query';

const getFy = async ({ store, filters = {}, urlKey }) => {
  const response = { data: null, error: null };
  const apiUrl = `/businesses/${urlKey}/saturn/financial-years`;

  try {
    const fyResponse = await store?.api?.get?.(appendQuery(apiUrl, filters));

    response.data = fyResponse?.data?.data;
  } catch (err) {
    response.error = err;
  }

  return response;
};

export default getFy;
