const ignoreErrorList = [
  'Uncaught Error: Hydration failed because the initial UI does not match what was rendered on the server.',
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
  'Non-Error promise rejection captured with value: Timeout',
  "Cannot read properties of null (reading 'ua')",
  'NotFoundError: The object can not be found here',
  'NotFoundError: Node.replaceChild: Child to be replaced is not a child of this node',
  "NotFoundError: Failed to execute 'replaceChild' on 'Node': The node to be replaced is not a child of this node",
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  'Non-Error promise rejection captured with value', // See https://github.com/getsentry/sentry-javascript/issues/3440
  // Axios Abort Errors
  'Request aborted',
  'AbortError',
  'timeout of 0ms exceeded',
  'timeout exceeded',
  'Network Error',
  // Webengage ?
  "Cannot read properties of null (reading 'queryOne')",
  // React / Next.js hydration errors
  '@sentryIgnore',
  // Ignore a hydration error caused by this techique: https://react.dev/reference/react/Suspense#providing-a-fallback-for-server-errors-and-client-only-content
  'https://reactjs.org/docs/error-decoder.html?invariant=418', // Hydration failed because the initial UI does not match what was rendered on the server
  // Ignore a hydration error often caused by browser extensions modifying the DOM.
  'https://reactjs.org/docs/error-decoder.html?invariant=419', // The server could not finish this Suspense boundary, likely due to an error during server rendering. Switched to client rendering.
  'https://reactjs.org/docs/error-decoder.html?invariant=423', // There was an error while hydrating.
  'https://reactjs.org/docs/error-decoder.html?invariant=425', // Text content does not match server-rendered HTML...
];

module.exports = ignoreErrorList;
