const nodeEnv = process.env.NODE_ENV;
const refrensEnv = process.env.NEXT_PUBLIC_REFRENS_ENV;

const getPublicDomain = () => {
  if (refrensEnv === 'production') {
    return process.env.NEXT_PUBLIC_PUBLIC_DOMAIN || 'https://www.refrens.com';
  }
  return process.env.NEXT_PUBLIC_BASE_DOMAIN;
};

const checkStaticDomain = () => {
  if (process.env.NEXT_PUBLIC_STATIC_DOMAIN) {
    return process.env.NEXT_PUBLIC_STATIC_DOMAIN.endsWith('/')
      ? process.env.NEXT_PUBLIC_STATIC_DOMAIN.slice(0, -1)
      : process.env.NEXT_PUBLIC_STATIC_DOMAIN;
  }
  return process.env.NEXT_PUBLIC_STATIC_DOMAIN;
};

const NextConfig = {
  publicRuntimeConfig: {
    nodeEnv,
    refrensEnv,
    isRefrensProd: refrensEnv === 'production',
    isRefrensStage: refrensEnv === 'staging',
    isRefrensDev: refrensEnv === 'development',
    isEmailSharingEnabled: true,
    buildId: process.env.NEXT_PUBLIC_BUILD_ID,
    sentryRelease: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    courierAPI: process.env.NEXT_PUBLIC_COURIER_DOMAIN,
    akatoshTracker: process.env.NEXT_PUBLIC_AKATOSH_LIB,
    apiDomain: process.env.NEXT_PUBLIC_API_DOMAIN,
    authCookie: process.env.NEXT_PUBLIC_AUTH_COOKIE,
    authQuery: process.env.NEXT_PUBLIC_AUTH_QUERY,
    baseDomain: process.env.NEXT_PUBLIC_BASE_DOMAIN,
    bizRefCookie: process.env.NEXT_PUBLIC_BIZREF_COOKIE,
    bizRefQuery: process.env.NEXT_PUBLIC_BIZREF_QUERY,
    fbAppId: '326712571385127',
    gtmId: 'GTM-NFK8GG2',
    socialProfiles: {
      facebook: 'https://www.facebook.com/refrensApp',
      twitter: 'https://twitter.com/refrensApp',
    },
    staticDomain: checkStaticDomain(),
    publicDomain: getPublicDomain(),
    legalDomain: process.env.NEXT_PUBLIC_LEGAL_DOMAIN,
    referrerCookie: process.env.NEXT_PUBLIC_REFERRER_COOKIE,
    referrerQuery: process.env.NEXT_PUBLIC_REFERRER_QUERY,
    ucStateKey: 'uc',
    feedWelcomeStateKey: 'feedWelcome',
    eInvoiceKey: 'einvoice',
    requirementHydrationKey: 'requirement-form',
    inviteQuery: process.env.NEXT_PUBLIC_INVITE_QUERY,
    vendorReqKey: 'hireOnboarding',
    utmKey: 'utm',
    apiQueryKey: 'apiQuery',
    urlTokenQuery: process.env.NEXT_PUBLIC_URL_AUTH_TOKEN,
    invoiceRequestTokenQuery: process.env.NEXT_PUBLIC_INVOICE_REQUEST_TOKEN,
    refrensUrlkey: 'refrens',
    cashfreeEnv: process.env.NEXT_PUBLIC_CASHFREE_ENV,
    refrensProgram: process.env.NEXT_PUBLIC_REFRENS_PROGRAM,
    refrensFee: 4,
    profileFee: {
      INR: 2000,
      USD: 29,
    },
    webengageKey: process.env.NEXT_PUBLIC_WEBENGAGE_KEY,
    usetifulKey: process.env.NEXT_PUBLIC_USETIFUL_KEY,
    cannyKey: process.env.NEXT_PUBLIC_CANNY_KEY,
    gaProperty: process.env.NEXT_PUBLIC_GA_PROPERTY,
    ga4Property: process.env.NEXT_PUBLIC_GA4_PROPERTY,
    sentryDSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    razorpayKeyId: process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID,
    googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    googleClientIdMultiScope: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID_MULTI_SCOPE,
    outlookClientId: process.env.NEXT_PUBLIC_OUTLOOK_CLIENT_ID,
    recaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
    invisibleRecaptchaKey: process.env.NEXT_PUBLIC_INVISIBLE_RECAPTCHA_KEY,
    optimizeId: process.env.NEXT_PUBLIC_OPT_CONTAINER_ID,
    googleFontKey: process.env.NEXT_PUBLIC_GOOGLE_FONT_KEY,
    superReferrerUrlKey: process.env.NEXT_PUBLIC_SUPER_REFERRER_URLKEY,
    globalInvoiceKey: process.env.NEXT_PUBLIC_GLOBAL_INVOICE_KEY,
    sithisUrl: process.env.NEXT_PUBLIC_SITHIS_URL,
    whiteRunDomain: process.env.NEXT_PUBLIC_WHITERUN_DOMAIN,
    venusUrl: process.env.NEXT_PUBLIC_VENUS_URL,
    refrensPremiumUrlKey: process.env.NEXT_PUBLIC_REFRENS_PREMIUM_URLKEY,
    refrensPremiumUserId: process.env.NEXT_PUBLIC_REFRENS_PREMIUM_USER,
    refrensPremiumCredits: {
      credits: 500,
      months: 6,
    },
    lmsNoResponseStageId: process.env.NEXT_PUBLIC_LMS_STAGE_NO_RESPONSE_ID,
    imageUrl: {
      public: process.env.NEXT_PUBLIC_PUBLIC_IMAGE_BASE_URL,
    },
    suprsend: {
      key: process.env.NEXT_PUBLIC_SUPRSEND_KEY,
      secret: process.env.NEXT_PUBLIC_SUPRSEND_SECRET,
      vapidKey: process.env.NEXT_PUBLIC_SUPRSEND_VAPID_KEY,
    },
    cashfreeSdk: process.env.NEXT_PUBLIC_CASHFREE_SDK,
    cashfreeSdkV3:
      process.env.NEXT_PUBLIC_CASHFREE_SDK_V3 === '0'
        ? false
        : process.env.NEXT_PUBLIC_CASHFREE_SDK_V3,
    facebookMeta: {
      appId: process.env.NEXT_PUBLIC_FB_APP_ID,
      // we are currently using v19 of the graph api, will need to update here if we upgrade version in future
      version: 'v19.0',
      appConfigId: process.env.NEXT_PUBLIC_FB_APP_CONFIG_ID,
    },
    shopifyRedirectMediumValue: 'Shopify_app',
    icarusHostname: process.env.NEXT_PUBLIC_ICARUS_HOSTNAME,
  },
};

export default NextConfig;
