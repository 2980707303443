import React, { useEffect, useState } from 'react';
import Error from 'next/error';
import { Button, Typography, Image, Tooltip } from '@refrens/disco';
import { Info, Warning } from '@refrens/disco/icons';
import { Box } from 'reflexbox';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import appendQuery from 'append-query';
import NextConfig from '@/config';

import { Link, Router } from '@/router';
import { getUrl } from '@/lib/getUrl';
import CardPage from './cardpage';

const { publicRuntimeConfig } = NextConfig;
const { baseDomain } = publicRuntimeConfig;

const ForbiddenButtonWrappers = styled.div`
  margin-top: var(--sizes-regular);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--sizes-regular);
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0;
  & > * {
    flex-grow: 1;
  }
`;

const ForbiddenCardPage = styled(CardPage)`
  && {
    border-radius: 0.75rem;
    padding: 1.75rem;

    @media screen and (${(props) => props.theme.screens.lte.xs}) {
      padding: 1.25rem;
    }
  }
`;

const ForbiddenPageRedirectInfo = styled(Typography)`
  && {
    color: #9ba2ab;
    text-align: center;
  }
`;

const ForbiddenPageAuthMessage = styled(Typography)`
  && {
    background: var(--color-white-regular);
    border-radius: var(--radii-lg);
    padding: var(--sizes-small);
    display: grid;
    place-items: center;
    text-align: center;
    width: 100%;
    line-height: var(--line-height-small);
  }
`;

const ForbiddenPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sizes-larger);
`;

const ForbiddenPageMessageTitle = styled(Typography.H4)`
  && {
    font-weight: bold;
    font-size: var(--sizes-regular);
    line-height: var(--line-height-large);
  }
`;

const ForbiddenPageMessageDescription = styled(Typography.P)`
  && {
    text-align: center;
    color: #363c45;
  }
`;

const NextErrorWrapper = styled.div`
  color: var(--color-grey-regular) !important;
`;

function CustomError({ store, isNetworkError, statusCode }) {
  const { isReady, crispReady, openHelp, auth } = store;
  const [redirectTimer, setRedirectTimer] = useState(10);

  useEffect(() => {
    let intervalId;
    if (auth && statusCode === 403) {
      // We shall redirect user to dashboard after 10 seconds in case of 403 error
      intervalId = setInterval(() => {
        setRedirectTimer((currTimer) => (currTimer > 0 ? currTimer - 1 : 0));
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [auth, statusCode]);

  useEffect(() => {
    if (redirectTimer === 0) {
      // redirect to dashboard once timer is 0
      Router.pushRoute('Dashboard');
    }
  }, [redirectTimer]);

  if (isNetworkError) {
    return (
      <Box m={32}>
        <CardPage displayType='column' title='408: Network Error'>
          <Box textAlign='center' mb={16}>
            <Warning size={48} color='#EBBC26' />
            <Typography size='h3'>Internet connection unstable</Typography>
            <Typography size='h5' variant='subtle'>
              Oops, It seems there is some problem with your internet connectivity.
            </Typography>
          </Box>
          <Box textAlign='center'>
            <Button onClick={window.location.reload}>Reload</Button>
          </Box>
        </CardPage>
      </Box>
    );
  }

  // taking an assumption here that if API throws 401 error even when a user is there is malicious intent
  // in such a case user will get stuck on this error message
  if (auth && statusCode === 401) {
    return (
      <Box m={32}>
        <CardPage displayType='column' title='Hold on tight'>
          <Box textAlign='center' mb={16}>
            <Image
              src='https://assets.refrens.com/animation_300_kjigz8w6_607da47cc9.gif'
              loading='eager'
              height={300}
              width={300}
            />
            <Typography size='h3' variant='success'>
              Launching into your space...
            </Typography>
          </Box>
        </CardPage>
      </Box>
    );
  }

  if (auth && statusCode === 403) {
    const { email } = auth;
    return (
      <Box m={32}>
        <ForbiddenCardPage displayType='column' title='403: Forbidden'>
          <ForbiddenPageContainer>
            <ForbiddenPageAuthMessage variant='subtle' size='small'>
              You are logged in from {email}.
            </ForbiddenPageAuthMessage>
            <Image
              src='https://assets.refrens.com/403_forbidden_1d955d41f3.webp'
              height={100}
              width={100}
            />
            <ForbiddenPageMessageTitle size='h4' as='h4' bold>
              Oops! Wrong Door! 🚪
            </ForbiddenPageMessageTitle>
            <ForbiddenPageMessageDescription size='h5' variant='subtle'>
              Looks like you've stumbled into someone else's account! If you're supposed to be part
              of this business, ask the admin to let you in!
            </ForbiddenPageMessageDescription>
            <ForbiddenPageMessageDescription size='h5' variant='subtle'>
              If you believe this is an error, kindly contact our support team for assistance.
            </ForbiddenPageMessageDescription>
            <br />
            <ForbiddenButtonWrappers>
              <Link route='Dashboard' passHref>
                <Button variant='secondary'>Go to your Dashboard</Button>
              </Link>
              <Button
                id='contactButton'
                onClick={openHelp}
                disabled={!crispReady}
                variant='secondary'
                nature='outlined'
              >
                Contact Support
              </Button>
              {!crispReady && (
                <Tooltip anchorId='contactButton' place='top' content='Loading Support...' />
              )}
            </ForbiddenButtonWrappers>
            <ForbiddenPageRedirectInfo variant='none'>
              You will be redirected to your dashboard in {redirectTimer}s
            </ForbiddenPageRedirectInfo>
          </ForbiddenPageContainer>
        </ForbiddenCardPage>
      </Box>
    );
  }

  if (statusCode === 404) {
    return (
      <Box m={32}>
        <ForbiddenCardPage displayType='column' title='404: Not Found'>
          <ForbiddenPageContainer>
            <Image
              src='https://assets.refrens.com/403_forbidden_1d955d41f3.webp'
              height={100}
              width={100}
            />
            <ForbiddenPageMessageTitle size='h4' as='h4' bold>
              Oops! The page you are looking for could not be found 🥺
            </ForbiddenPageMessageTitle>
            <ForbiddenButtonWrappers>
              {auth ? (
                <Link route='Dashboard' passHref>
                  <Button variant='secondary'>Go to your Dashboard</Button>
                </Link>
              ) : (
                // If user is not logged in, take them to home page
                // Note: This block ideally won't be reached without auth on 404 since we render the login form instead
                <Link passHref href={appendQuery(baseDomain, { redirect: 'no' })}>
                  <Button variant='secondary'>Take me Home</Button>
                </Link>
              )}
            </ForbiddenButtonWrappers>
          </ForbiddenPageContainer>
        </ForbiddenCardPage>
      </Box>
    );
  }

  if (statusCode === 410) {
    return (
      <Box m={32}>
        <CardPage displayType='column' title='410: Gone'>
          <Box textAlign='center' mb={16}>
            <Info size={48} color='#F34233' />
            <Typography size='h5' bold>
              The document or page is deleted or does not exist. If you created this, check deleted
              document section to restore it.
            </Typography>
            <br />
            {isReady && !auth && (
              <>
                <Typography size='h5' variant='subtle'>
                  Please login to access this page.{' '}
                  <Typography.A href={getUrl('Login')}>Click Here To Login</Typography.A>{' '}
                </Typography>
                <Typography size='h5' variant='subtle'>
                  Please <Typography.A href={getUrl('ForgotPassword')}>Click Here</Typography.A> if
                  you forgot your password
                </Typography>
                <br />
              </>
            )}
          </Box>
          {crispReady && (
            <Box textAlign='center'>
              <Button onClick={openHelp}>Contact Support</Button>
            </Box>
          )}
        </CardPage>
      </Box>
    );
  }

  if (statusCode === 500) {
    return (
      <Box m={32}>
        <ForbiddenCardPage displayType='column' title='500: Internal Server Error'>
          <ForbiddenPageContainer>
            <Image
              src='https://assets.refrens.com/403_forbidden_1d955d41f3.webp'
              height={100}
              width={100}
            />
            <ForbiddenPageMessageTitle size='h4' as='h4' bold>
              Oops! Something bad happened 🥺
            </ForbiddenPageMessageTitle>
            <ForbiddenButtonWrappers>
              <Button
                variant='neutral'
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload the Page
              </Button>
              {auth ? (
                <Link route='Dashboard' passHref>
                  <Button variant='secondary'>Go to your Dashboard</Button>
                </Link>
              ) : (
                // If user is not logged in, take them to home page
                // Note: This block ideally won't be reached without auth on 404 since we render the login form instead
                <Link passHref href={appendQuery(baseDomain, { redirect: 'no' })}>
                  <Button variant='secondary'>Take me Home</Button>
                </Link>
              )}
            </ForbiddenButtonWrappers>
          </ForbiddenPageContainer>
        </ForbiddenCardPage>
      </Box>
    );
  }

  return (
    <NextErrorWrapper>
      <Error statusCode={statusCode} />
    </NextErrorWrapper>
  );
}

export default inject('store')(observer(CustomError));
