import React, { useCallback, useMemo, useState } from 'react';
import { func, object, bool, string } from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { inject, observer } from 'mobx-react';
import { Typography, Button, Menu, Popover, CustomTooltip, UserAvatar } from '@refrens/disco';
import { ContactCards, PersonaAvatar, usePulse } from '@refrens/jupiter';

import {
  RefrensWhite,
  Rewards,
  ChevronDown,
  ExternalLink,
  OutlineSetting,
  OutlineLogout,
  Bars,
  UserGuide,
  ArrowLeft,
  Refrens,
  OutlineBolt,
  Pulse as PulseIcon,
} from '@refrens/disco/icons';
import dynamic from 'next/dynamic';
import { useMedia } from 'react-use';
import { Router, Link } from '@/router';
import NextConfig from '@/config';

import Ln from '@/components/blocks/MenuButton';
import JupiterStyleOverride from '@/components/styles/JupiterStyleOverride';
import { getUrl } from '@/lib/getUrl';
import EmptyNotification from '@/components/widgets/EmptyNotification';
import NotificationData from '@/components/widgets/NotificationData';
import { HEADER_HEIGHT } from '@/constants';

const SuprSendInbox = dynamic(() => import('@suprsend/react-inbox'), { ssr: false });
const { publicRuntimeConfig } = NextConfig;
const { suprsend } = publicRuntimeConfig;

const HeaderWrapper = styled.header`
  flex-grow: 1;
  display: flex;
  width: 100%;
  @keyframes header-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }

  animation: header-fade-in 0.5s;
`;

const BarWrapper = styled.div`
  && {
    background-color: ${(props) => (props.isAppUser ? '#fff' : props.theme.lydia.colors.primary)};
    box-shadow: none;
    display: flex;
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    color : ${(props) => props.theme.lydia.colors.text.light};
    @media (${(props) => props.theme.lydia.screen.lte.xs}) {
      transition: all 0.3s ease;
      height: ${(props) => (props.hideHeaderWithAnim ? 0 : `${HEADER_HEIGHT}px`)}
      transform: ${(props) => props.hideHeaderWithAnim && `translateY(-${HEADER_HEIGHT}px)`};
    }
    ${(props) =>
      props.debugUi &&
      `
      @media (${props.theme.lydia.screen.gte.xxs}) {
        background: #9c27b0;
      }
      @media (${props.theme.lydia.screen.gte.xs}) {
        background: #cddc39;
      }
      @media (${props.theme.lydia.screen.gte.s2}) {
        background: black;
      }
      @media (${props.theme.lydia.screen.gte.sm}) {
        background: #ec407a;
      }
      @media (${props.theme.lydia.screen.gte.md}) {
        background: #4caf50;
      }
      @media (${props.theme.lydia.screen.gte.lg}) {
        background: ${props.theme.lydia.colors.primary};
      }
    `}
  }
`;

const Toolbar = styled.div`
  height: ${(props) => props.theme.lydia.sizes.header.height};
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
`;

const FlexDiv = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  justify-content: space-between;
  align-items: center;
`;

const RightNav = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  > div > div {
    display: flex;
  }
`;

const LogoButton = styled(Button)`
  && {
    line-height: 0;
    @media (${(props) => props.theme.lydia.screen.lte.sm}) {
      > svg {
        height: 20px;
      }
      margin-left: 0;
    }
  }
`;

const AvatarButton = styled(Button)`
  && {
    padding: 4px;
    height: 38px;
    position: relative;
    z-index: 0;
    &:hover {
      background: #733dd9;
    }
  }
`;

const Tagline = styled(Typography)`
  && {
    margin-left: 8px;
    display: flex;
    align-items: center;
    @media (${(props) => props.theme.lydia.screen.lte.xs}) {
      display: none;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0 4px;
    height: 38px;
    width: 38px;
    line-height: 20px;
    border-radius: 4px;
    margin-right: 8px;
    &:hover {
      background: #733dd9;
    }
    > svg {
      color: #fff;
      margin-right: 0;
    }
    &[data-reportbugs='true'] {
      width: 100%;
    }
    @media (${(props) => props.theme.lydia.screen.lte.xs}) {
      padding: 0;
      margin-right: 0;
      &[data-reportbugs='true'] {
        display: none;
      }
    }
  }
`;

const AccountMenuWrapper = styled(JupiterStyleOverride)`
  && {
    padding: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(22, 28, 38, 0.12);
    border: solid 1px #e5ecf7;
    background-color: #ffffff;
  }
`;

const UserName = styled(Typography)`
  && {
    margin-top: 12px;
  }
`;

const WhatsNewBlock = styled.div`
  padding: 8px 12px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: #f8f5ff;
  border: 1px solid #f0ebff;
`;

const WhatsNew = styled.div`
  a {
    > span {
      line-height: 1.2;
    }
    svg {
      stroke-width: 2.5;
    }
  }
`;

const ExtraLinks = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 0;
  padding: 8px 24px;
  background-color: rgba(243, 245, 247, 0);
  > a {
    text-decoration: none;
  }
`;

const Dots = styled.span`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #7f8fa4;
`;

const AuthButtonWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

// const NetworkButton = styled(Button)`
//   && {
//     margin-right: 4px;
//     padding: 0 8px;
//     height: 38px;
//     svg {
//       margin-right: 0;
//     }
//     &: hover {
//       background: #733dd9;
//     }
//   }
// `;

const ChevronDownIcon = styled(ChevronDown)`
  && {
    margin-left: 4px;
  }
`;

const DiscoMenuStyled = styled(Menu)`
  && {
    li > div {
      > span {
        margin-left: 0;
      }
    }
  }
`;

const ProductsMenu = styled(Menu)`
  && {
    ul {
      li > div {
        padding: 0;
        height: max-content;
      }
      a {
        text-decoration: none;
        color: var(--color-grey-regular);
      }
    }
  }
`;

const AppUserLogo = styled.div`
  margin-left: 4px;
`;

const AppMenuWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const AppUserName = styled(Typography)`
  && {
    margin-top: 12px;
    @media (${(props) => props.theme.lydia.screen.lte.sm}) {
      display: none;
    }
  }
`;

const SuperSendInboxWrapper = styled.div`
  display: flex;
  height: 38px;
  width: 38px;
  margin-right: 8px;
  line-height: 20px;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background: #733dd9;
  }
  > div:first-child {
    position: inherit;
  }
  * {
    font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif !important;
  }
  > div > div {
    margin-top: 0;
    margin-right: 0;
    > svg {
      display: flex;
      stroke-width: 1.25;
      height: 23px;
      * {
        color: #fff !important;
      }
    }
    p {
      line-height: 1.5;
    }
    > span {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(32%, -32%);
      transform-origin: 100% 0%;
      z-index: auto;
      min-width: 8px;
      height: 16px;
      padding: 0 5px;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      text-align: center;
      background: #cc1016;
      border-radius: 10px;
    }
  }
`;

const UserDetails = styled.div`
  display: flex;
  width: 100%;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const Badge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(32%, -32%);
  -ms-transform: translate(32%, -32%);
  transform: translate(32%, -32%);
  -webkit-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  z-index: auto;
  min-width: 8px;
  height: 16px;
  padding: 0 5px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  background: #cc1016;
  border-radius: 10px;
`;

const SuprSendHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--sizes-h3);
`;

const SuprsendToastProps = {
  toastOptions: { duration: 7000 },
};

const productMenu = () => (
  <ProductsMenu>
    <Menu.Item>
      <Ln
        href={getUrl('InvoiceLanding')}
        label='Invoice Generator'
        data-ga-on='click'
        data-ga-event-category='ProductMenu'
        data-ga-event-action='InvoiceLandingLink'
      />
    </Menu.Item>
    <Menu.Item>
      <Ln
        href={getUrl('ProformaInvoiceLanding')}
        label='Proforma Invoice Generator'
        data-ga-on='click'
        data-ga-event-category='ProductMenu'
        data-ga-event-action='ProformaInvoiceLandingLink'
      />
    </Menu.Item>
    <Menu.Item>
      <Ln
        href={getUrl('QuotationLanding')}
        label='Quotation Generator'
        data-ga-on='click'
        data-ga-event-category='ProductMenu'
        data-ga-event-action='QuotationLandingLink'
      />
    </Menu.Item>
    <Menu.Item>
      <Ln
        href={getUrl('IntPaymentLandingPage')}
        label='Receive International Payment'
        data-ga-on='click'
        data-ga-event-category='ProductMenu'
        data-ga-event-action='IntPaymentLandingPageLink'
      />
    </Menu.Item>
    <Menu.Item>
      <Ln
        href={getUrl('ExpenseManagementLanding')}
        label='Expenditure Management'
        data-ga-on='click'
        data-ga-event-category='ProductMenu'
        data-ga-event-action='ExpenseManagementLandingLink'
      />
    </Menu.Item>
    <Menu.Item>
      <Ln
        href={getUrl('PurchaseOrderLanding')}
        label='Purchase Orders'
        data-ga-on='click'
        data-ga-event-category='ProductMenu'
        data-ga-event-action='PurchaseOrderLandingLink'
      />
    </Menu.Item>
    <Menu.Item>
      <Ln
        href='/hire'
        label='Hire A Freelancer'
        data-ga-on='click'
        data-ga-event-category='ProductMenu'
        data-ga-event-action='NewRequirementLandingPage'
      />
    </Menu.Item>
  </ProductsMenu>
);

const Header = (props) => {
  const {
    activeBusinessId,
    store = {},
    hideMenuButton,
    handleHamburgerMenu,
    routeName,
    withRefrensTagline,
    withoutMarketPlaceBtn,
    hideHeaderWithAnim,
    withoutProducts = false,
    withoutInvite = false,
    withoutNotifications = false,
    withoutAuthButtons = false,
    isHydrated = false,
  } = props;

  const { debugUi, auth = {}, isAppUser = false } = store;

  const pulseContext = usePulse();
  const { setInboxExpanded } = pulseContext || {};

  const isSmallScreen = useMedia('(max-width: 568px)');
  const hideAccountManager = useMedia('(max-width: 992px)');
  const [showQuickUserSetting, setQuickUserSetting] = useState(false);

  const AccountManager = useMemo(() => {
    if ((!store?.business?.urlKey || !auth?._id) && !store?.refrensAccountManager?.name)
      return null;

    return <ContactCards contact={store.refrensAccountManager} />;
  }, [auth, store?.business?.urlKey, store?.refrensAccountManager, store?.activeBusiness]);

  const [productMenuOpen, setProductMenuOpen] = useState(false);

  const AccountMenu = useMemo(
    () =>
      auth && (
        <AccountMenuWrapper>
          <UserDetails>
            <PersonaAvatar
              user={auth}
              personaBusiness={auth.personaBusiness}
              userAvatarSize={56}
              personaBusinessAvatarSize={24}
            />
            <UserInfo>
              <UserName variant='neutral' size='h5' bold ellipsis>
                {auth.name}
              </UserName>
              <Typography variant='none' size='small' ellipsis>
                {auth.email}
              </Typography>
            </UserInfo>
          </UserDetails>
          <WhatsNewBlock>
            <Typography>Missing out on a feature? We'd love to know!</Typography>
            <WhatsNew>
              <Button
                anchor
                as='a'
                href='https://feedback.refrens.com/new-features'
                data-ga-on='click'
                target='_blank'
                variant='secondary'
                data-ga-event-category='SideNav'
                data-ga-event-action='RequestFeatureClick'
                data-canny-link
              >
                <Typography variant='secondary' bold>
                  Request a Feature
                </Typography>
                <ExternalLink size={14} color='#733dd9' />
              </Button>
            </WhatsNew>
          </WhatsNewBlock>
          <DiscoMenuStyled>
            <Menu.Item
              data-ga-on='click'
              data-ga-event-category='UserHeaderNav'
              data-ga-event-action='BusinessSettingsClick'
              renderWrapper={(children) => (
                <Link route='UserProfile' passHref>
                  {children}
                </Link>
              )}
              icon={<OutlineSetting size='20' color='#000' />}
            >
              <Typography variant='neutral' anchor>
                User Settings
              </Typography>
            </Menu.Item>
            <Menu.Item
              onClick={() => store.logout()}
              data-ga-on='click'
              data-ga-event-category='UserHeaderNav'
              data-ga-event-action='Logout'
              icon={<OutlineLogout size='20' color='#000' />}
            >
              <Typography variant='neutral' anchor>
                Logout
              </Typography>
            </Menu.Item>
          </DiscoMenuStyled>
          <ExtraLinks>
            <Typography.A
              href='https://www.refrens.com/grow/'
              rel='external noopener noreferrer'
              target='_blank'
              size='small'
              variant='subtle'
            >
              Blog
            </Typography.A>
            <Dots />
            <Typography.A
              rel='external noopener noreferrer'
              target='_blank'
              href='/aboutus'
              size='small'
              variant='subtle'
            >
              About
            </Typography.A>
            <Dots />
            <Typography.A
              rel='external noopener noreferrer'
              target='_blank'
              href='/privacy-policy'
              size='small'
              variant='subtle'
            >
              Privacy
            </Typography.A>
            <Dots />
            <Typography.A
              href='https://refrens.freshdesk.com/support/solutions'
              rel='external noopener noreferrer'
              target='_blank'
              size='small'
              variant='subtle'
            >
              FAQs
            </Typography.A>
          </ExtraLinks>
        </AccountMenuWrapper>
      ),
    [auth, store, isSmallScreen],
  );

  const renderSuprsendNotificationComponent = useCallback(
    ({ notificationData }) => <NotificationData notificationData={notificationData} />,
    [],
  );

  const renderSuprsendBadgeComponent = useCallback(
    ({ count }) => <Badge>{count > 10 ? `10+` : count}</Badge>,
    [],
  );

  const renderSuprsendEmptyComponent = useCallback(() => <EmptyNotification />, []);

  const headerRightComponent = useCallback(
    ({ markAllRead, closeInboxPopup }) => {
      return (
        <SuprSendHeaderContainer>
          <Button anchor onClick={markAllRead} variant='secondary'>
            Mark all as read
          </Button>
          <Button
            variant='secondary'
            anchor
            onClick={() => {
              closeInboxPopup();
              window.open(
                getUrl('Notifications', {
                  business: store?.business?.urlKey,
                }),
                '_blank',
              );
            }}
          >
            View All
          </Button>
        </SuprSendHeaderContainer>
      );
    },
    [store?.business?.urlKey],
  );

  const SuprsendTheme = useMemo(
    () => ({
      bell: {
        color: '#fff',
        padding: '0 4px',
        height: '32px',
        width: '32px',
      },
      notificationsContainer: {
        container: {
          height: isSmallScreen ? '400px' : '550px',
          width: isSmallScreen ? '95vw' : '440px',
          maxHeight: '100%',
          overflow: 'auto',
        },
      },
      header: {
        container: {
          padding: '12px 12px 0 12px',
          fontSize: '16px',
        },
        headerText: {
          color: 'var(--color-grey-regular)',
          fontWeight: 500,
        },
        markAllReadText: {
          fontWeight: 500,
          color: 'var(--color-secondary-regular)',
        },
      },
      notification: {
        headerText: {
          lineHeight: 1.5,
          color: 'var(--color-grey-light)',
          fontSize: '14px',
        },
        bodyText: { lineHeight: 1.5 },
        createdText: { lineHeight: 1.5 },
      },
      toast: {
        container: { backgroundColor: '#000' },
        headerText: { color: 'white', fontWeight: 500 },
        bodyText: { color: 'white' },
      },
    }),
    [isSmallScreen],
  );

  const showPulseIcon = !!setInboxExpanded && !!auth && !!auth.featureFlags?.pulseUnlocked;

  return (
    <HeaderWrapper className='no-print'>
      <BarWrapper
        position='fixed'
        component='div'
        debugUi={debugUi}
        hideHeaderWithAnim={hideHeaderWithAnim}
        isAppUser={isAppUser}
      >
        <Toolbar variant='dense'>
          {isAppUser && (
            <Button onClick={() => window.history.back()} anchor variant='neutral'>
              <ArrowLeft color='#7f8fa4' />
            </Button>
          )}
          {auth?.businesses?.length > 0 && !hideMenuButton && !isAppUser && (
            <Button
              aria-label='Menu'
              onClick={handleHamburgerMenu}
              disabled={hideMenuButton}
              title='Toggle Menu'
              data-ga-on='click'
              data-ga-event-category='AppHeader'
              data-ga-event-action='Hamburger'
              variant='neutral'
              anchor
              iconOnly
            >
              <Bars size={16} color='#fff' />
            </Button>
          )}
          {isAppUser && (
            <AppMenuWrapper>
              <AppUserLogo>
                <img
                  src='https://hp.azureedge.net/Assests/images/logo-298x90.png'
                  alt='HelloParent'
                />
              </AppUserLogo>
              <AppUserName variant='neutral' size='h5' ellipsis>
                {auth.name}
              </AppUserName>
            </AppMenuWrapper>
          )}
          {!isAppUser && (
            <FlexDiv>
              <LogoButton
                as='a'
                anchor
                aria-label='Home'
                href={getUrl('Home', {
                  redirect: 'no',
                })}
              >
                {isSmallScreen ? <Refrens size={28} color='#fff' /> : <RefrensWhite size={28} />}
              </LogoButton>
              {!auth && withRefrensTagline && (
                <Tagline variant='white'>| B2B network for Freelancers and Agencies</Tagline>
              )}
              {!!isHydrated && (
                <RightNav>
                  {/*
                  Attention wanderer, the code below may seem weird, being wrapped in pointless divs...
                  But make no mistake of removing them; for the consequences shall be even weirder.
                  Accept the weirdness.
                  More on this: https://refrens.slack.com/archives/C012G3F4Z9A/p1654172098795849
                 */}
                  {!hideAccountManager && (
                    <div id='refrens-account-manager-cta'>{AccountManager}</div>
                  )}
                  <div>
                    <CustomTooltip content='Report a Bug' placement='bottom'>
                      <StyledButton
                        as='a'
                        anchor
                        href='https://feedback.refrens.com/bugs'
                        target='_blank'
                        size='small'
                        data-ga-on='click'
                        data-ga-event-category='AppHeader'
                        data-ga-event-action='ReportBugClick'
                        data-reportbugs
                        data-canny-link
                      >
                        <Typography variant='white'>Report an Issue</Typography>
                      </StyledButton>
                    </CustomTooltip>
                  </div>
                  <div>
                    {auth && (
                      <CustomTooltip content='Latest Updates' placement='bottom'>
                        <StyledButton
                          anchor
                          size='small'
                          data-ga-on='click'
                          data-ga-event-category='AppHeader'
                          data-ga-event-action='LatestUpdatesClick'
                          data-canny-changelog
                          data-canny-link
                        >
                          <OutlineBolt size={20} color='#ffffff' />
                        </StyledButton>
                      </CustomTooltip>
                    )}
                  </div>
                  <div>
                    {!withoutInvite && auth && activeBusinessId && (
                      <CustomTooltip content='Invite & Upgrade' placement='bottom'>
                        <Link route='UserInvites'>
                          <StyledButton
                            anchor
                            data-ga-on='click'
                            data-ga-event-category='SideNav'
                            data-ga-event-action='UserInvitesClick'
                          >
                            <Rewards size={20} color='#ffffff' />
                          </StyledButton>
                        </Link>
                      </CustomTooltip>
                    )}
                  </div>
                  <div>
                    {auth && !withoutMarketPlaceBtn && (
                      <CustomTooltip content='User Guide' placement='bottom'>
                        <StyledButton
                          href='https://refrens.freshdesk.com/support/solutions'
                          as='a'
                          data-ga-on='click'
                          data-ga-event-category='AppHeader'
                          data-ga-event-action='NewRequirementButtonClick'
                          data-ga-event-label='NewRequirementLandingPage'
                          target='_blank'
                        >
                          <UserGuide size={20} color='#fff' />
                        </StyledButton>
                      </CustomTooltip>
                    )}
                  </div>
                  <div>
                    {showPulseIcon && (
                      <CustomTooltip content='Pulse Chat' placement='bottom'>
                        <StyledButton
                          anchor
                          data-ga-on='click'
                          data-ga-event-category='SideNav'
                          data-ga-event-action='PulseChatClick'
                          onClick={() => {
                            setInboxExpanded(true);
                          }}
                        >
                          <PulseIcon size={20} color='#ffffff' />
                        </StyledButton>
                      </CustomTooltip>
                    )}
                  </div>
                  <div>
                    {!withoutNotifications &&
                      typeof window !== 'undefined' &&
                      !!auth &&
                      !!suprsend && (
                        <CustomTooltip content='Notifications' placement='bottom'>
                          <SuperSendInboxWrapper>
                            <SuprSendInbox
                              workspaceKey={suprsend.key}
                              workspaceSecret={suprsend.secret}
                              distinctId={auth._id}
                              subscriberId={auth.suprSendSubscriberId}
                              collapseToastNotification
                              toastProps={SuprsendToastProps}
                              notificationComponent={renderSuprsendNotificationComponent}
                              noNotificationsComponent={renderSuprsendEmptyComponent}
                              badgeComponent={renderSuprsendBadgeComponent}
                              headerRightComponent={headerRightComponent}
                              theme={SuprsendTheme}
                            />
                          </SuperSendInboxWrapper>
                        </CustomTooltip>
                      )}
                  </div>
                  <div>
                    {/*
                  HIDE UNTILE NETWORK REFACTOR IS DONE
                  {auth && activeBusinessId && (
                    <Link route='BusinessNetwork'>
                      <NetworkButton
                        anchor
                        title='My Network'
                        data-ga-on='click'
                        data-ga-event-category='SideNav'
                        data-ga-event-action='MyNetworkClick'
                      >
                        <OutlineUserGroup size={20} color='#ffffff' />
                      </NetworkButton>
                    </Link>
                  )} */}
                  </div>
                  <div>
                    {!auth && !withoutProducts && (
                      <Popover
                        trigger={['click']}
                        positions={['bottom']}
                        content={productMenu()}
                        isOpen={productMenuOpen}
                        containerStyle={{
                          borderRadius: '8px',
                          transition: 'none',
                          zIndex: '9999',
                        }}
                        onClickOutside={() => {
                          setProductMenuOpen(false);
                        }}
                      >
                        <Button
                          as='a'
                          anchor
                          variant='neutral'
                          onClick={() => {
                            setProductMenuOpen(!productMenuOpen);
                          }}
                        >
                          <Typography variant='white'>Products</Typography>
                          <ChevronDown size={12} color='#fff' />
                        </Button>
                      </Popover>
                    )}
                  </div>
                  {/* show if user have atleast one business */}
                  <div>
                    {!!auth && (
                      <Popover
                        isOpen={showQuickUserSetting}
                        content={AccountMenu}
                        trigger={['click']}
                        positions={['bottom']}
                        containerStyle={{
                          borderRadius: '8px',
                          transition: 'none',
                          zIndex: '9999',
                        }}
                        containerClassName='user-quick-setting'
                        onClickOutside={() => {
                          setQuickUserSetting(false);
                        }}
                      >
                        <CustomTooltip content='User Menu' placement='bottom'>
                          <AvatarButton
                            aria-haspopup='true'
                            color='light'
                            data-ga-on='click'
                            data-ga-event-category='AppHeader'
                            data-ga-event-action='UserAvatarClick'
                            anchor
                            onClick={() => {
                              setQuickUserSetting(!showQuickUserSetting);
                            }}
                          >
                            <UserAvatar src={auth.avatar} name={auth.name} size={28} />
                            <ChevronDownIcon size={12} color='#fff' />
                          </AvatarButton>
                        </CustomTooltip>
                      </Popover>
                    )}
                  </div>
                  <div>
                    {routeName !== 'Login' && !auth?._id && !withoutAuthButtons && (
                      <AuthButtonWrapper>
                        <Button
                          href={getUrl('Login')}
                          anchor
                          as='a'
                          title='Signin'
                          data-ga-on='click'
                          data-ga-event-category='AppHeader'
                          data-ga-event-action='LoginClick'
                        >
                          <Typography variant='white'>Login</Typography>
                        </Button>
                      </AuthButtonWrapper>
                    )}
                  </div>
                  <div>
                    {routeName !== 'Register' && !auth?._id && !withoutAuthButtons && (
                      <AuthButtonWrapper>
                        <Button
                          href={getUrl('Register')}
                          as='a'
                          title='Create an Account'
                          data-ga-on='click'
                          data-ga-event-category='AppHeader'
                          data-ga-event-action='RegisterClick'
                        >
                          <Typography variant='white'>Register</Typography>
                        </Button>
                      </AuthButtonWrapper>
                    )}
                  </div>
                </RightNav>
              )}
            </FlexDiv>
          )}
        </Toolbar>
      </BarWrapper>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  activeBusinessId: string,
  handleHamburgerMenu: func,
  hideMenuButton: bool,
  store: object,
  routeName: string,
  withRefrensTagline: bool,
  withoutMarketPlaceBtn: bool,
  hideHeaderWithAnim: bool,
  withoutProducts: bool,
  withoutNotifications: bool,
  withoutInvite: bool,
  withoutAuthButtons: bool,
  isHydrated: bool,
};

const HeaderModule = withTheme(Header);
export default inject('store')(observer(HeaderModule));
