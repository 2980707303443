import React from 'react';
import moment from 'moment';
import { object, func, bool } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Alert, Toast, Modal, Form as DiscoForm, Typography } from '@refrens/disco';
import { observable, action, when, makeObservable } from 'mobx';
import Nbsp from 'react-nbsp';
import styled from 'styled-components';
import { trackEvent, RefrensBasicApi } from '@refrens/jupiter';

import { PrimaryButton, SecondaryAnchor } from '@/components/blocks/Button';
import { Auth } from '@/schemas/auth';

import { Row, Col } from '@/components/styles/LydiaComponents';
import Recaptcha from './recaptcha';

const CenteredRow = styled(Row)`
  justify-content: center;
`;

const AlertText = styled(Typography)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--sizes-xsmall);
`;

const FormBodyWrapper = styled(Col)`
  gap: 2rem;
`;

const CenteredCol = styled(Col)`
  align-items: center;
`;

const TextWrapper = styled.div`
  margin-block-end: var(--sizes-regular);
`;

class EmailVerificatonPrompt extends React.Component {
  showEmailPopup = false;

  user = null;

  allowEmailChange = false;

  recaptchaResponse = undefined;

  constructor(props) {
    super(props);

    makeObservable(this, {
      showEmailPopup: observable,
      user: observable,
      allowEmailChange: observable,
      recaptchaResponse: observable,
      updateEmail: action,
    });

    this.store = props.store;

    this.schema = Auth;
    this.user = observable(this.schema.default());
    this.showEmailPopup = !!props.showEmailPopup;

    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.user.email = this.store.auth && this.store.auth.email;
  }

  componentDidUpdate(preProps) {
    const { showEmailPopup } = this.props;
    if (preProps.showEmailPopup !== showEmailPopup) {
      this.showEmailPopup = showEmailPopup;
    }
  }

  resendVerification = () =>
    this.store.api
      .post('authentication/link', {
        email: this.store.auth.email,
        type: 'verifyemail',
      })
      .then(() => {
        Toast({ type: 'success', message: 'Email Sent' });
        this.showEmailPopup = false;
        this.allowEmailChange = false;
      });

  onToken = (recaptchaResponse) => {
    this.recaptchaResponse = recaptchaResponse;
  };

  updateEmail = async (values) => {
    const { email, password } = values;
    await when(() => !!this.recaptchaResponse);
    return RefrensBasicApi(this.store.auth.email, password)
      .patch('/users/me', { email }, { headers: { ...(this.recaptchaResponse || {}) } })
      .then(() => {
        this.store.auth.email = email;
        this.showEmailPopup = false;
        this.allowEmailChange = false;
        trackEvent('User', 'EmailUpdate', 'EmailUpdate', {}, this.user.email);
        Toast({
          type: 'success',
          message: `Success! A verification email has been sent on the email ${email}`,
        });
      })
      .catch(this.recaptchaRef.current?.wrappedInstance?.onError);
  };

  render() {
    if (!this.store.auth) {
      return <></>;
    }
    const { force, hideAlert, onEmailPopupClose } = this.props;
    let showAlert = false;
    if (!this.store.auth.emailVerified && !hideAlert) {
      const createdAt = moment(this.store.auth.createdAt);
      showAlert = force || createdAt.add(1, 'days').isBefore(moment());
    }
    if (this.store.auth.isEmailBlocked && !hideAlert) {
      showAlert = true;
    }

    const formikBag = {
      initialValues: this.user,
      validationschema: this.schema,
      onSubmit: this.updateEmail,
    };

    return (
      <>
        {!!showAlert && (
          <Alert
            variant='warning'
            centered
            message={
              <AlertText>
                {this.store.auth.isEmailBlocked
                  ? 'Your email is currently blocked and will no longer be used to send emails.'
                  : 'Verify your email from the verification email you received from Refrens.'}
                <Typography
                  variant='secondary'
                  underline
                  onClick={() => {
                    this.showEmailPopup = true;
                  }}
                >
                  {this.store.auth.isEmailBlocked
                    ? 'Re-verify your email to resume sending >'
                    : 'Send me the email again.'}
                </Typography>
              </AlertText>
            }
          />
        )}

        <Modal
          open={this.showEmailPopup}
          centered
          header={this.allowEmailChange ? 'Update Email' : 'Resend Verification Link'}
          onCancel={() => {
            this.showEmailPopup = false;
            this.allowEmailChange = false;
            if (onEmailPopupClose) {
              onEmailPopupClose();
            }
          }}
          okText='Update Email'
          footer={null}
          maskClosable={false}
          zIndex={10000}
        >
          {!this.allowEmailChange && (
            <>
              <CenteredCol>
                <TextWrapper>
                  We will send an verification link to your email at
                  <Nbsp />
                  <strong>{this.user.email}</strong>.
                </TextWrapper>
                <CenteredCol>
                  <PrimaryButton onClick={this.resendVerification}>Send Link</PrimaryButton>
                  <SecondaryAnchor
                    onClick={() => {
                      this.allowEmailChange = true;
                    }}
                  >
                    Update Email
                  </SecondaryAnchor>
                </CenteredCol>
              </CenteredCol>
            </>
          )}

          {this.allowEmailChange && (
            <>
              <DiscoForm formik={formikBag}>
                <FormBodyWrapper>
                  <DiscoForm.Input required name='email' label='Email' outlined />
                  <DiscoForm.Password
                    required
                    name='password'
                    label='Password'
                    outlined
                    visibilityToggle
                  />
                  <DiscoForm.Error />
                  <Recaptcha
                    ref={this.recaptchaRef}
                    onToken={this.onToken}
                    pageName='updateEmail'
                  />
                  <CenteredRow>
                    <DiscoForm.Button type='submit' variant='secondary'>
                      Update Email
                    </DiscoForm.Button>
                  </CenteredRow>
                </FormBodyWrapper>
              </DiscoForm>
            </>
          )}
        </Modal>
      </>
    );
  }
}

EmailVerificatonPrompt.propTypes = {
  store: object,
  force: bool,
  showEmailPopup: bool,
  hideAlert: bool,
  onEmailPopupClose: func,
};

EmailVerificatonPrompt.defaultProps = {
  force: false,
  showEmailPopup: false,
  hideAlert: false,
};

export default inject('store')(observer(EmailVerificatonPrompt));
