import React from 'react';
import styled from 'styled-components';
import { Button, Image, Typography } from '@refrens/disco';

import { captureException } from '@/helpers/sentry';
import CardPage from './cardpage';

const ErrorBoundaryCardPage = styled(CardPage)`
  && {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white-light);
    border-radius: 0.75rem;
    padding: 1.75rem;

    @media screen and (${(props) => props.theme.screens.lte.xs}) {
      padding: 1.25rem;
    }
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sizes-larger);
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  // eslint-disable-next-line  class-methods-use-this
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.error({ error, errorInfo });
    captureException(error);
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryCardPage>
          <PageContainer>
            <Typography bold size='h3'>
              Uh Oh, looks like something bad happened...
            </Typography>
            <Image
              src='https://assets.refrens.com/403_forbidden_1d955d41f3.webp'
              height={200}
              width={200}
            />
            <Button variant='secondary' onClick={() => this.setState({ hasError: false })}>
              RETRY
            </Button>
          </PageContainer>
        </ErrorBoundaryCardPage>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
