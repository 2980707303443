import React, { ComponentProps } from 'react';
import NextRouter from 'next/router';
import NextLink from 'next/link';

import NextConfig from '@/config';
import NamedRoute from './NamedRoute';
import type { NamedNextLinkProps, NamedNextRouter } from './types';

const { publicRuntimeConfig } = NextConfig;

/**
 * NamedRouter class provides a named routing mechanism for Next.js.
 * It allows defining routes with names, patterns, and pages, and provides utilities for linking and navigating using these named routes.
 */
class NamedRouter {
  /**
   * Array of NamedRoute objects.
   */
  routes: NamedRoute[];

  /**
   * React functional component for creating links using named routes (modified NextLink component).
   */
  Link: React.FC<NamedNextLinkProps>;

  /**
   * NamedNextRouter instance for handling route navigation (wraps NextRouter methods).
   */
  Router: NamedNextRouter;

  /**
   * Constructs a new NamedRouter instance.
   */
  constructor() {
    this.routes = [];
    this.Link = this.getLink();
    this.Router = this.getRouter();
  }

  /**
   * Adds a new named route.
   * @param {string} name - The name of the route.
   * @param {string} [pattern] - The URL pattern of the route.
   * @param {string} [page] - The page component associated with the route.
   * @returns {NamedRouter} The current NamedRouter instance.
   * @throws {Error} If a route with the same name already exists.
   */
  add(name: string, pattern?: string, page?: string): NamedRouter {
    const options = {
      name,
      pattern,
      page,
    };

    if (name[0] === '/') {
      options.page = pattern;
      options.pattern = name;
      options.name = null;
    }

    if (this.findByName(name)) {
      throw new Error(`Route "${name}" already exists`);
    }

    this.routes.push(new NamedRoute(options));
    return this;
  }

  /**
   * Finds a route by its name.
   * @param {string} [name] - The name of the route to find.
   * @returns {NamedRoute | null} The found route or null if not found.
   */
  findByName(name?: string): NamedRoute | null {
    if (name) {
      return this.routes.filter((route) => route.name === name)[0];
    }
    return null;
  }

  /**
   * Matches a URL to a route and extracts query parameters.
   * @param {string} url - The URL to match.
   * @returns {object} An object containing the matched route, parameters, and query.
   */
  match(url: string) {
    const parsedUrl = new URL(url, publicRuntimeConfig.baseDomain);
    const { pathname, searchParams } = parsedUrl;

    const query = {};
    searchParams.forEach((value, key) => {
      query[key] = value;
    });

    const matches = this.routes
      .map((route) => {
        const params = route.match(pathname);
        if (!params) return null;
        return { route, params, query: { ...query, ...params } };
      })
      .filter(Boolean);

    if (matches.length === 0) {
      return { query, parsedUrl };
    }

    matches.sort((a, b) => {
      // Fewer placeholders -> more specific
      return a.route.keyNames.length - b.route.keyNames.length;
    });

    return { ...matches[0], parsedUrl };
  }

  /**
   * Finds a route by name or URL and gets its URLs.
   * @param {string} [nameOrUrl] - The name or URL of the route.
   * @param {Record<string, any>} [params] - The parameters for the route.
   * @returns {object} An object containing the found route and its URLs.
   */
  findAndGetUrls(nameOrUrl?: string, params?: Record<string, any>) {
    const route = this.findByName(nameOrUrl);

    if (route) {
      return { route, urls: route.getUrls(params), byName: true };
    }

    const { route: matchedRoute, query } = this.match(nameOrUrl) as any;
    const href = matchedRoute ? matchedRoute.getHref(query) : nameOrUrl;
    const urls = { href, as: nameOrUrl };
    return { route: matchedRoute, urls };
  }

  /**
   * Gets the NamedNextRouter instance with wrapped methods for route navigation.
   * @returns The NamedNextRouter instance.
   */
  getRouter(): NamedNextRouter {
    const NewRouter = NextRouter as NamedNextRouter;

    const wrap = (method) => (route, params, options) => {
      const foundRoute = this.findAndGetUrls(route, params);
      const { byName, urls } = foundRoute || {};
      return NewRouter[method](urls?.as, undefined, byName ? options : params);
    };

    NewRouter.pushRoute = wrap('push');
    NewRouter.replaceRoute = wrap('replace');
    NewRouter.prefetchRoute = wrap('prefetch');

    return NewRouter;
  }

  /**
   * Gets the React functional component for creating links using named routes.
   * @returns The Link component.
   */
  getLink(): React.FC<NamedNextLinkProps> {
    const LinkRoutes: React.FC<NamedNextLinkProps> = (props) => {
      const { route, params, to, ...linkProps } = props;
      const newProps = { ...linkProps } as ComponentProps<typeof NextLink>;
      const nameOrUrl = route || to;
      if (nameOrUrl) {
        Object.assign(newProps, this.findAndGetUrls(nameOrUrl, params).urls);
      }

      newProps.href = newProps.as || '#';
      delete newProps.as;

      return (
        <NextLink
          {...newProps}
          legacyBehavior // Needed as Next.js 13+ has a new behavior for anchor wrapping
          prefetch={false} // Disable default visibility based prefetching. Will still occur on hover
        />
      );
    };

    return LinkRoutes;
  }
}

export default NamedRouter;
