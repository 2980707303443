import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { oneOfType, arrayOf, node, string, object, bool, number } from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import {
  Sidebar as RefrensSidebar,
  SidebarAppWrapper,
  StickyFooter,
  useAbility,
  useAccessibility,
  PulseProvider,
  AuroraFlags,
  GoogleOneTap,
} from '@refrens/jupiter';
import { Button, isBrowser, Typography } from '@refrens/disco';
import enquire from 'universal-enquire';
import { Ability } from '@casl/ability';
import debounce from 'lodash/debounce';

import theme from '@/lib/theme';
import JupiterStyleOverride from '@/components/styles/JupiterStyleOverride';
import getSidebarRoute from '@/helpers/getSidebarRoute';
import NetworkDetector from '@/components/widgets/network-detector';
import { Router } from '@/router';

import ToolLinksData from '@/helpers/toolLinksData.json';
import getSidebarDefaultSubOpenMenu from '@/helpers/getSidebarDefaultSubOpenMenu';
import isDibella from '@/helpers/isDibella';
import { HEADER_HEIGHT } from '@/constants';
import messageAurora from '@/helpers/messageAurora';
import Header from './header';
import Footer from './footer';
import StickyHeader from './StickyHeader';
import LoginForm from './login';
import Error from './error';
import PremiumAccess from './PremiumAccess';
import AccessDenied from './AccessDenied';
import PageLoader from './PageLoader';
import FileDownloadAlertModal from './FileDownloadAlertModal';

const DocumentWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  margin: 0px;
  display: flex;
  @media print {
    top: 0;
    position: static;
  }
`;

const ToolBarWrapper = styled.div`
  width: 100%;
  background-color: white;
  position: relative;
`;

const MainWrapper = styled.div`
  && {
    width: 100%;
    flex-grow: 1;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : props.theme.lydia.colors.bg.body};
    @media print {
      background-color: #fff;
      padding: 0;
      margin: 0;
    }
  }
`;

const Scroller = styled.div`
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: ${HEADER_HEIGHT}px; // header space compensation
  scroll-behavior: smooth;
  @media (${(props) => props.theme.lydia.screen.lte.sm}) {
    padding-bottom: 0;
    &[data-without-footer] {
      margin-bottom: 0;
    }
  }
  @media (${(props) => props.theme.lydia.screen.lte.xs}) {
    // we hide the header on mobile (xs) screens on scroll down
    // margin-bottom should be 0 when the header is hidden
    &[data-hide-header-with-anim='true'] {
      margin-bottom: 0;
    }
  }
  &[data-without-header] {
    // if header is hidden at all times, then prioritize setting margin-bottom to 0
    margin-bottom: 0 !important;
  }
  @media print {
    padding: 0;
    margin: 0;
    overflow: visible;
    height: fit-content;
  }
`;

const XScroller = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: visible;
  scroll-behavior: smooth;
  overflow-x: hidden;

  &[data-x-scroll-page='true'] {
    overflow-x: auto;
  }
  &[data-with-sticky-footer='true'] {
    @media (${(props) => props.theme.lydia.screen.lte.sm}) {
      padding-bottom: 112px;
    }
  }
  &[data-with-sticky-footer='false'] {
    @media (${(props) => props.theme.lydia.screen.lte.sm}) {
      padding-bottom: 60px;
    }
  }
  &[data-disabled='true'] {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  @media print {
    padding: 0;
    margin: 0;
    height: fit-content;
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  min-height: 80%;
  padding-bottom: 63px;
  margin-bottom: 125px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.theme.lydia.sizes.main.width};

  @media (${(props) => props.theme.lydia.screen.gte[props.theme.lydia.breakpoints.aside]}) {
    padding-left: ${(props) => props.theme.lydia.spacing.padding};
    padding-right: ${(props) => props.theme.lydia.spacing.padding};
  }

  @media (${(props) => props.theme.lydia.screen.gte[props.theme.lydia.breakpoints.aside]}) {
    padding-top: ${(props) => props.theme.lydia.spacing.padding};
  }

  @media print {
    padding-top: 0;
    max-width: unset;
    padding-bottom: 0;
    max-width: unset;
    padding: 0;
    margin: 0;
  }

  &[data-without-footer='true'] {
    margin-bottom: 0;
  }
  &[data-x-scroll-page='true'] {
    width: fit-content;
  }
  &[data-is-full-width-page='true'] {
    max-width: unset;
    min-width: 100%;
  }
  &[data-is-large-page='true'] {
    max-width: ${(props) => props.theme.lydia.sizes.largeMain.width};
  }
  &[data-without-padding='true'] {
    padding: 0 !important;
  }
  &[data-with-sticky-toolbar='true'] {
    max-width: unset;
    padding: 0 !important;
    > div:first-child {
      position: sticky;
      top: 0;
      z-index: 10;
      @media print {
        display: none;
      }
    }
    > div:not(:first-child) {
      margin-left: auto;
      margin-right: auto;
      max-width: ${(props) => props.theme.lydia.sizes.main.width};
    }
  }
`;

const AppAreaWrapper = styled.div`
  display: flex;
  height: 100%;
  position: fixed;
  width: 100%;
  @media print {
    position: static;
    padding: 0;
    margin: 0;
    height: fit-content;
  }
`;

const PageContentWrapper = styled(SidebarAppWrapper)`
  && {
    width: 100%;
    &[data-without-aside='true'] {
      // remove aside margin compensation when aside is hidden
      margin-left: 0;
    }
  }
`;

const ToolLinksFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px;
`;

const ToolLinksFooterHeader = styled(Typography)`
  && {
    font-size: var(--sizes-regular);
  }
`;

const ToolLinksWrapper = styled.div`
  width: 100%;
  margin-top: var(--sizes-small);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--sizes-small);
  @media screen and (min-width: 568px) {
    width: auto;
    flex-direction: row;
    align-items: center;
  }
`;

const ToolLinkButton = styled(Button)`
  && {
    align-self: inherit;
    padding: var(--sizes-smallest);
    font-size: var(--sizes-small);
    font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
    color: var(--color-grey-light);
    text-decoration: none;
    &:hover,
    &:active {
      background: var(--color-white-light);
      color: var(--color-secondary-regular);
    }
  }
`;

const PublicPageRedirectContainer = styled.div`
  text-align: center;
  margin-top: var(--sizes-largest);
  span {
    font-size: var(--sizes-h4) !important;
  }
  a {
    font-size: var(--sizes-h4) !important;
    vertical-align: baseline;
  }
`;

const asideQuery = `screen and (${theme.screen.lte.lg})`;
const xlScreen = `screen and (${theme.screen.gte.lg})`;

const Page = (props) => {
  const {
    store,
    activeBusiness,
    pageProps,
    withCollaspedAside,
    withoutHeader: propWithoutHeader,
    withoutFooter: propWithoutFooter,
    withStickyFooter: propWithStickyFooter,
    withoutAutoScrollTop,
    withPersistentMobileHeader,
    withoutAside,
    statusCode,
    loginH1,
    loginTitle,
    withoutLogin,
    isStatelessAllowed,
    withoutVerifyPrompt,
    withoutMarketPlaceBtn,
    isLargePage,
    isFullWidthPage,
    isXScrollPage,
    withoutPadding,
    dataWithStickyToolbar,
    children,
    withRefrensTagline,
    withoutProducts,
    withoutInvite,
    withoutNotifications,
    withoutAuthButtons,
    withoutScroller, // disables handle scroll and scroller paddings; overrides scroll behaviors defined here
    withoutGoogleOneTap,
    pageBackground,
    isNetworkError = false,
    permissionsReady = false,
    accessibilityReady = false,
    permissionsDenied = false,
    pagePermissions,
    wfSourcePermissions,
    isProxyAllowed = false,
    isServerRendered = false,
    forceRenderSSR,
    isComponentSSR = false,
    showSkeleton = false,
  } = props;

  const [business, setBusiness] = useState(activeBusiness);
  const [collapsed, setCollapsed] = useState(true);
  const [isLargeScreen, setIsLargeScreen] = useState(true);
  const [hideHeaderWithAnim, setHideHeaderWithAnim] = useState(false);
  const [withoutHeader, setWithoutHeader] = useState(
    typeof window === 'undefined' ? true : propWithoutHeader,
  ); // hide header initially on ssr
  const [withoutFooter, setWithoutFooter] = useState(
    typeof window === 'undefined' ? true : propWithoutFooter,
  );
  const [withStickyFooter, setWithStickyFooter] = useState(
    typeof window === 'undefined' ? false : propWithStickyFooter,
  );

  const previousActiveBusiness = useRef();
  const previousWithoutAutoScrollTop = useRef();
  const previousRouteName = useRef();
  const lastScrollPosition = useRef(0);
  const scrollerRef = useRef();
  store.toolBarRef = useRef();

  const { auth, isReady, suggestionCount = {}, isAppUser = false, premiumPopup } = store;

  const { pageRoute, showBlankPage = false, businessResponse } = pageProps || {};
  const { name: routeName = '' } = pageRoute || {};

  const ability = useAbility();
  const accessibility = useAccessibility();

  const isDibellaUserAgent = useMemo(() => isDibella(), []);

  const isAurora = useMemo(() => AuroraFlags.isAurora(), []);

  const toggleSidebar = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  useEffect(() => {
    previousActiveBusiness.current = activeBusiness;
    previousRouteName.current = routeName;
    store.scrollerRef = scrollerRef;

    if (isBrowser()) {
      // Let Aurora know the current active page
      messageAurora('pageChange', pageRoute || {});

      if (showBlankPage && !auth) {
        setWithoutHeader(true);
        setWithoutFooter(true);
        setWithStickyFooter(false);
      }
      if (isAurora) {
        // Aurora is our mobile app. We need to hide the header/footer and stickyFooter for this user agent. We will also append the router and other necessary functions to the window object
        setWithoutHeader(true);
        setWithoutFooter(true);

        if (AuroraFlags.shouldHideStickyFooter()) {
          setWithStickyFooter(false);
        } else {
          setWithStickyFooter(!!propWithStickyFooter);
        }

        window.lydia = {
          ...(window.lydia || {}),
          router: Router,
          activeBusiness,
          setSidebarCollapsed: setCollapsed,
          toggleSidebar,
        };

        // Send active business update to the mobile app
        messageAurora('activeBusinessChanged', { activeBusiness });
      } else {
        setWithoutHeader(typeof window === 'undefined' ? true : propWithoutHeader);
        setWithStickyFooter(typeof window === 'undefined' ? false : !!propWithStickyFooter);
        setWithoutFooter(typeof window === 'undefined' ? true : !!propWithoutFooter);
      }

      enquire
        .register(asideQuery, {
          match: () => {
            setIsLargeScreen(false);
          },
          unmatch: () => {
            setIsLargeScreen(true);
          },
        })
        .register(xlScreen, {
          match: () => {
            if (withCollaspedAside) {
              setCollapsed(true); // collapsed mode
            } else {
              setCollapsed(false); // expanded mode
            }
          },
          unmatch: () => {
            setCollapsed(true);
          },
        });
    }

    return () => {
      enquire.unregister(asideQuery);
      enquire.unregister(xlScreen);
      // store.showPremiumPopup('', false, undefined, {});
    };
  }, []);

  useEffect(() => {
    if (previousActiveBusiness.current !== activeBusiness) {
      setBusiness(activeBusiness);
      if (isAurora) {
        // update the business in window object for aurora
        window.lydia = {
          ...(window.lydia || {}),
          activeBusiness,
        };
        // Send active business update to the mobile app
        messageAurora('activeBusinessChanged', { activeBusiness });
      }
    }
    previousActiveBusiness.current = activeBusiness;
  }, [activeBusiness]);

  useEffect(() => {
    const prevRouteName = previousRouteName.current;
    previousRouteName.current = routeName;

    const prevWithoutAutoScrollTop = previousWithoutAutoScrollTop.current;
    previousWithoutAutoScrollTop.current = withoutAutoScrollTop;

    if (isAurora) {
      if (prevRouteName !== routeName) {
        // Let Aurora know the current active page
        messageAurora('pageChange', pageProps?.pageRoute || {});
      }
    }

    if (prevWithoutAutoScrollTop && withoutAutoScrollTop && prevRouteName === routeName) {
      // skip scroll reset if page is same and withoutAutoScrollTop is true for that page component
      // this is done if we want to restore/maintain scroll position on page change
      return;
    }

    if (routeName && prevRouteName && prevRouteName !== routeName) {
      // reset scroll position to top on page change
      if (scrollerRef.current) scrollerRef.current.scrollTop = 0;
      if (withCollaspedAside) {
        setCollapsed(true);
      }
    }
  }, [routeName, withoutAutoScrollTop]);

  useEffect(() => {
    if (showBlankPage && !auth) {
      setWithoutHeader(true);
      setWithoutFooter(true);
    } else {
      if (propWithoutHeader !== withoutHeader) {
        if (isAurora) {
          // App will always be rendered without header & footer for mobile app
          setWithoutHeader(true);
        } else {
          setWithoutHeader(!!propWithoutHeader);
        }
      }

      if (propWithStickyFooter !== withStickyFooter) {
        if (isAurora && AuroraFlags.shouldHideStickyFooter()) {
          setWithStickyFooter(false);
        } else {
          setWithStickyFooter(!!propWithStickyFooter);
        }
      }

      if (propWithoutFooter !== withoutFooter) {
        if (isAurora) {
          // App will always be rendered without header & footer for mobile app
          setWithoutFooter(true);
        } else {
          setWithoutFooter(!!propWithoutFooter);
        }
      }
    }
  }, [showBlankPage, auth, propWithoutHeader, propWithoutFooter, propWithStickyFooter]);

  useEffect(() => {
    store.asideCollapsed = collapsed;
  }, [collapsed]);

  useEffect(() => {
    setCollapsed(store.asideCollapsed);
  }, [store.asideCollapsed]);

  const handleScroll = useCallback(
    debounce(
      (e) => {
        if (e.target) {
          const { scrollTop } = e.target;

          if (!withPersistentMobileHeader) {
            /* On scroll down hide header and collapse float button  */
            if (scrollTop > lastScrollPosition.current) {
              setHideHeaderWithAnim(true);
            } else {
              setHideHeaderWithAnim(false);
            }
          } else if (hideHeaderWithAnim) {
            setHideHeaderWithAnim(false);
          }

          /* set last scroll position to current scroll position */
          lastScrollPosition.current = scrollTop;
        }
      },
      120,
      {
        maxWait: 120, // ensure not more than 120ms delay
      },
    ),
    [withPersistentMobileHeader, hideHeaderWithAnim],
  );

  const onProfilePublish = useCallback(async () => {
    if (business?.urlKey) {
      await store.api.patch(`/businesses/${business.urlKey}`, {
        isPrivate: false,
      });
      setBusiness((prev) => ({ ...prev, isPrivate: false }));
      return Promise.resolve();
    }

    return Promise.reject(new Error('No active business'));
  }, [business?.urlKey]);

  const onAccountingClick = useCallback(async (accountingType) => {
    await store.api.patch(`/users/me`, {
      $addToSet: {
        'preferences.sidebar.accounting': accountingType,
      },
    });
    return Promise.resolve();
  }, []);

  const onSubmenuClick = useCallback(
    async (itemKey) => {
      if (itemKey) {
        await store.api.patch(`/users/me`, {
          'preferences.sidebar.defaultMenu': itemKey,
        });
        if (auth?.preferences?.sidebar?.defaultMenu) {
          auth.preferences.sidebar.defaultMenu = itemKey;
        }
      }
      return Promise.resolve();
    },
    [auth?.preferences?.sidebar?.defaultMenu],
  );

  const onShare = useCallback(async () => {
    if (business?.urlKey) {
      await store.api.patch(`/businesses/${business.urlKey}`, {
        'onboarding.profileShared': true,
      });
      await store.refreshToken();
      return Promise.resolve();
    }

    return Promise.reject(new Error('No active business'));
  }, [business?.urlKey]);

  const renderPublicPageUrlRedirect = useCallback(() => {
    const { alias, name, urlKey } = businessResponse || {};

    const bizName = alias || name;

    if (bizName && urlKey) {
      return (
        <PublicPageRedirectContainer>
          <Typography size='label'>
            Were you looking for{' '}
            <Button anchor href={`/${urlKey}`} as='a' variant='secondary'>
              {bizName}
            </Button>
            ?
          </Typography>
        </PublicPageRedirectContainer>
      );
    }

    return null;
  }, [businessResponse]);

  /**
   * Renders the access denied screen in case of permissions being denied
   */
  const renderAccessDenied = useCallback(() => {
    return <AccessDenied businessUrlKey={business?.urlKey} />;
  }, [business?.urlKey]);

  const onGoogleOneTapSuccess = useCallback(async (value) => {
    const googleAuth = {
      strategy: 'google',
      tokenId: value.access_token,
    };
    try {
      const res = await store.execGoogleAuth(googleAuth);
      if (res) {
        window.location.reload();
      }
    } catch (err) {
      console.error('Google One Tap Error', err);
    }
  }, []);

  const sidebarDefaultOpenSubMenu = useMemo(() => {
    return getSidebarDefaultSubOpenMenu({
      pageRoute,
      landingPage: store.apiQuery?.lp,
      visitedMenuItems: auth?.preferences?.sidebar?.visitedMenuItems,
    });
  }, [pageRoute, store.apiQuery?.lp, auth?.preferences?.sidebar?.visitedMenuItems]);

  const sidebarDefaultSelectedMenu = getSidebarRoute(routeName);

  const permissionAllowed = useMemo(() => {
    const { isProxyBusiness, proxyBusiness } = pageProps || {};
    // for non permission pages allow default render else wait for permissions
    let allowed = isProxyBusiness ? isProxyAllowed : !pagePermissions;
    // set permission to true for stateless components and request is from dibella
    if (isDibellaUserAgent && isStatelessAllowed) {
      allowed = true;
    } else if (isProxyBusiness && isProxyAllowed) {
      if (pagePermissions) {
        const proxyAbility = new Ability(proxyBusiness.permissions);
        const [action, service] = pagePermissions(pageProps);
        allowed = proxyAbility.can(action, service);
      }
    } else if (pagePermissions && permissionsReady) {
      const [action, service] = pagePermissions(pageProps);
      allowed = ability.can(action, service);
      if (wfSourcePermissions?.length) {
        const wfAbility = new Ability(wfSourcePermissions);
        allowed = !!wfAbility.can(action, service);
      }
    }
    return !!allowed;
  }, [
    pageProps,
    isProxyAllowed,
    pagePermissions,
    permissionsReady,
    isStatelessAllowed,
    isDibellaUserAgent,
  ]);

  const shouldRenderNoAuthContent = !isServerRendered || (isServerRendered && forceRenderSSR);

  /**
   * Check if the user is not authenticated and the page is not stateless or non-authenticated pages are allowed. Only renders on client side if forceRenderSSR is false.
   */
  const shouldRenderLoginForm =
    shouldRenderNoAuthContent && !withoutLogin && isReady && !auth && !isStatelessAllowed;

  /**
   * Check if the page has an error status code (4xx, 5xx)
   */
  const showError = statusCode >= 400;

  /**
   * Render an error screen in case of error status code
   */
  const renderErrorScreen = useCallback(() => {
    // show error only when permissions are ready to display the correct error screen
    if (permissionsReady && (!isServerRendered || (isServerRendered && forceRenderSSR))) {
      return !permissionsDenied && !permissionAllowed ? (
        // show access denied screen only when permissions are ready and permission is denied
        <>{renderAccessDenied()}</>
      ) : (
        // show error screen for other error status codes
        <Error isNetworkError={isNetworkError} statusCode={statusCode} />
      );
    }
    // show loader when permissions are not ready or is server-rendered without forceRenderSSR
    return <PageLoader error />;
  }, [
    renderAccessDenied,
    permissionsReady,
    isServerRendered,
    forceRenderSSR,
    permissionsDenied,
    permissionAllowed,
    isNetworkError,
    statusCode,
  ]);

  let shouldRenderPage = false;
  if (!showError) {
    // only render page if there is no error to be shown
    if (forceRenderSSR) {
      shouldRenderPage = true; // always render page if forceRenderSSR is true. No loader transition should be shown in this case
    } else if (!isServerRendered) {
      // since page is not server rendered, we need to wait till permissions are ready to render the page. Meanwhile show loader.
      shouldRenderPage = !!permissionsReady && !!accessibilityReady;
    }
  }

  /**
   * Check if the sidebar should be rendered.
   * Sidebar should be rendered only if the business is present and permissions are not denied
   */
  const shouldRenderSideBar =
    !!business?.urlKey && // no business, no point of sidebar
    !withoutAside && // component requested sidebar to not be rendered
    !isAppUser && // no sidebar for `app` users
    !isAurora && // no sidebar for mobile app
    permissionsReady &&
    accessibilityReady &&
    !permissionsDenied && // no sidebar for denied permissions
    isReady &&
    // api query hydration is needed before render as we set the landing page based default open submenu
    store.apiQueryReady;

  /**
   * Check if the sticky header should be rendered.
   * Sticky header should be rendered only in the presence of auth and business with no errors so that wrong messages are not shown to the user.
   */
  const shouldRenderStickyHeader =
    !!auth && !!business?.urlKey && !showError && !isAppUser && isReady && !showSkeleton;

  /**
   * Show main page loader if server-rendered and not forceRenderSSR or component is not server-rendered and store is not ready. Server rendered components should not have a loader transition. Page hydration logic will be executed in background in either case.
   * Only the header gets rendered until this variable is true.
   */
  const isPageLoading = (isServerRendered && !forceRenderSSR) || (!isReady && !isComponentSSR);

  const showGoogleOneTap =
    !withoutGoogleOneTap &&
    !store.isDry &&
    !!isReady &&
    !auth &&
    !isPageLoading &&
    !isDibellaUserAgent;

  return (
    <div suppressHydrationWarning>
      <DocumentWrapper>
        {!isDibellaUserAgent && <NetworkDetector />}
        <MainWrapper backgroundColor={pageBackground}>
          <PulseProvider
            values={{
              authUser: auth,
            }}
          >
            {!withoutHeader && (
              <Header
                activeBusinessId={business?._id}
                handleHamburgerMenu={toggleSidebar}
                hideMenuButton={!shouldRenderSideBar}
                routeName={routeName}
                asideCollapsed={withoutAside || collapsed}
                withRefrensTagline={withRefrensTagline}
                withoutMarketPlaceBtn={withoutMarketPlaceBtn}
                withoutProducts={withoutProducts}
                withoutInvite={withoutInvite}
                withoutNotifications={withoutNotifications}
                withoutAuthButtons={withoutAuthButtons}
                hideHeaderWithAnim={hideHeaderWithAnim}
                isHydrated={!!isReady}
              />
            )}
          </PulseProvider>
          <AppAreaWrapper>
            {isPageLoading ? (
              <PageLoader animate withFooterLogo />
            ) : (
              <>
                {!!isBrowser() && (
                  <GoogleOneTap disabled={!showGoogleOneTap} onSuccess={onGoogleOneTapSuccess} />
                )}
                {!!shouldRenderSideBar && (
                  <JupiterStyleOverride>
                    <RefrensSidebar
                      businesses={auth && auth.businesses}
                      activeBusiness={business}
                      user={auth}
                      asideCollapsed={collapsed}
                      onSidebarToggle={setCollapsed}
                      defaultSelected={[sidebarDefaultSelectedMenu]}
                      defaultOpenSubMenu={sidebarDefaultOpenSubMenu}
                      onBusinessChange={(biz) => {
                        Router.pushRoute('Dashboard', {
                          business: biz.urlKey,
                        });
                      }}
                      onItemClick={() => {
                        if (!isLargeScreen) {
                          setCollapsed(true);
                        }
                      }}
                      suggestionCount={{
                        openLeads: suggestionCount.openLeads,
                        openRequirements: suggestionCount.openRequirements,
                        invoiceSuggestion: suggestionCount.invoice,
                        expenditureSuggestion: suggestionCount.expenditure,
                      }}
                      onProfilePublish={onProfilePublish}
                      onShare={onShare}
                      onAccountingClick={onAccountingClick}
                      onSubmenuClick={onSubmenuClick}
                      omitTopHeightGap={!!withoutHeader}
                    />
                  </JupiterStyleOverride>
                )}
                <PageContentWrapper
                  asideCollapsed={!!collapsed}
                  auth={!!auth}
                  data-without-aside={!!withoutAside || !shouldRenderSideBar} // remove aside margin if not required or not yet rendered
                >
                  {!!shouldRenderStickyHeader && (
                    <StickyHeader
                      withoutVerifyPrompt={withoutVerifyPrompt}
                      showYesBankAlert={false}
                      showInactiveUserAlert={!!accessibilityReady}
                      showDocumentQuotaAlert={!!accessibilityReady}
                      accessibility={accessibility}
                      activeBusiness={business}
                      isEmailVerified={store.auth.emailVerified}
                    />
                  )}
                  <ToolBarWrapper ref={store.toolBarRef} />
                  <Scroller
                    /**
                     * Adding id to scroller to make it accessible for scroll to top
                     * This is required for scroll to top to work on all pages because we are not using window object to scroll
                     */
                    id='rl-scroller'
                    onScroll={handleScroll}
                    data-without-footer={withoutFooter || undefined}
                    data-without-header={withoutHeader || undefined}
                    data-hide-header-with-anim={hideHeaderWithAnim || undefined}
                  >
                    <XScroller
                      id='x-scroller'
                      ref={scrollerRef} // used for scroll reset on page change (see `componentDidUpdate`)
                      onScroll={handleScroll}
                      data-x-scroll-page={!withoutScroller && !!isXScrollPage}
                      data-with-sticky-footer={!!withStickyFooter}
                      data-disabled={!!withoutScroller}
                    >
                      <PageWrapper
                        data-is-full-width-page={isFullWidthPage || showError} // error rendering will be full width even if component has not requested it
                        data-x-scroll-page={!!isXScrollPage}
                        data-is-large-page={!!isLargePage}
                        data-without-padding={!!withoutPadding}
                        data-with-sticky-toolbar={dataWithStickyToolbar && !shouldRenderLoginForm}
                        data-without-footer={!!withoutFooter}
                      >
                        {!showSkeleton ? (
                          <>
                            {shouldRenderLoginForm ? (
                              <>
                                <LoginForm
                                  h1={loginH1 || 'Login'}
                                  title={loginTitle || '401 : Login to Refrens'}
                                  pageRoute={pageRoute}
                                />
                                {renderPublicPageUrlRedirect()}
                                <ToolLinksFooter>
                                  <ToolLinksFooterHeader bold>Popular Tools</ToolLinksFooterHeader>
                                  <ToolLinksWrapper>
                                    {ToolLinksData.map((tool) => (
                                      <ToolLinkButton
                                        key={tool.name}
                                        as='a'
                                        href={tool.link}
                                        target='_blank'
                                        title={tool.title}
                                      >
                                        {tool.name}
                                      </ToolLinkButton>
                                    ))}
                                  </ToolLinksWrapper>
                                </ToolLinksFooter>
                              </>
                            ) : (
                              <>
                                {showError ? (
                                  renderErrorScreen()
                                ) : (
                                  <>
                                    {/* render page for permissionAllowed children */}
                                    {permissionAllowed && (
                                      <>
                                        {shouldRenderPage ? (
                                          <PremiumAccess {...premiumPopup}>
                                            {children}
                                          </PremiumAccess>
                                        ) : (
                                          <PageLoader />
                                        )}
                                      </>
                                    )}

                                    {/* wait for permissions to be ready for pages with permission check */}
                                    {permissionsReady && !permissionAllowed && (
                                      <>{renderAccessDenied()}</>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <PageLoader position='absolute' />
                        )}
                      </PageWrapper>
                      {!withoutFooter && !isAppUser && !showSkeleton && (
                        <Footer backgroundColor={pageBackground} routeName={routeName} />
                      )}
                    </XScroller>
                  </Scroller>
                  {!!withStickyFooter && !isAppUser && (
                    <JupiterStyleOverride>
                      <StickyFooter
                        activeBusiness={business}
                        auth={auth}
                        suggestionCount={{
                          openLeads: suggestionCount.openLeads,
                          openRequirements: suggestionCount.openRequirements,
                          invoiceSuggestion: suggestionCount.invoice,
                          expenditureSuggestion: suggestionCount.expenditure,
                          credits: suggestionCount.credits,
                        }}
                        defaultSelected={sidebarDefaultSelectedMenu}
                      />
                    </JupiterStyleOverride>
                  )}
                </PageContentWrapper>
              </>
            )}
          </AppAreaWrapper>
        </MainWrapper>
      </DocumentWrapper>
      <FileDownloadAlertModal />
    </div>
  );
};

Page.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  store: object,
  withoutAside: bool,
  withoutLogin: bool,
  isStatelessAllowed: bool,
  withoutLearnMore: bool,
  withoutVerifyPrompt: bool,
  withoutAutoScrollTop: bool,
  withoutScroller: bool,
  withCollaspedAside: bool,
  isLargePage: bool,
  isFullWidthPage: bool,
  dataWithStickyToolbar: bool,
  isXScrollPage: bool,
  statusCode: number,
  loginH1: string,
  loginTitle: string,
  pageProps: object,
  pageName: string,
  withoutFooter: bool,
  withoutMarketPlaceBtn: bool,
  withoutInvite: bool,
  withoutNotifications: bool,
  withoutAuthButtons: bool,
  withStickyFooter: bool,
  pageBackground: string,
  isNetworkError: bool,
  permissionsReady: bool,
  accessibilityReady: bool,
  isProxyAllowed: bool,
  /**
   * Whether the page is currently rendered from the server
   */
  isServerRendered: bool,
  /**
   * Force render in case of Components with SSR or NextJS encounters an error
   */
  forceRenderSSR: bool,
  /**
   * Whether the component was requested to be server rendered
   */
  isComponentSSR: bool,
  withoutGoogleOneTap: bool,
};

export default inject('store')(observer(Page));
