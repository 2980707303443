import React from 'react';
import { Typography, UserAvatar } from '@refrens/disco';
import getOptimizedImage from '@refrens/birds/src/helpers/get-optimized-image';
import getSrcSet from '@refrens/birds/src/helpers/get-src-set';
import Nbsp from 'react-nbsp';
import NextConfig from '@/config';
import {
  Wrapper,
  TextWrapper,
  AvatarWrapper,
  BodyWrapper,
} from '@/components/styles/NotificationTemplateStyles';
import ButtonGroupRenderer from './ButtonGroupRenderer';

const { publicRuntimeConfig } = NextConfig;
const { isRefrensProd, imageUrl } = publicRuntimeConfig;
const { public: optimizedImageUrl } = imageUrl || {};

const NewLeadReceived = (props) => {
  const { params, actions } = props;
  const {
    messageTxt: leadCreateMessage,
    actorUser,
    actorBusiness,
    leadOrigin,
    customerName,
    leadSubject,
  } = params || {};
  const { name: userName, avatar } = actorUser || {};
  const { name: bizName, logo } = actorBusiness || {};

  const notificationAvatar = avatar || logo;
  const notificationName = userName || bizName;

  return (
    <Wrapper>
      <AvatarWrapper>
        <UserAvatar
          size={42}
          src={
            notificationAvatar &&
            getOptimizedImage(
              notificationAvatar,
              { w: 88, h: 88 },
              isRefrensProd,
              optimizedImageUrl,
            )
          }
          srcSet={
            notificationAvatar &&
            getSrcSet(
              notificationAvatar,
              [
                { w: 88, h: 88 },
                { w: 42, h: 42 },
              ],
              isRefrensProd,
              optimizedImageUrl,
            )
          }
          name={notificationName}
        />
      </AvatarWrapper>
      <BodyWrapper>
        <TextWrapper>
          {/* display old lead create notification template */}
          {leadCreateMessage && (
            <Typography inline size='h5'>
              {leadCreateMessage}
            </Typography>
          )}

          {/* display new lead create notification template */}
          {!leadCreateMessage && (
            <>
              <Typography inline size='h5'>
                {`${actorBusiness.name} has a new lead from ${customerName} via ${leadOrigin}`}
              </Typography>
              <Nbsp />
              <Typography size='span' variant='subtle'>
                {leadSubject}
              </Typography>
            </>
          )}
        </TextWrapper>
        {!!actions?.length && <ButtonGroupRenderer actions={actions} />}
      </BodyWrapper>
    </Wrapper>
  );
};

export default NewLeadReceived;
