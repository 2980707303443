import styled, { css } from 'styled-components';

const StyleOverride = styled.div`
  font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;

  input,
  textarea {
    font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
  }
`;

export const PremiumPopupWrapper = styled(StyleOverride)`
  && {
    ${({ theme: { screens }, maxWidth }) => css`
      &[data-apply='true'] {
        max-width: ${maxWidth || '360px'};
        width: 100%;
        margin: 0 auto;
        @media (${screens.lte.xs}) {
          width: 90%;
        }
        height: 100%;
        text-align: center;
        > div {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        &[data-remove-margin='true'] {
          > div {
            @media (${screens.lte.xs}) {
              margin: 0 auto;
            }
            margin: 0 auto;
          }
        }
        &[data-remove-margin='false'] {
          > div {
            @media (${screens.lte.xs}) {
              margin: 40px auto 0px auto;
            }
            margin: 80px auto 0px auto;
          }
        }
      }
    `}
  }
`;

export default StyleOverride;
