import React from 'react';
import { PremiumPopup } from '@refrens/jupiter';
import JupiterStyleOverride, {
  PremiumPopupWrapper,
} from '@/components/styles/JupiterStyleOverride';
import { Router } from '@/router';
import Head from './head';

const AccessDenied = ({ businessUrlKey }) => {
  return (
    <JupiterStyleOverride>
      <Head title='Access Denied' />
      <PremiumPopupWrapper data-apply data-remove-margin={false} maxWidth='368px'>
        <PremiumPopup
          withoutModal
          showTag={false}
          variant='FEATURE'
          cta={{
            label: 'Go Back To Dashboard',
          }}
          onClick={() => {
            const params = {};
            if (businessUrlKey) {
              params.business = businessUrlKey;
            }
            Router.pushRoute('Dashboard', params);
          }}
          patchBusiness={false}
          title='Access Denied'
          description='You do not have permission. Please ask your administrator to get access.'
          bannerImage={{
            src: 'https://assets.refrens.com/access_denied_e1773f30cd.png',
            name: 'Access Denied',
          }}
        />
      </PremiumPopupWrapper>
    </JupiterStyleOverride>
  );
};

export default AccessDenied;
