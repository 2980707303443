import styled from 'styled-components';
// import Color from 'color';

const Tag = styled.span`
  ${(props) =>
    props.theme.lydia.colors[props.color]
      ? `
    background: ${props.theme.lydia.colors[props.color]};
  `
      : `
    background: ${props.color};
  `};
  ${(props) =>
    props.color === 'transparent' &&
    `
    box-shadow: 0 0 0 1px ${props.theme.lydia.colors.devider};
  `}
  padding: 0 5px;
  display: inline-block;
  border-radius: 3px;
  ${(props) =>
    props.light || props.color === 'transparent'
      ? `
    color: ${props.theme.lydia.colors.text.dark};
  `
      : `
    color: ${props.theme.lydia.colors.text.light};
  `}
  font-size: 12px;
  white-space: nowrap;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  font-weight: ${(props) => (props.strong ? '600' : '500')};
`;

Tag.defaultProps = {
  color: 'disabled',
};

export default Tag;
