import React from 'react';
import { object } from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { MdChat } from 'react-icons/md';
import { FiPhoneCall, FiMail } from 'react-icons/fi';
import { FaQuestionCircle } from 'react-icons/fa';
import { AppStore, PlayStore } from '@refrens/disco/icons';
import { Tooltip, Typography } from '@refrens/disco';
import Nbsp from 'react-nbsp';
import { LinkButton } from '@/components/blocks/Button';
import theme from '@/lib/theme';
import { MarketAnchorWrapper } from '@/components/styles/Common';

const StyledFooter = styled.footer`
  height : 100px;
  margin-top : -86px;
  text-align : center;
  width : 100%;
  padding : ${(props) => props.theme.lydia.spacing.padding}
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.lydia.colors.bg.body};
  transition: all 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: all 1s ease-in-out;
  transform: translate3d(0);
  color : ${(props) => props.theme.lydia.colors.text.secondary};
  position: sticky;
  left: 0;
  a, button {
    color : inherit;
    display: inline-flex;
    text-decoration: none;
    align-items: center;
  }
  >p {
    margin : 0;
  }
  >ul {
    margin : 0;
    padding : 0;
    >li {
      display : inline-block;
      padding : 0 10px;
    }
  }
  >ul.downloadLinks {
    margin: 0;
    padding: 0;
    > li {
      display: inline-block;
      padding: 0 var(--sizes-xsmall);
    }
  }

`;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.store = props.store;
  }

  render() {
    const { routeName = 'footer' } = this.props || {};
    const mPrefix = routeName.toLowerCase();
    return (
      // eslint-disable-next-line react/destructuring-assignment
      <StyledFooter backgroundColor={this.props.backgroundColor} className='no-print'>
        <MarketAnchorWrapper key={mPrefix}>
          <div id={`${mPrefix}__mkt-anchor-ft01`} className='class__mkt-anchor'>
            <span style={{ display: 'none' }}>{mPrefix}</span>
          </div>
          <div id={`${mPrefix}__mkt-anchor-ft02`} className='class__mkt-anchor'>
            <span style={{ display: 'none' }}>{mPrefix}</span>
          </div>
          <div id={`${mPrefix}__mkt-anchor-ft03`} className='class__mkt-anchor'>
            <span style={{ display: 'none' }}>{mPrefix}</span>
          </div>
          <div id={`${mPrefix}__mkt-anchor-ft04`} className='class__mkt-anchor'>
            <span style={{ display: 'none' }}>{mPrefix}</span>
          </div>
          <div id={`${mPrefix}__mkt-anchor-ft05`} className='class__mkt-anchor'>
            <span style={{ display: 'none' }}>{mPrefix}</span>
          </div>
        </MarketAnchorWrapper>
        <Typography size='span' variant='subtle' inline>
          Download our app
        </Typography>
        <ul className='downloadLinks'>
          <li>
            <Typography.A
              target='_blank'
              href='https://play.google.com/store/apps/details?id=com.refrens.RefrensApp&referrer=utm_source%3Dsystem%26utm_medium%3Dfooter%26utm_campaign%3Dapppromo'
              size='span'
              id='play-store-footer-logo'
            >
              <PlayStore size={20} />{' '}
            </Typography.A>
            <Tooltip
              anchorId='play-store-footer-logo'
              content='Download from Play Store'
              place='top'
              color='#000'
            />
          </li>
          <li>
            <Typography.A
              target='_blank'
              href='https://apps.apple.com/app/apple-store/id6476802238?pt=126855248&ct=Refrens%20Website&mt=8'
              size='span'
              id='app-store-footer-logo'
            >
              <AppStore size={20} />
            </Typography.A>
            <Tooltip
              anchorId='app-store-footer-logo'
              content='Download from App Store'
              place='top'
              color='#000'
            />
          </li>
        </ul>
        <Typography size='span' variant='subtle' inline>
          Reach out to us for any help
        </Typography>
        <ul>
          <li>
            <Typography.A href='tel:+919104043036'>
              <FiPhoneCall size={16} color={theme.colors.primary} />
              <Nbsp />
              {' +91 91040 43036'}
            </Typography.A>
          </li>
          <li>
            <Typography.A href='mailto:care@refrens.com'>
              <FiMail size={18} color={theme.colors.social.gmail} />
              <Nbsp />
              {' care@refrens.com'}
            </Typography.A>
          </li>
          {this.store.crispReady && (
            <li>
              <LinkButton tag='button' onClick={this.store.openHelp}>
                <MdChat size={18} color={theme.colors.primary} />
                <Nbsp />
                Help and Support
              </LinkButton>
            </li>
          )}
          <li>
            <Typography.A href='https://refrens.freshdesk.com/support/solutions' target='_blank'>
              <FaQuestionCircle size={18} />
              <Nbsp />
              FAQs
            </Typography.A>
          </li>
        </ul>
        <br />
        <Typography size='smaller' variant='subtle'>
          This site is protected by reCAPTCHA and the Google{' '}
          <Typography.A size='smaller' href='https://policies.google.com/privacy'>
            Privacy Policy
          </Typography.A>{' '}
          and{' '}
          <Typography.A size='smaller' href='https://policies.google.com/terms'>
            Terms of Service
          </Typography.A>{' '}
          apply.
        </Typography>
      </StyledFooter>
    );
  }
}

Footer.propTypes = {
  store: object,
};

export default inject('store')(observer(Footer));
