const menuItems = {
  GENERAL: 'GENERAL',
  USER_ROLES: 'USER_ROLES',
  BUSINESS_ROLES: 'BUSINESS_ROLES',
  ACCOUNTING: 'ACCOUNTING',
  INVENTORY: 'INVENTORY',
  EMAILS: 'EMAILS',
  LMS: 'LMS',
  MISCELLANEOUS: 'MISCELLANEOUS',
  STATEMENT: 'STATEMENT',
  ADVANCED_ACCOUNTING: 'ADVANCED_ACCOUNTING',
  ZATCAEINVOICE: 'ZATCAEINVOICE',
};

const apiRequestConstants = {
  uploadDocRequestPath: 'uploadrequest',
};

const localStorageVariables = {
  SelectedFy: 'selectedFy',
};

export default { menuItems, localStorageVariables, apiRequestConstants };
