import NextConfig from '@/config';

const { publicRuntimeConfig } = NextConfig;
const { icarusHostname } = publicRuntimeConfig;

type FileDownloadUrlAndName = {
  fileUrl: string;
  fileName: string;
};

/**
 * Get the download URL and file name from the given URL
 * @param url The URL to get the download URL and file name from
 * @returns The download URL and file name
 */
const getFileDownloadUrlAndName = (url: string): FileDownloadUrlAndName => {
  const res = {
    fileUrl: typeof url === 'string' ? url?.trim() : '',
    fileName: '',
  };

  if (!res.fileUrl) return res;

  try {
    const fileUrlObj = new URL(res.fileUrl);

    if (fileUrlObj.pathname) {
      res.fileName = decodeURIComponent(fileUrlObj.pathname.split('/').pop());
    }

    // we need to convert s3 urls to cloudfront urls to avoid XSS attacks
    // cloudfront will add a content-disposition header to the response in order to force download the file
    if (fileUrlObj.hostname === 's3.ap-south-1.amazonaws.com') {
      const icarusHostnameObject = new URL(icarusHostname);
      icarusHostnameObject.pathname = fileUrlObj.pathname;
      icarusHostnameObject.search = fileUrlObj.search;

      res.fileUrl = icarusHostnameObject.toString();
    }
  } catch (err) {
    return res;
  }

  return res;
};

export default getFileDownloadUrlAndName;
