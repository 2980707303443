import { pathToRegexp, compile } from 'path-to-regexp';
import { INamedRoute } from './types';

/**
 * Helper function to convert an object to a query string
 */
const toQuerystring = (obj) =>
  Object.keys(obj)
    .filter((key) => obj[key] !== null && obj[key] !== undefined) // Filter out null or undefined values
    .map((key) => {
      let value = obj[key];

      if (Array.isArray(value)) {
        value = value.join('/'); // Join array values with '/'
      }
      return [encodeURIComponent(key), encodeURIComponent(value)].join('='); // Encode key and value
    })
    .join('&'); // Join all key-value pairs with '&'

/**
 * Class representing a named route
 */
class NamedRoute {
  name: string; // Name of the route

  pattern: string; // URL pattern for the route

  page: string; // Page associated with the route

  regex: RegExp; // Regular expression for matching the route

  keys: any[]; // Keys extracted from the pattern

  keyNames: string[]; // Names of the keys

  toPath: any; // Function to compile the pattern into a path

  // Constructor to initialize the NamedRoute instance
  constructor({ name, pattern, page = name }: INamedRoute) {
    if (!name && !page) {
      // Throw error if name and page are missing
      throw new Error(`Missing page to render for route "${pattern}"`);
    }

    this.name = name;
    this.pattern = pattern || `/${name}`; // Default pattern is '/name'
    this.page = page.replace(/(^|\/)index$/, '').replace(/^\/?/, '/'); // Remove 'index' and ensure leading '/'
    this.regex = pathToRegexp(this.pattern, (this.keys = [])); // Create regex for the pattern and extract keys
    this.keyNames = this.keys.map((key) => key.name); // Extract key names
    this.toPath = compile(this.pattern); // Compile the pattern into a path function
  }

  // Method to match a given path against the route's pattern
  match(path: string) {
    if (path) {
      const values = this.regex.exec(path); // Execute regex on the path
      if (values) {
        return this.valuesToParams(values.slice(1)); // Convert matched values to params
      }
    }
    return null; // Return null if no match
  }

  // Method to convert matched values to parameters
  valuesToParams(values) {
    return values.reduce((params, val, i) => {
      if (val === undefined) return params; // Skip undefined values
      return Object.assign(params, {
        [this.keys[i].name]: decodeURIComponent(val), // Decode and assign value to the corresponding key
      });
    }, {});
  }

  // Method to get the href for the route with given parameters
  getHref(params = {}) {
    return `${this.page}?${toQuerystring(params)}`; // Construct href with query string
  }

  // Method to get the 'as' path for the route with given parameters
  getAs(params = {}) {
    const as = this.toPath(params) || '/'; // Compile path with params or default to '/'
    const keys = Object.keys(params);
    const qsKeys = keys.filter((key) => this.keyNames.indexOf(key) === -1); // Filter out keys that are part of the pattern

    if (!qsKeys.length) return as; // Return 'as' if no query string keys

    const qsParams = qsKeys.reduce(
      (qs, key) =>
        Object.assign(qs, {
          [key]: params[key], // Add query string params
        }),
      {},
    );

    return `${as}?${toQuerystring(qsParams)}`; // Construct 'as' with query string
  }

  // Method to get both 'as' and 'href' URLs for the route with given parameters
  getUrls(params) {
    const as = this.getAs(params); // Get 'as' URL
    const href = this.getHref(params); // Get 'href' URL
    return { as, href };
  }
}

export default NamedRoute;
