import isString from 'lodash/isString';
// import isMobilePhone from '../../node_modules/validator/lib/isMobilePhone';
import countryStates from '@refrens/fence/countries/states.json';
import * as Yup from 'yup';
import isEmail from 'validator/lib/isEmail';
import { stateCodeValidator } from '@refrens/birds/dist/es5/helpers/stateUtils';
import customParsePhoneNumberFromString from '@refrens/birds/dist/es5/helpers/custom-parse-phone-from-string';
import validatePassword from '@refrens/birds/dist/es5/helpers/validatePassword';
import telephoneCountries from '@refrens/fence/helpers/telephone-countries';

Yup.addMethod(Yup.string, 'phoneValidator', function phoneValidator(requiredCountry) {
  return this.test('phoneValidator', 'Invalid Phone number', function phoneValidatorTest(value) {
    return new Promise((resolve, reject) => {
      if (!value) return resolve(true);
      const { path, createError } = this;
      const phoneNumber = customParsePhoneNumberFromString(value);

      if (phoneNumber && phoneNumber?.isValid()) {
        if (!requiredCountry || phoneNumber?.countryCallingCode === requiredCountry) {
          return resolve(true);
        }
        return reject(
          createError({ path, message: 'Country not allowed. Please enter allowed country.' }),
        );
      }

      const onlyCountryCode = telephoneCountries.some((phone) => `+${phone.dialCode}` === value);
      if (onlyCountryCode) return resolve(true);
      return reject(createError({ path, message: 'Invalid Phone number' }));
    });
  });
});

const { boolean, string, array, date, number, object } = Yup;

export function text() {
  return string().meta({ htmlType: 'textarea' }).nullable();
}

export function id() {
  return string().length(24);
}

export function password() {
  return string().meta({ htmlType: 'password' }).nullable();
}

export const strongPassword = (label = 'Password', emailField, nameField) => {
  return string()
    .trim()
    .required(`${label} is required`)
    .test(
      'strong-password',
      `${label} must be 8 or more characters with at least one uppercase, one lowercase, a number, and a special character`,
      function (value) {
        if (!value) return false;

        const { path, parent, createError } = this;

        const user = {
          email: emailField ? parent?.[emailField] : '',
          name: nameField ? parent?.[nameField] : '',
        };

        const validity = validatePassword(value, user);

        if (validity.strength === 100 && !validity.valid) {
          // Incase password is strong, yet invalid (eg. password is similar to email/name) show a generic error
          return createError({
            path,
            message: `This ${label} is not valid. Please choose another one.`,
          });
        }

        return validity.valid;
      },
    );
};

export function email() {
  return string()
    .trim()
    .email()
    .test('isEmail', 'Email must be a valid email', (val) => !val || isEmail(val))
    .test('email&Check', 'Email must be a valid email', (val) => !val || val.indexOf('&') === -1)
    .meta({ htmlType: 'email' })
    .nullable();
}

export function emailList() {
  return array()
    .ensure()
    .of(
      object()
        .shape({
          name: string().nullable(),
          email: string().nullable(),
        })
        .nullable(),
    )
    .meta({ htmlType: 'emailList' });
}

/** @TODO phone no validation * */
export function tel(requiredCountry) {
  return string().nullable().trim().meta({ htmlType: 'tel' }).phoneValidator(requiredCountry);
  // .test('isMobilePhone', '${path} is not a valid mobile number', isMobilePhone);
}

export function bool() {
  return boolean().meta({ htmlType: 'boolean' });
}

export function num() {
  return number()
    .meta({ htmlType: 'number' })
    .nullable()
    .transform((current) => {
      const n = current * 1;
      if (Number.isNaN(n)) {
        return null;
      }
      return n;
    });
}

export function select(options, message = 'Please select a valid option') {
  // if api source service is provided
  // eg options = '/categories'
  if (isString(options)) {
    return string().ensure().meta({
      htmlType: 'select',
      // use api source
      source: options,
      options: null,
    });
  }

  // if direct options are provided
  // eg - options = { INR: 'Indian Rupee', USD: 'US Dollors' }
  return (
    string()
      // if options are provided add null by default
      // eg null is a valid value along side INR and USD
      .oneOf([null].concat(Object.keys(options)), message)
      .meta({
        htmlType: 'select',
        source: null,
        // use provided options directly
        options,
      })
  );
}

export function tags(options, max) {
  const isSource = isString(options);
  const a = array()
    .ensure()
    .meta({
      htmlType: 'select',
      mode: 'tags',
      source: isSource ? options : null,
      options: isSource ? null : options,
      max,
    });

  if (max) {
    a.max(max);
  }

  return a;
}

export function collection(type, min = 1) {
  let a = array().ensure();
  if (type) {
    a = a.of(type).default(Array(min).fill(type.default()));
  } else {
    a = a.default([]);
  }
  if (min) {
    a = a.min(min);
  }
  return a.meta({ min });
}

export function dateTime() {
  return date()
    .nullable()
    .meta({
      htmlType: 'datetime',
      timeOptions: {
        format: 'hh:mm A',
        use12Hours: true,
        minuteStep: 15,
      },
    });
}

export function file() {
  return string().meta({ htmlType: 'file' }).nullable();
}

export function image() {
  return file().meta({ htmlType: 'image' }).nullable();
}

export function pan() {
  return string()
    .trim()
    .matches(/^[A-Z\d]{10}$/, {
      message: 'Invalid PAN',
      excludeEmptyString: true,
    })
    .typeError('Invalid PAN')
    .nullable();
}

export function gstin() {
  return string()
    .trim()
    .test(
      'gstinlength',
      'GST number should be exactly 15 characters.',
      (val) => !val || val.length === 15,
    )
    .matches(/^[A-Z\d]{15}$/, {
      message: 'Invalid GSTIN',
      excludeEmptyString: true,
    })
    .typeError('Invalid GSTIN')
    .nullable();
}

export function hsn() {
  return string()
    .trim()
    .matches(/^[0-9]*$/, {
      message: 'Invalid HSN/SAC',
      excludeEmptyString: true,
    })
    .nullable();
}

export function ifsc() {
  return string()
    .trim()
    .length(11, 'IFSC code must of 11 characters')
    .matches(/^[A-Z]{4}[0]{1}[A-Z\d]{6}$/, {
      message: 'Invalid IFSC',
      excludeEmptyString: true,
    })
    .typeError('Invalid IFSC')
    .nullable();
}

export function swiftCode() {
  return string()
    .trim()
    .matches(/^[A-Z]{6}[A-Z\d]{2}([A-Z\d]{3})?$/, {
      message: 'Invalid SWIFT Code',
      excludeEmptyString: true,
    })
    .typeError('Invalid SWIFT Code')
    .nullable();
}

export function iban() {
  return string().trim().nullable();
  // .matches(/^[a-zA-Z0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/, {
  //   message: 'Invalid IBAN',
  //   excludeEmptyString: true,
  // })
  // .typeError('Invalid IBAN')
}

export function upi() {
  return string()
    .trim()
    .matches(/([\w-]*[@][\w]*)/, {
      message: 'Invalid UPI',
      excludeEmptyString: true,
    })
    .typeError('Invalid UPI')
    .nullable();
}

export function name() {
  return string().trim().nullable();
}

export function customField(dataType) {
  switch (dataType) {
    case 'text':
      return string().nullable();
    case 'number':
      return num().nullable();
    case 'date':
      return date().nullable();
    case 'currency':
      return num().nullable();
    default:
      return string().nullable();
  }
}

export const stateCodeValidation = (countryField) => {
  return string()
    .trim()
    .nullable()
    .when(countryField, {
      is: (country) => countryStates[country] !== undefined,
      then: string().test('is-valid-state-code', 'Please select a valid state', function (value) {
        const { country } = this.parent || {};
        return stateCodeValidator(country, value);
      }),
    });
};
