import React, { useMemo } from 'react';
import { Modal, Typography } from '@refrens/disco';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import getFileDownloadUrlAndName from '@/helpers/getFileDownloadUrlAndName';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sizes-regular);
`;

const FileDownloadAlertModal = (props) => {
  const { store } = props;
  const { fileDownloadAlert } = store;
  const {
    title = '',
    show = false,
    fileUrl = '',
    selfUploaded = false,
    fileName: givenFileName = '',
  } = fileDownloadAlert || {};

  const { fileUrl: sanitizedFileUrl, fileName } = useMemo(() => {
    const res = getFileDownloadUrlAndName(fileUrl);
    if (givenFileName) {
      res.fileName = givenFileName;
    }
    return res;
  }, [fileUrl]);

  return (
    <Modal
      open={show}
      centered
      header={
        <Typography bold size='h4'>
          {title || 'File Download'}
        </Typography>
      }
      onOk={() => {
        window.open(sanitizedFileUrl, '_blank', 'noopener,noreferrer');
        store.dismissFileDownloadAlert();
      }}
      onCancel={() => {
        store.dismissFileDownloadAlert();
      }}
      okText='Download'
      cancelText='Cancel'
      okButtonVariant='secondary'
      maskClosable={false}
    >
      <BodyWrapper>
        {sanitizedFileUrl ? (
          <>
            <Typography size='h4'>
              {selfUploaded
                ? 'This is a user uploaded file, please ensure you trust the source before downloading.'
                : "The file you're about to download was uploaded by another user. Please ensure you trust the source before proceeding."}
            </Typography>

            <Typography size='p'>
              ⚠️ Note: For your safety, always verify the origin of downloaded files.
            </Typography>

            {!!fileName && (
              <Typography size='p' variant='subtle'>
                File Name: {fileName}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant='error' size='label'>
            Invalid file URL.
          </Typography>
        )}
      </BodyWrapper>
    </Modal>
  );
};

export default inject('store')(observer(FileDownloadAlertModal));
