import React, { useCallback, useEffect, useState } from 'react';
import { object, string, bool } from 'prop-types';
import { observer, inject } from 'mobx-react';
import LocalForage from 'localforage';
import { SigninForm } from '@refrens/jupiter';
import { Alert, Typography } from '@refrens/disco';
import { Box } from 'reflexbox';
import Nbsp from 'react-nbsp';
import styled from 'styled-components';

import JupiterStyleOverride from '@/components/styles/JupiterStyleOverride';
import { Auth } from '@/schemas/auth';
import { Link } from '@/router';

import CardPage from './cardpage';
import Head from './head';

const StyledAnchor = styled(Typography.A)`
  && {
    text-decoration: none;
  }
`;

export const BottomCTA = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  > span {
    margin-bottom: var(--sizes-smallest);
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin-bottom: var(--sizes-large);
  }
`;

const supportingRoutes = [
  'InvoiceFinal',
  'ProInvoiceFinal',
  'ExpenditureFinal',
  'CreditNoteFinal',
  'PaymentReceiptFinal',
  'DebitNoteFinal',
  'PurchaseOrderFinal',
  'QuotationFinal',
  'SalesOrderFinal',
  'DeliveryChallanFinal',
];

const description =
  'Welcome back to Refrens. Sign-In through Email and password or Use your google account.';

const LoginForm = (props) => {
  const { store, canonical, title, pageRoute, newLogin = false } = props;
  const { name } = pageRoute;
  const [auth, setAuth] = useState(Auth.default());
  useEffect(() => {
    if (!newLogin) {
      LocalForage.getItem('UserInfo').then((user) => {
        if (user) {
          setAuth({ ...auth, email: user.email });
        }
      });
    }
  }, []);

  const loginWithCaptcha = useCallback(async (data, headers) => {
    await store.authenticate(data, headers);
    window.location.reload();
    return Promise.resolve();
  }, []);

  const getDoc = useCallback((routeName) => {
    switch (routeName) {
      case 'InvoiceFinal':
        return 'to view your invoice';
      case 'ProInvoiceFinal':
        return 'to view your proforma invoice';
      case 'ExpenditureFinal':
        return 'to view your expenditure';
      case 'CreditNoteFinal':
        return 'to view your credit note';
      case 'PaymentReceiptFinal':
        return 'to view your payment receipt';
      case 'DebitNoteFinal':
        return 'to view your debit note';
      case 'PurchaseOrderFinal':
        return 'to view your purchase order';
      case 'QuotationFinal':
        return 'to view your quotation';
      case 'SalesOrderFinal':
        return 'to view your sales order';
      case 'DeliveryChallanFinal':
        return 'to view your delivery challan';
      default:
        return '';
    }
  }, []);

  return (
    <JupiterStyleOverride>
      <Head canonical={canonical} />
      <CardPage
        style={{ width: '380px', margin: '20px auto' }}
        title={title}
        description={description}
        displayType='column'
      >
        {!!store?.loginError && (
          <StyledAlert message={store?.loginError} variant='error' showIcon />
        )}
        <Box mb='20px' mt={!!store?.loginError && '20px'} textAlign='center'>
          <Typography as='h3' bold>
            Welcome to Refrens
          </Typography>
          {supportingRoutes.includes(name) ? (
            <Typography as='p' variant='none'>
              Please login {getDoc(name)}
            </Typography>
          ) : (
            <Typography as='p' variant='none'>
              Please login to continue
            </Typography>
          )}
        </Box>
        <SigninForm onSubmit={loginWithCaptcha} defaultValue={auth} />
        <BottomCTA>
          <Typography>
            Having issues logging in?{' '}
            <Link href='/magiclink' passHref>
              <StyledAnchor variant='secondary'>Click here</StyledAnchor>
            </Link>
          </Typography>
          <Typography>
            Don't have an account?
            <Nbsp />
            <Nbsp />
            <Link to='/register' passHref>
              <StyledAnchor variant='secondary'>Sign up now</StyledAnchor>
            </Link>
          </Typography>
        </BottomCTA>
      </CardPage>
    </JupiterStyleOverride>
  );
};

const LoginFormModule = inject('store')(observer(LoginForm));

LoginForm.propTypes = {
  store: object,
  title: string,
  canonical: string,
  newLogin: bool,
};

export default LoginFormModule;
