import { boolean, object, string } from 'yup';
import countries from '@refrens/fence/countries';

import {
  email,
  password,
  select,
  tel,
  name,
  image,
  collection,
  strongPassword,
} from '@/lib/yup-types';

export const Auth = object().shape({
  email: email().label('Email').required(),
  password: password().label('Password').required(),
});

export function Profile({ isRefrensTeamMember }) {
  if (!isRefrensTeamMember) {
    return object().shape({
      country: select(countries).label('Country').required(),
      name: name()
        .matches(/[a-zA-Z0-9]/, 'Please enter a valid Name')
        .label('Name')
        .required(),
      phone: tel().label('Phone Number').nullable(),
      avatar: image().label('Profile Image'),
      newRParam: string()
        .matches(/^[0-9a-z]+$/, 'Please use only lowercase alphabets and numerals')
        .nullable(),
    });
  }

  return object().shape({
    country: select(countries).label('Country').required(),
    name: name()
      .matches(/[a-zA-Z0-9]/, 'Please enter a valid Name')
      .label('Name')
      .required(),
    phone: tel().label('Phone Number').nullable(),
    avatar: image().label('Profile Image'),
    newRParam: string()
      .matches(/^[0-9a-z]+$/, 'Please use only lowercase alphabets and numerals')
      .nullable(),
    memberAlias: string().nullable(),
    memberBio: string().nullable(),
    status: string().nullable().required('Status is a required field'),
    designation: string().nullable().required('Designation is a required field'),
    showSecondaryEmail: boolean().nullable().default(false),
    secondaryEmail: email()
      .nullable()
      .when('showSecondaryEmail', (showSecondaryEmail, schema) =>
        showSecondaryEmail ? schema.required('Secondary Email is a required field') : schema,
      ),
    secondaryPhone: tel()
      .nullable()
      .when('showSecondaryPhone', (showSecondaryPhone, schema) =>
        showSecondaryPhone ? schema.required('Secondary Phone is a required field') : schema,
      ),
    showSecondaryPhone: boolean().nullable().default(false),
    whatsappNumber: string().nullable(),
    demoUrl: string().nullable(),
  });
}

export function EmailAuthShape(countryCode) {
  return object().shape({
    email: email().label('Email').required(),
    country: select(countries).label('Country').default(countryCode).required(),
    roles: collection(string()).default(['superAdmin']).label('Roles'),
    name: name()
      .label('Name')
      .matches(/[a-zA-Z0-9]/, 'Name must have atleast 1 Alphanumeric character')
      .required(),
    phone: tel().label('Phone Number').nullable(),
    password: password().nullable(),
    organization: string().nullable().label('Organization Name'),
  });
}

export const PasswordReset = object().shape({
  email: email().nullable(), // For password strength validation
  name: string().nullable(), // For password strength validation
  password: strongPassword('Password', 'email', 'name'),
  confirmPassword: password()
    .label('Confirm Password')
    .required()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

export const PasswordChange = object().shape({
  password: string().trim().required('Password is required'),
  email: email().nullable(), // For password strength validation
  name: string().nullable(), // For password strength validation
  newPassword: strongPassword('New Password', 'email', 'name').test(
    'same-password',
    'New Password must be different than your current password',
    function (value) {
      return this.parent.password !== value;
    },
  ),
  confirmPassword: string()
    .trim()
    .required('Confirm Password is required')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.newPassword === value;
    }),
});

export function PipelineUpdate() {
  return object().shape({
    pipeline: string().required(),
    stage: string().required(),
  });
}

export function PremiumLeadCreate({ userOptions, isPrimaryPipeline }) {
  const userMap = (userOptions || []).reduce((acc, curr) => {
    if (!curr) return acc;
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  if (isPrimaryPipeline) {
    return object().shape({
      urlKey: string().required().label('Business URL Key'),
      user: select(userMap).required('User is a required field').label('User'),
      planType: string().required('Please select a valid plan').label('Plan Type'),
    });
  }

  return object().shape({
    urlKey: string().required().label('Business URL Key'),
    user: select(userMap).required('User is a required field').label('User'),
    privateFields: object().shape({
      vendor: object().shape({
        pipeline: string().required('Please select a valid pipeline').label('Pipeline'),
        stage: string().required('Please select a valid stage').label('Stage'),
      }),
    }),
    subject: string().required('Subject is a required field').label('Subject'),
  });
}
