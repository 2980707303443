import { AuroraFlags } from '@refrens/jupiter';

/**
 * Send message to Aurora through ReactNativeWebView postMessage
 * @param {string} type - Type of message
 * @param {object} data - Additional data to be sent
 * @returns {boolean} - Returns true if message was sent successfully, false otherwise
 */
const messageAurora = (type, data = null) => {
  if (type && AuroraFlags.isAurora()) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type,
          data,
        }),
      );
      return true;
    }
  }
  return false;
};

export default messageAurora;
