import React from 'react';
import { oneOfType, node, func, object, string } from 'prop-types';
import styled, { css } from 'styled-components';

const BaseButton = styled(({ tag = 'button', children, ...props }) =>
  React.createElement(tag, props, children),
)`
  display: inline-block;

  overflow: hidden;

  padding: 8px 16px;
  margin: 2px;

  cursor: pointer;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  ${(props) =>
    props.textDecoration
      ? `
    text-decoration: ${props.textDecoration};
  `
      : `
    text-decoration: none !important;
  `}
  text-transform: capitalize;

  background: none;

  border: 0 none;
  border-radius: 4px;

  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;

  ${(props) => (props.size === 'small' ? 'padding : 6px; font-size:13px;' : '')}
  ${(props) => (props.size === 'large' ? 'padding : 12px; font-size:17px;' : '')}

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  justify-content: center;
  vertical-align: middle;

  align-items: center;

  > svg {
    vertical-align: text-top;
  }

  &:hover {
    transition: all 150ms linear;
    opacity: 0.85;
  }

  &:active {
    transition: all 150ms linear;
    opacity: 0.75;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }

  ${(props) =>
    props.vertical
      ? `
    display: inline-flex;
    flex-direction: column;
    svg {
      width: 30;
      height: 30;
    }
    span {
      font-size: .8em;
    }
  `
      : ''}
`;

BaseButton.propTypes = {
  'title': string.isRequired,
  'data-ga-on': string.isRequired,
  'data-ga-event-category': string.isRequired,
  'data-ga-event-action': string.isRequired,
};

export const Button = styled(BaseButton)`
  display: inline-flex;
  background: ${(props) => props.bg};
  color: ${(props) => props.theme.lydia.colors.text[props.color] || props.color};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.lydia.colors.text[props.color] || props.color};
  }
`;

Button.defaultProps = {
  bg: '#9b9b9b',
  color: 'light',
};

export const PrimaryButton = styled(Button)`
  background: ${(props) => props.theme.lydia.colors.primary};
`;

export const SecondaryButton = styled(Button)`
  && {
    margin-inline-end: var(--sizes-small);
    font-size: var(--sizes-span);
    font-weight: var(--font-weight-regular);
    &:disabled {
      background-color: #d3dceb;
      color: #516173;
      border-color: #d3dceb;
      cursor: not-allowed;
      pointer-events: auto;
    }
    ${({ theme: { screens }, responsive }) => css`
      background: ${(props) => props.theme.lydia.colors.secondary};
      @media (${screens.lte.xxs}) {
        font-size: ${responsive ? '10px' : null};
        padding: ${responsive ? '2.5px' : null};
      }
    `}
  }
`;

export const DangerButton = styled(Button)`
  background: ${(props) => props.theme.lydia.colors.danger};
`;

export const OutlineButton = styled(BaseButton)`
  padding: 7px 15px;
  border: solid 1px #dcdae1;
  background-color: ${(props) => props.theme.lydia.colors.text.light};
  ${(props) =>
    props.transparent
      ? `
    background-color: transparent;
  `
      : ''}
  color: #201742;
  opacity: 0.7;
  ${(props) => (props.size === 'small' ? 'padding : 5px; font-size:13px;' : '')}
  &:hover, &:active, &:focus {
    border: solid 1px ${(props) => props.theme.lydia.colors.primary};
    color: ${(props) => props.theme.lydia.colors.primary};
    opacity: 1;
  }
  ${(props) =>
    props.active
      ? `
    background-color: ${props.theme.lydia.colors.primary}
    color: ${props.theme.lydia.colors.text.light};
    opacity: 1;
    &:hover, &:active, &:focus {
      background-color: ${props.theme.lydia.colors.primary}
      color: ${props.theme.lydia.colors.text.light};
    }
  `
      : ''}
`;

export const PrimaryOutlineButton = styled(OutlineButton)`
  && {
    border: solid 1px ${(props) => props.theme.lydia.colors.primary};
    margin: 0px !important;
    color: ${(props) => props.theme.lydia.colors.primary};
    opacity: 1;
    > svg {
      stroke: ${(props) => props.theme.lydia.colors.primary};
    }
    &:hover,
    &:active {
      background-color: ${(props) => props.theme.lydia.colors.primary};
      color: ${(props) => props.theme.lydia.colors.text.light};
      > svg {
        stroke: ${(props) => props.theme.lydia.colors.text.light};
      }
    }
  }
`;

export const LightOutlineButton = styled(OutlineButton)`
  background-color: transparent;
  color: ${(props) => props.theme.lydia.colors.text.light};
  opacity: 1;
  &:hover,
  &:active {
    background-color: #fff;
  }
`;

export const Anchor = styled(BaseButton)`
  display: inline-flex;
  color: ${(props) => props.theme.lydia.colors.text.dark};
  ${(props) =>
    props.size === 'small'
      ? `
    padding : 0;
    margin : 0;
  `
      : ''}
  &:hover, &:active, &:focus {
    color: ${(props) => props.theme.lydia.colors.text.dark};
  }
`;

export const PrimaryAnchor = styled(Anchor)`
  color: ${(props) => (props.color ? props.color : props.theme.lydia.colors.primary)};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.lydia.colors.primary};
  }
  padding-left: ${(props) => props.pl};
  margin-top: ${(props) => props.mt};
  position: ${(props) => props.position};
  z-index: ${(props) => props.zIndex};
  right: ${(props) => props.right};
`;

export const SecondaryAnchor = styled(Anchor)`
  color: ${(props) => props.theme.lydia.colors.secondary};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.lydia.colors.secondary};
  }
`;

export const LowAnchor = styled(Anchor)`
  color: #909090;
`;

export const FieldIconButton = styled(Anchor)`
  disaply: flex;
  padding: 0;
  height: 20px;
  line-height: 20px;
`;

export const LinkButton = styled(({ tag = 'button', children, ...props }) =>
  React.createElement(tag, props, children),
)`
  color: ${(props) => props.theme.lydia.colors.primary};
  text-decoration: ${(props) => (props.noUnderline ? 'none' : 'underline')};
  border: none;
  background: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  outline: 0;
  line-height: 1.15;
  &:hover {
    color: #865ecc;
  }
  &:active,
  &:hover,
  $:focus {
    outline: 0;
    text-decoration: none;
  }
  ${(props) => (props.size === 'small' ? 'font-size:13px;' : '')}
`;

export const LightLinkButton = styled(LinkButton)`
  color: ${(props) => props.theme.lydia.colors.text.light};
`;

export const SuccessLinkButton = styled(LinkButton)`
  color: ${(props) => props.theme.lydia.colors.success};
`;

export const LowLinkButton = styled(LinkButton)`
  color: #909090;
  text-decoration: ${(props) => props.lineHide && 'none'};
`;

export const ButtonLabel = styled.span`
  && {
    line-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${(props) =>
    props.hide &&
    `
    @media (${props.theme.lydia.screen.lte[props.hide]}) {
      display : none;
    }
  `}
`;

const GridButtonEl = styled(Anchor)`
  display: inline-flex;
  flex-direction: column;
  margin: 0 5px;
  color: #939393;
  &:hover {
    color: #201742;
  }
`;

const GridButtonLabel = styled.span`
  font-size: 0.9em;
`;

export function GridButton({ tag = 'button', Icon, label, size = 'small', onClick, ...props }) {
  return (
    <GridButtonEl
      tag={tag}
      size={size}
      onClick={(e) => {
        if (e && e.stopPropagation) {
          e.stopPropagation();
        }
        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    >
      <Icon size={16} />
      <GridButtonLabel>{label}</GridButtonLabel>
    </GridButtonEl>
  );
}

export function WalletGridButton({
  tag = 'button',
  Icon,
  label,
  size = 'small',
  onClick,
  ...props
}) {
  return (
    <GridButtonEl
      tag={tag}
      size={size}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    >
      <Icon size={16} />
      <GridButtonLabel>{label}</GridButtonLabel>
    </GridButtonEl>
  );
}

GridButton.propTypes = {
  tag: string,
  Icon: oneOfType([node, func, object]),
  label: string,
  size: string,
};

export const ButtonGroup = styled.div`
  disaply: flex;
  > button,
  a {
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 0;
    margin: 0;
    height: ${(props) => props.height}px;
    font-size: ${(props) => props.fontSize}px;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

ButtonGroup.defaultProps = {
  height: 28,
};
