import { React, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import { Alert, Button } from '@refrens/disco';
import { subject } from '@casl/ability';
import { MdWarning } from 'react-icons/md';
import checkExhaustedQuota from '@refrens/birds/dist/es5/helpers/get-exhausted-doc-quota';
import { redirectToPremium } from '@refrens/jupiter';
import EmailVerificationPrompt from '@/components/widgets/EmailVerificationPrompt';
import Diamond from '../../../public/public/images/invoice/premium-icon-white.svg';

const Wrapper = styled.div`
  .ant-alert {
    text-align: center;
    border-radius: 0;
  }
`;
const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff7e6;
  padding: var(--sizes-label);
  border-radius: var(--sizes-smallest);
  box-shadow: 0px var(--sizes-xsmall) var(--sizes-smallest) rgba(0, 0, 0, 0.1);
  margin: 0;
  width: 100%;
  gap: var(--sizes-small);

  @media (min-width: 568px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    padding: var(--sizes-label) var(--sizes-h3);
    margin: auto;
    gap: var(--sizes-large);
  }
`;

const AlertMessage = styled.span`
  font-size: var(--sizes-h6);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--sizes-smaller);

  @media (min-width: 568px) {
    font-size: var(--sizes-h6);
    margin-bottom: 0;
  }
`;

const AlertButton = styled(Button)`
  padding: var(--sizes-smallest) var(--sizes-h5);
  background: linear-gradient(to right, #e3512e, #feb47b);
  color: white;
  font-weight: 500;
  border: none;
  border-radius: var(--sizes-smallest);
  cursor: pointer;
  outline: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }

  svg {
    margin-right: var(--sizes-smallest);
  }

  @media (min-width: 568px) {
    width: auto;
  }
`;

const getUpgradSubscriptionAlertMessage = ({ isPremium }) => {
  const title = isPremium ? 'Subscription Expired' : 'Trial Expired';
  const message = isPremium
    ? 'Renew to keep accessing premium features. (You can still accept unlimited Upgrad documents.)'
    : 'Get a subscription to access premium features. (You can still accept unlimited Upgrad documents.)';
  const ctaText = isPremium ? 'Renew Subscription' : 'View Plans';

  return {
    text: `${title}: ${message}`,
    ctaText,
  };
};

const getNormalSubscriptionAlertMessage = ({ hasSomeDocsLeft, isPremium }) => {
  const title = isPremium ? 'Subscription Expired' : 'Trial Expired';
  let message = 'Renew your plan to keep accessing premium features.';
  if (!isPremium) {
    message = hasSomeDocsLeft
      ? 'Upgrade to access premium features.'
      : 'Upgrade to create more documents & access premium features.';
  }
  const ctaText = isPremium ? 'Renew Subscription' : 'Upgrade Now';

  return {
    text: `${title}: ${message}`,
    ctaText,
  };
};

const getSubscriptionAlertMessage = (endDate, remainingQuota, isUpgradVendor) => {
  if (isUpgradVendor) {
    return getUpgradSubscriptionAlertMessage({ isPremium: endDate });
  }

  return getNormalSubscriptionAlertMessage({
    hasSomeDocsLeft: remainingQuota > 0,
    isPremium: endDate,
  });
};

export default function StickyHeader({
  withoutVerifyPrompt,
  showYesBankAlert,
  showInactiveUserAlert,
  showDocumentQuotaAlert,
  accessibility,
  activeBusiness,
  isEmailVerified,
}) {
  const {
    preferences,
    users,
    billedTo,
    urlKey,
    country: bizCountry,
    premium,
  } = activeBusiness || {};

  const { bulkExpenditure = false, upgradVendor = false } = preferences || {};
  const { country: billingCountry } = billedTo || {};
  const { enabled = false, endDate, trialActivated = false } = premium || {};
  const { remainingQuota = 50 } = checkExhaustedQuota(activeBusiness, accessibility.rules, 1);

  const redirectCountry = useMemo(() => billingCountry || bizCountry, [billingCountry, bizCountry]);

  const subscriptionAlert = useMemo(() => {
    if (!enabled && trialActivated && showDocumentQuotaAlert) {
      return getSubscriptionAlertMessage(endDate, remainingQuota, upgradVendor);
    }
    return null;
  }, [enabled, trialActivated, showDocumentQuotaAlert, endDate, remainingQuota, upgradVendor]);

  const userCount = users ? Object.keys(users)?.length : 0;
  const maxedOut = !accessibility.can('get', subject('add-user', { limit: userCount }));

  // Function to determine which alert to show based on priority
  const getPriorityAlert = useCallback(() => {
    if (subscriptionAlert) {
      return (
        <AlertContainer>
          <AlertMessage>{subscriptionAlert.text}</AlertMessage>
          <AlertButton
            onClick={() => redirectToPremium(redirectCountry, urlKey)}
            title={subscriptionAlert.ctaText}
          >
            <Diamond /> {subscriptionAlert.ctaText}
          </AlertButton>
        </AlertContainer>
      );
    }

    if (!withoutVerifyPrompt && !isEmailVerified) {
      return <EmailVerificationPrompt />;
    }

    if (showInactiveUserAlert && !bulkExpenditure && maxedOut) {
      return (
        <Alert
          message={
            <>
              <span>Your team members have lost access to this business.</span>
              <Button
                anchor
                variant='secondary'
                onClick={() => redirectToPremium(redirectCountry, urlKey)}
              >
                Upgrade your business to continue &#8250;
              </Button>
            </>
          }
          variant='warning'
        />
      );
    }

    if (showYesBankAlert) {
      return (
        <Alert
          message={
            <>
              <span>International payment are down due to yes bank dependency. </span>
              <a
                href='https://refrens.freshdesk.com/support/solutions/articles/44001911416'
                title='Read More'
                rel='external noopener noreferrer'
                target='_blank'
              >
                Read More.
              </a>
            </>
          }
          variant='error'
          icon={<MdWarning size={20} />}
          showIcon
          closable
        />
      );
    }

    return null;
  }, [
    subscriptionAlert,
    redirectCountry,
    urlKey,
    withoutVerifyPrompt,
    isEmailVerified,
    showInactiveUserAlert,
    bulkExpenditure,
    maxedOut,
    showYesBankAlert,
  ]);

  return <Wrapper className='no-print'>{getPriorityAlert()}</Wrapper>;
}

StickyHeader.propTypes = {
  withoutVerifyPrompt: bool,
  showYesBankAlert: bool,
  showInactiveUserAlert: bool,
};
