/* global window */
/* eslint "no-underscore-dangle" : ["error", {"allow" : ["_store", "_toJS", "_id"]}] */
/* eslint class-methods-use-this: "off" */
/** @TODO REFACTOR eslint error ignored * */

import qs from 'qs';
import appendQuery from 'append-query';
import { observable, computed, action, toJS, autorun, reaction, makeObservable } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import { deleteCookie, getCookie, setCookie } from 'cookies-next/client';
import LocalForage from 'localforage';
import { jwtDecode } from 'jwt-decode';
import awaitGlobal from 'await-global';
import nop from 'nop';
import suprsend from '@suprsend/web-sdk';
import { isBrowser } from '@refrens/disco';
import {
  AppType,
  AuroraFlags,
  loginEvent,
  logoutEvent,
  trackEvent,
  formatAuthError,
  RefrensApi,
  refrensApiGuru,
} from '@refrens/jupiter';
import urlJoin from 'url-join';
import merge from 'lodash/merge';
import { captureException, setTags, setUser } from '@sentry/node';
import { googleLogout } from '@react-oauth/google';
import PlatformReqHeader from '@refrens/fence/system/platform/platformRequestHeaders';

import NextConfig from '@/config';
import isMobile from '@/lib/is-mobile';
import getConfiguration from '@/helpers/getConfiguration';
import { getPath } from '@/lib/getUrl';
import constants from '@/constants/settings';
import isDibella from '@/helpers/isDibella';
import messageAurora from '@/helpers/messageAurora';
import { generateApiHeaders, apiQueryKeys } from './helpers/apiHeaders';

const { localStorageVariables } = constants;

const { publicRuntimeConfig } = NextConfig;

const {
  buildId,
  nodeEnv,
  referrerCookie,
  referrerQuery,
  inviteQuery,
  invisibleRecaptchaKey,
  eInvoiceKey,
  apiQueryKey,
  utmKey,
  baseDomain,
  apiDomain,
} = publicRuntimeConfig;
const activeBusinessKey = 'activeBusiness';

const isDev = nodeEnv === 'development';

let store = null;
let tagsInitialized = false;
let loginEventForUser = null;

enableStaticRendering(!isBrowser());

class Store {
  originalToken = null;

  loginErrorUnfiltered = null;

  auth = null;

  activeBusiness;

  isMobile = true;

  asideCollapsed = true;

  debugUi = false;

  cashfreeInitialized = false;

  crispReady = false;

  apiQuery = {};

  isNewFileSupported = true;

  showYesBankAlert = false;

  loginError = '';

  suggestionCount = {
    invoice: 0,
    expenditure: 0,
    purchaseOrder: 0,
    openLeads: 0,
    openRequirements: 0,
    credits: 0,
  };

  inviterReward = {};

  showTour = false;

  showTutorialVideo = false;

  isReady = false; // final auth hydration status flag

  showNetworkNotification = false;

  pageVisible = true;

  redirectedUrls = [];

  premiumPopup = { feature: '', defaultOpen: false, withoutModal: false, params: {} };

  selectedFy = {};

  financialYears = [];

  socket = null;

  apiQueryReady = false;

  isDry = false; // dry flag is to prevent external api calls and events; a dry store indicates that it is not the final initialized store

  refrensAccountManager = null;

  proxyBusinesses = [];

  fileDownloadAlert = {
    show: false,
    title: '',
    fileName: '',
    fileUrl: '',
    selfUploaded: false,
  };

  updateSelectedFy = async (newFy) => {
    if (newFy?.id && newFy?.label) {
      const { businesses = [] } = this.auth || {};
      const activeBusiness = (businesses || []).find((b) => b._id === this.activeBusiness);

      this.selectedFy = newFy;

      // store this in localstorage to use it across the book keeping pages
      localStorage.setItem(
        localStorageVariables.SelectedFy,
        JSON.stringify({
          label: newFy.label,
          id: newFy.id,
          business: activeBusiness?.urlKey || '',
          start_date: newFy.start_date,
          end_date: newFy.end_date,
        }),
      );
    }
  };

  get business() {
    if (this.auth && this.auth.businesses && this.auth.businesses.length > 0) {
      if (this.activeBusiness) {
        const activeBiz = this.auth.businesses.find(
          (biz) => biz._id === this.activeBusiness && !biz.isRemoved,
        );

        if (activeBiz) {
          return activeBiz;
        }
      }
      return this.auth.businesses.find((biz) => !biz.isRemoved);
    }
    return undefined;
  }

  set business(biz) {
    const bizId = biz._id || biz;
    this.activeBusiness = bizId;
    if (isBrowser()) {
      LocalForage.setItem(activeBusinessKey, bizId).catch(nop);
      setCookie(activeBusinessKey, bizId, { maxAge: 60 * 60 * 24 * 365 });
    }
  }

  get businesses() {
    const businesses = {};
    if (this.auth && this.auth.businesses && this.auth.businesses.length > 0) {
      this.auth.businesses.forEach((biz) => {
        businesses[biz.urlKey] = biz;
      });
    }
    return businesses;
  }

  get isRefrensUser() {
    return this.auth && this.auth.email.includes('@refrens.com');
  }

  get isAppUser() {
    return this.auth && this.auth.source === 'APP';
  }

  get sweetReferrer() {
    return getCookie(referrerCookie);
  }

  set sweetReferrer(referrer) {
    if (isBrowser()) {
      if (referrer) {
        setCookie(referrerCookie, referrer, { maxAge: 60 * 60 * 24 * 60 });
      } else {
        deleteCookie(referrerCookie);
      }
    }
  }

  /**
   * Computed property to get an API instance which is kept up-to-date with current token and apiQuery
   */
  get api() {
    const apiQueryHeaders = generateApiHeaders(this.apiQuery);

    const serverApi = refrensApiGuru.apiInstance;

    if (serverApi) {
      // add api query platform headers based on useragent
      const { isAurora, appVersion, platform } = AuroraFlags.userAgentInfo();
      const platformHeaders = {};
      if (isAurora) {
        platformHeaders[PlatformReqHeader.MOBILE_APP_CHECK] = true;
        platformHeaders[PlatformReqHeader.MOBILE_APP_OS] = platform;
        platformHeaders[PlatformReqHeader.MOBILE_APP_VERSION] = appVersion;
      }
      merge(serverApi, { defaults: { headers: { ...apiQueryHeaders, ...platformHeaders } } });
    }

    return serverApi;
  }

  constructor(state, dry = false) {
    makeObservable(this, {
      auth: observable,
      activeBusiness: observable,
      isMobile: observable,
      asideCollapsed: observable,
      debugUi: observable,
      cashfreeInitialized: observable,
      crispReady: observable,
      apiQuery: observable,
      isNewFileSupported: observable,
      showYesBankAlert: observable,
      loginError: observable,
      suggestionCount: observable,
      inviterReward: observable,
      showTour: observable,
      showTutorialVideo: observable,
      isReady: observable,
      showNetworkNotification: observable,
      pageVisible: observable,
      redirectedUrls: observable,
      premiumPopup: observable,
      selectedFy: observable,
      financialYears: observable,
      socket: observable,
      apiQueryReady: observable,
      isDry: observable,
      refrensAccountManager: observable,
      fileDownloadAlert: observable,
      updateSelectedFy: action,
      business: computed,
      businesses: computed,
      isRefrensUser: computed,
      isAppUser: computed,
      api: computed,
      initStore: action,
      setupBrowserEnv: action,
      initAuth: action,
      initApiQuery: action,
      removeRefQuery: action,
      refreshToken: action,
      getProxyBusiness: action,
      setLastActiveBiz: action.bound,
      setSuggestionCount: action.bound,
      setDocSuggestionCount: action.bound,
      getInviterReward: action,
      updateInvoiceCount: action,
      updateExpenditureCount: action,
      updatePurchaseOrderCount: action,
      updateSalesOrderCount: action,
      updateOpenLeadCount: action,
      updateRequirementCount: action,
      registerAuth: action,
      setActiveBusiness: action.bound,
      showPremiumPopup: action,
      logout: action,
      showFileDownloadAlert: action,
      dismissFileDownloadAlert: action,
      collapseAside: action.bound,
      openAside: action.bound,
      toggleTour: action.bound,
      toggleTutorial: action.bound,
    });

    this.logStoreState('pre constructor', state, dry);

    if (!state) {
      throw new Error('Store initial `state` is undefined');
    }

    this.initPromise = this.initStore(state, dry);

    if (isBrowser() && !dry) {
      this.activeBusinessReaction = reaction(
        () => this.activeBusiness,
        (activeBiz) => {
          if (activeBiz) {
            this.setLastActiveBiz(activeBiz);
          } else {
            setTags({
              // update sentry tags
              active_business: undefined,
              active_business_id: undefined,
            });
          }
        },
      );
    }
  }

  dismissFileDownloadAlert = () => {
    this.fileDownloadAlert = {
      show: false,
      title: '',
      fileName: '',
      fileUrl: '',
      selfUploaded: false,
    };
  };

  showFileDownloadAlert = ({ title = '', fileName = '', fileUrl = '', selfUploaded = false }) => {
    this.fileDownloadAlert = {
      show: true,
      title,
      fileName,
      fileUrl,
      selfUploaded,
    };
  };

  logStoreState(...extraInfo) {
    if (isBrowser() && apiDomain === 'https://api.refrens.net') {
      if (extraInfo) {
        // eslint-disable-next-line no-console
        console.info(...extraInfo);
        // eslint-enable-next-line no-console
      }
      // eslint-disable-next-line no-console
      console.info('############## Store State:', toJS(this));
      // eslint-enable-next-line no-console
    }
  }

  async initStore(state, dry = false) {
    this.isDry = dry;
    this.originalToken = state.originalToken;
    this.loginErrorUnfiltered = state.loginErrorUnfiltered;
    this.skipDefaultAuth = !!state.skipDefaultAuth;
    await this.initAuth(state.auth, state.urlBusinessId, state.loginError);

    // after auth hydration
    if (isBrowser()) {
      // conditions for initialising only once
      if (!this.isDry && !tagsInitialized && !this.isAppUser) {
        // initialize datalayer tags
        tagsInitialized = true;
        import('./helpers/tags')
          .then(async ({ default: init }) => {
            await init(store);
          })
          .catch(() => {});
      }

      this.setupBrowserEnv();
    }
    this.initApiQuery();
    this.getInviterReward();
    await this.getProxyBusiness();

    this.isReady = true;
  }

  async setupBrowserEnv() {
    this.isMobile = isMobile();

    if (isDev) {
      this.debugUi = true;
    }
  }

  async initAuth(auth, urlBusinessId, loginError) {
    const hasToken = !!refrensApiGuru.accessToken;
    if (!isBrowser()) {
      if (hasToken) {
        throw new Error('initAuth: Token should not be used in server side');
      }
      return;
    }

    if (this.skipDefaultAuth) {
      this.auth = null;
      setUser(null);
      return;
    }

    if (hasToken && auth) {
      await this.registerAuth(auth, urlBusinessId);
    } else if (loginError) {
      this.loginError = loginError;
    } else if (isBrowser()) {
      await this.refreshToken();
    }
  }

  async initApiQuery() {
    // this.sweetReferrer = state[referrerQuery];
    if (isBrowser()) {
      try {
        const { search = '', href } = window.location;
        const query = qs.parse(search.replace(/^\?/, ''));
        if (query[referrerQuery]) {
          this.sweetReferrer = query[referrerQuery];
        }
        const {
          utm_medium: utmMedium,
          utm_source: utmSource,
          utm_campaign: utmCampaign,
          utm_term: utmTerm,
        } = query;

        let apiQuery = await LocalForage.getItem(apiQueryKey);
        apiQuery = apiQuery || {};

        if (!apiQuery?.lp && utmMedium && utmSource && utmCampaign) {
          apiQuery[utmKey] = {
            medium: utmMedium,
            source: utmSource,
            campaign: utmCampaign,
            term: typeof utmTerm === 'string' ? decodeURI(utmTerm) : undefined,
          };
        }

        if (document && document.referrer && !document.referrer.includes('refrens.com')) {
          apiQuery.hr = document.referrer;
        }

        if (!apiQuery?.lp && href) {
          apiQuery.lp = href;
        }

        apiQueryKeys.forEach((key) => {
          let { [key]: value = '' } = apiQuery;
          if (query[key]) {
            value = query[key];
          }
          if (value) {
            apiQuery[key] = value;
          }
        });

        this.apiQuery = observable(apiQuery);

        this.apiQueryReady = true;

        autorun(() => {
          LocalForage.setItem(apiQueryKey, this.apiQuery).catch(nop);
        });
      } catch (err) {
        this.apiQueryReady = true;
      }
    }
  }

  removeRefQuery() {
    this.apiQuery[inviteQuery] = '';
    this.apiQuery[referrerQuery] = '';
  }

  async refreshToken() {
    if (!isBrowser()) {
      return;
    }

    let authResult;
    try {
      authResult = await refrensApiGuru.validateAccessToken();
      if (authResult?.accessToken) {
        await this.registerAuth(authResult.user);
      } else {
        this.logStoreState('pre refreshToken -> browserToken not found', toJS(this.auth));
        // reset auth if no token is found
        this.auth = null;
        setUser(null);
        this.logStoreState('post refreshToken -> browserToken not found', toJS(this.auth));
      }
    } catch (e) {
      const errorDetails = formatAuthError(e);
      this.loginError = errorDetails.message;
      setUser(null);
    }
  }

  async getProxyBusiness() {
    const { businesses = [] } = this.auth || {};
    const biz = (businesses || []).find((b) => !b.isRemoved && b.isAllowedProxy);
    if (biz) {
      const { data: proxyBusinesses = [] } = await this.api.get(`/businesses/${biz.urlKey}/proxy`);
      this.proxyBusinesses = proxyBusinesses;
    }
  }

  async setLastActiveBiz(bizId) {
    if (!isBrowser() || this.isDry) {
      return;
    }
    const { businesses = [], lastActiveBusiness } = this.auth || {};
    const currentBusiness = businesses.some((biz) => biz._id === bizId);
    if (currentBusiness && bizId !== lastActiveBusiness) {
      setTags({
        // update sentry tags
        active_business: currentBusiness?.urlKey || undefined,
        active_business_id: currentBusiness?._id || undefined,
      });
      await this.api.patch('/users/me', { lastActiveBusiness: bizId });
      this.auth.lastActiveBusiness = bizId;
    }
  }

  async setSuggestionCount(bizId, leadGetAllowed) {
    if (!isBrowser()) {
      return;
    }
    const { businesses = [] } = this.auth || {};
    const currentBusiness = businesses.find((biz) => biz._id === bizId);
    const promises = [];

    if (currentBusiness && leadGetAllowed) {
      promises.push(
        this.api.get(
          appendQuery(`/businesses/${currentBusiness.urlKey}/leads`, {
            $limit: 0,
            status: { $in: ['OPEN', 'NEW'] },
            source: { $nin: ['REQUIREMENT'] },
            isLegacy: false,
          }),
        ),
      );
    }

    if (currentBusiness) {
      promises.push(this.api.get(`/wallets/me?rcbalance=true`));
    }

    const [requirements, leads, credits] = await Promise.all(promises);
    this.suggestionCount.openLeads = leads ? leads.data.total : 0;
    this.suggestionCount.openRequirements = requirements ? requirements.data.total : 0;
    this.suggestionCount.credits = credits ? credits.data.credits : 0;
  }

  async setDocSuggestionCount(bizId, billType) {
    const { businesses = [], emailVerified } = this.auth || {};
    const currentBusiness = businesses.find((biz) => biz._id === bizId);

    const billTypeMapping = {
      INVOICE: { route: '/invoices', key: 'invoice' },
      EXPENDITURE: { route: '/expenditures', key: 'expenditure' },
      PURCHASEORDER: { route: '/purchaseorders', key: 'purchaseOrder' },
      SALESORDERS: {
        route: '/salesorders',
        key: 'salesOrder',
      },
    };

    if (currentBusiness && billTypeMapping[billType] && emailVerified) {
      // const query = {
      //   business: currentBusiness.urlKey,
      //   $limit: 0,
      //   billType,
      // };
      // const response = await this.api.get(appendQuery(billTypeMapping[billType].route, query));
      this.suggestionCount[billTypeMapping[billType].key] = 0;
    }
  }

  async getInviterReward() {
    // const { data:inviterReward } = await this.api.get('/configurations/rc.business.create.inviter');
    const inviterReward = getConfiguration('rc.business.create.inviter');
    this.inviterReward = inviterReward;
  }

  updateInvoiceCount() {
    setTimeout(() => {
      if (this.suggestionCount.invoice > 0) {
        this.suggestionCount.invoice -= 1;
      }
    }, 500);
  }

  updateExpenditureCount() {
    setTimeout(() => {
      if (this.suggestionCount.expenditure > 0) {
        this.suggestionCount.expenditure -= 1;
      }
    }, 500);
  }

  updatePurchaseOrderCount() {
    setTimeout(() => {
      if (this.suggestionCount.purchaseOrder > 0) {
        this.suggestionCount.purchaseOrder -= 1;
      }
    }, 500);
  }

  updateSalesOrderCount() {
    setTimeout(() => {
      if (this.suggestionCount.salesOrder > 0) {
        this.suggestionCount.salesOrder -= 1;
      }
    }, 500);
  }

  updateOpenLeadCount({ increaseCount = false }) {
    setTimeout(() => {
      if (increaseCount) {
        this.suggestionCount.openLeads += 1;
      } else if (this.suggestionCount.openLeads > 0) {
        this.suggestionCount.openLeads -= 1;
      }
    }, 500);
  }

  updateRequirementCount({ increaseCount = false }) {
    setTimeout(() => {
      if (increaseCount) {
        this.suggestionCount.openRequirements += 1;
      } else if (this.suggestionCount.openRequirements > 0) {
        this.suggestionCount.openRequirements -= 1;
      }
    }, 500);
  }

  configureSuprSendSdk = () => {
    if (!isBrowser() || isDibella() || !publicRuntimeConfig.suprsend?.key) {
      return;
    }
    try {
      suprsend.init(publicRuntimeConfig.suprsend.key, publicRuntimeConfig.suprsend.secret, {
        vapid_key: publicRuntimeConfig.suprsend.vapidKey,
      });
      if (!this.auth?._id) {
        // reset user to prevent sending notifications of old user, if any
        suprsend.reset();
        return;
      }
      suprsend.identify(this.auth._id);
      if (!AuroraFlags.isAurora()) {
        suprsend.web_push.register_push();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  resetSuprsend = async () => {
    if (!isBrowser() || isDibella()) {
      return;
    }
    try {
      suprsend.reset();
      // adding a delay to ensure that suprsend is reset
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  async registerAuth(auth, urlBusinessId) {
    this.logStoreState('pre registerAuth -> auth', toJS(this.auth));
    this.auth = auth;
    this.logStoreState('post registerAuth -> auth', toJS(this.auth));
    if (isBrowser()) {
      if (urlBusinessId) {
        this.setActiveBusiness(urlBusinessId);
      } else {
        try {
          this.setActiveBusiness(await LocalForage.getItem(activeBusinessKey));
        } catch (err) {
          this.activeBusiness = null;
        }
      }
      const { _id, name, email, phone, avatar, googleId, googlePicture } = auth;

      LocalForage.setItem('UserInfo', {
        _id,
        name,
        email,
        phone,
        avatar,
        googleLogin: !!googleId,
        googlePicture,
      }).catch(nop);

      if (_id) {
        setUser({ email, id: _id });
      }

      if (!this.isDry) {
        awaitGlobal('$crisp').then(($crisp) => {
          $crisp.push(['set', 'user:email', auth.email]);
        });
      }

      // setting the params same as AuthenticationResult
      const authResult = {
        user: auth,
      };
      if (
        !this.isDry &&
        // prevent sending multiple login events for the same user
        (!loginEventForUser || (loginEventForUser && loginEventForUser !== auth?._id))
      ) {
        loginEvent(authResult, AppType.LYDIA);
        loginEventForUser = auth?._id;
        this.configureSuprSendSdk();
      }
    } else {
      this.setActiveBusiness(getCookie(activeBusinessKey));
    }
  }

  setActiveBusiness(id) {
    if (this.auth.businesses && this.auth.businesses.length > 0) {
      if (id) {
        if (this.auth.businesses.find((biz) => biz._id === id)) {
          this.business = id;
        }
      } else {
        this.business = this.auth.businesses[0]._id;
      }
    } else {
      this.activeBusiness = null;
    }
  }

  showPremiumPopup = (featureKey, showModal, isExplainerModal = false, params = {}) => {
    this.premiumPopup = {
      featureKey,
      defaultOpen: showModal,
      withoutModal: !showModal,
      isExplainerModal,
      params,
    };
  };

  async logout(showLastLogin = true) {
    if (messageAurora('onLogout')) {
      // Let Aurora handle Logout flow
      return;
    }
    this.logStoreState('pre logout change -> auth', toJS(this.auth));
    this.auth = null;
    setUser(null);
    try {
      googleLogout();
    } catch (err) {
      // noop
    }
    this.logStoreState('post logout change -> auth', toJS(this.auth));
    if (this.socket) {
      this.socket.close();
    }
    // reset suggestion count on logout
    this.suggestionCount = {
      invoice: 0,
      expenditure: 0,
      openLeads: 0,
      openRequirements: 0,
    };

    await refrensApiGuru.clearTokens();
    deleteCookie('_eat'); // remove email token

    LocalForage.removeItem(eInvoiceKey);
    logoutEvent();

    await this.resetSuprsend();

    const loginParams = {};
    if (!showLastLogin) {
      loginParams.newLogin = true;
    }

    window.location.href = appendQuery(urlJoin(baseDomain, 'login'), loginParams);
  }

  collapseAside() {
    if (this.asideRef && this.asideRef.current && this.asideRef.current.wrappedInstance) {
      this.asideRef.current.wrappedInstance.collapsed = true;
    }
  }

  openAside() {
    if (this.asideRef && this.asideRef.current && this.asideRef.current.wrappedInstance) {
      this.asideRef.current.wrappedInstance.collapsed = false;
    }
  }

  openHelp() {
    if (AuroraFlags.isAurora()) {
      // Let Aurora handle Crisp chat
      messageAurora('openCrisp');
    } else {
      awaitGlobal('$crisp').then(($crisp) => {
        $crisp.push(['do', 'chat:open']);
      });
    }
  }

  isSelfBusiness = (business) => {
    return this.auth && !!this.auth.businesses.find((b) => b.urlKey === business);
  };

  isProxyBusiness = (business) => {
    return (
      Array.isArray(this.proxyBusinesses) && this.proxyBusinesses.some((b) => b.urlKey === business)
    );
  };

  isSelfRedirectedBusiness = (business) => this.redirectedUrls.includes(business);

  isSelfBusinessById = (business) =>
    this.auth && !!this.auth.businesses.find((b) => b._id === business);

  isSelfActiveBusiness = (business) => this.business && this.business.urlKey === business;

  toggleBusinessStatus = (urlKey) => {
    const { businesses = [] } = this.auth;
    this.auth.businesses = businesses.map((biz) => {
      const isRemoved = biz.urlKey === urlKey ? !biz.isRemoved : biz.isRemoved;
      return { ...biz, isRemoved };
    });
  };

  hasPreference = (business, preference = 'premiumn') => {
    const { preferences = {} } = business || {};
    const { [preference]: hasAccess = false, bulkExpenditure: isUpgradUser = false } = preferences;
    return hasAccess || (preference === 'premiumn' && isUpgradUser);
  };

  isOnTrial = (business) => {
    const { premium } = business || {};
    const { onTrial = false } = premium || {};
    return onTrial;
  };

  isPremium = (business, ...planTypes) => {
    const { premium } = business || {};
    const { planType: pT } = premium || {};
    return planTypes.includes(pT);
  };

  isTrialActivated = (business) => {
    const { premium } = business || {};
    const { trialActivated = false, activated } = premium || {};
    return trialActivated || activated;
  };

  hideFloatingHireBtn = (business) => {
    const { configuration = {} } = business || {};
    const { hideFloatingHireBtn = false } = configuration;
    return hideFloatingHireBtn;
  };

  toggleTour(showTour) {
    this.showTour = showTour;
  }

  toggleTutorial(showTutorialVideo) {
    this.showTutorialVideo = showTutorialVideo;
  }

  recaptchaExecute(pageName = 'siginin') {
    return new Promise((resolve) => {
      awaitGlobal('grecaptcha').then((grecaptcha) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(invisibleRecaptchaKey, { action: pageName }).then(resolve);
        });
      });
    });
  }

  setLocalFeatureFlags(key, value) {
    if (isBrowser()) {
      LocalForage.setItem(`localfeatureflag.[${key}]`, value).catch(nop);
    }
  }

  async getLocalFeatureFlags(key, defaultFlagValue) {
    if (!isBrowser()) {
      return defaultFlagValue;
    }
    const featureFlag = await LocalForage.getItem(`localfeatureflag.[${key}]`);
    if (typeof featureFlag === 'boolean') {
      return featureFlag;
    }
    return defaultFlagValue;
  }

  // execute google auth strategy
  execGoogleAuth = async (googleAuth) => {
    const apiQueryHeaders = generateApiHeaders(this.apiQuery);
    const res = await refrensApiGuru.generateRefreshToken(
      null,
      'google',
      googleAuth,
      apiQueryHeaders,
    );
    const { user } = res;
    if (res.isSignup) {
      trackEvent('Onboarding', this.isMobile ? 'Signup_mobile' : 'Signup', 'GOOGLE_ONE_TAP_SIGNUP');
    } else {
      trackEvent('FormSubmit', 'Login', 'GOOGLE_ONE_TAP_LOGIN');
    }
    await this.registerAuth(user);
    return res;
  };

  checkExistingEmail = async (email, recaptchaHeaders) => {
    const { data = {} } = await RefrensApi().get('/users', {
      params: { email, $limit: 1 },
      headers: { ...(recaptchaHeaders || {}) },
    });

    return data.isExistingUser;
  };

  authenticate = async (data, recaptchaHeaders) => {
    try {
      const { type, strategy, ...authData } = data;
      const apiQueryHeaders = generateApiHeaders(this.apiQuery);
      const combinedHeaders = { ...recaptchaHeaders, ...apiQueryHeaders };

      let resData;
      if (type === 'signup') {
        const res = await refrensApiGuru.createUser(authData, combinedHeaders);
        const { user } = res;
        trackEvent('Onboarding', this.isMobile ? 'Signup_mobile' : 'Signup', user.name);
        await this.registerAuth(user);
      } else if (type === 'signin') {
        const res = await refrensApiGuru.generateRefreshToken(
          null,
          strategy,
          authData,
          combinedHeaders,
        );
        const { user } = res;
        trackEvent('FormSubmit', 'Login', 'Login');
        await this.registerAuth(user);
      } else {
        resData = await this.execGoogleAuth(authData);
      }
      if (type === 'signup' || resData?.isSignup) {
        window.location.href = getPath('NewBusiness');
      } else {
        window.location.reload();
      }
      return Promise.resolve(resData);
    } catch (err) {
      return Promise.reject(formatAuthError(err));
    }
  };

  /**
   * Clean up store and dispose all reactions
   */
  dispose = () => {
    if (this.activeBusinessReaction) {
      this.activeBusinessReaction();
    }
  };
}

async function getInitialState(token, skipDefaultAuth = false) {
  const state = {
    isEmailToken: false,
    token: null,
    auth: null,
    originalToken: token,
    loginErrorUnfiltered: '',
    skipDefaultAuth,
  };

  if (!token && !skipDefaultAuth) {
    // If client authentication is already done, try using the existing token without authentication
    if (store?.auth?._id && refrensApiGuru.accessToken) {
      const existingToken = refrensApiGuru.checkAccessToken();
      if (existingToken && refrensApiGuru.checkTokenIntegrity()) {
        state.token = existingToken;
        state.auth = store.auth;

        return state;
      }
    }

    // If no token is provided, try to authenticate client
    try {
      const res = await refrensApiGuru.authenticateClient();
      if (res?.accessToken) {
        const { user, accessToken } = res;
        state.auth = user || {};
        state.token = accessToken; // update token if new token is provided in response
        if (user?._id) {
          setUser({ email: user.email, id: user._id });
        }
      }
      return state;
    } catch (e) {
      const errorDetails = formatAuthError(e);
      state.loginError = errorDetails.message;
      state.loginErrorUnfiltered = e;
      if (!e?.response?.data && errorDetails.status !== 429) {
        captureException(e, {
          tags: {
            authenticationError: true,
            storeInitialState: true,
          },
          extra: {
            token,
          },
        });
      }
    }
  }

  // Handle mail-token strategy
  if (token) {
    if (!isBrowser()) {
      throw new Error('Token must not be provided in server side rendering');
    }

    let strategy;

    try {
      // try to decode provided token to get authentication strategy
      const ct = jwtDecode(token);
      const { auth: authPayload = {} } = ct;
      strategy = authPayload?.strategy;
    } catch (err) {
      state.loginError = 'This session has been invalidated. Please login.';
      state.loginErrorUnfiltered = err;
      captureException(err, {
        tags: {
          tokenParseError: true,
          storeInitialState: true,
        },
        extra: {
          token,
        },
      });
    }

    if (strategy) {
      if (strategy === 'mail-token') {
        state.isEmailToken = true;
      } else {
        throw new Error('Unsupported strategy');
      }

      try {
        const { user, accessToken } = await refrensApiGuru.generateRefreshToken(token, strategy);
        state.auth = user || {};
        state.token = accessToken; // update token if new token is provided in response
        if (user?._id) {
          setUser({ email: user.email, id: user._id });
        }
      } catch (e) {
        const errorDetails = formatAuthError(e);
        state.loginError = errorDetails.message;
        state.loginErrorUnfiltered = e;
        if (!e?.response?.data && errorDetails.status !== 429) {
          captureException(e, {
            tags: {
              authStrategy: strategy,
              authenticationError: true,
              storeInitialState: true,
            },
            extra: {
              token,
            },
          });
        }
      }

      if (state.auth) {
        // if auth was successful and strategy is mail-token, update emailVerified flag
        try {
          if (!state.auth?.emailVerified) {
            // update emailVerified flag
            await RefrensApi(token).patch('/users/me', { emailVerified: true });
            state.auth.emailVerified = true;
          }
        } catch (err) {
          if (err?.response?.status !== 429) {
            captureException(err, {
              tags: {
                authStrategy: strategy,
                mailTokenUserPatchError: true,
                storeInitialState: true,
              },
              extra: {
                token,
                userId: state.auth?._id,
              },
            });
          }
        }
      }
    } else {
      // do not support tokens without strategy
      state.loginError = 'This session has been invalidated. Please login.';
    }
  }

  return state;
}

function StoreFactory(state, forceInitialize = false, dry = false) {
  if (isBrowser()) {
    if (!store || forceInitialize) {
      if (!state) {
        window.location.reload();
      }
      store = new Store(state, dry);
    }

    if (isDev) {
      window._store = store;
      window._toJS = toJS;
    } else {
      window['RW50ZXIgVGhlIE1hdHJpeA=='] = {
        TmVv: store,
        QnJlYWsgT3V0: toJS,
      };
    }

    window.lydia = {
      ...(window.lydia || {}),
      buildId,
      toggleTour: store.toggleTour,
      toggleTutorial: store.toggleTutorial,
      setLocalFeatureFlags: store.setLocalFeatureFlags,
      getLocalFeatureFlags: store.getLocalFeatureFlags,
    };

    return store;
  }

  return new Store(state, dry);
}

export { StoreFactory, getInitialState };
