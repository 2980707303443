import NProgress from 'nprogress';

let runners = 0;
NProgress.configure({ showSpinner: false });

const SingleProgress = {
  start() {
    if (!runners) {
      NProgress.start();
    }
    runners += 1;
  },
  done() {
    setTimeout(() => {
      if (runners) {
        runners -= 1;
      }
      if (!runners) {
        NProgress.done();
      } else {
        NProgress.inc();
      }
    }, 50);
  },
};

export default SingleProgress;
