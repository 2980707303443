import styled from 'styled-components';

const PagePaper = styled.div`
  && {
    padding: 32px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    margin: 0 auto 20px auto;
    ${(props) =>
      !props.overflow &&
      `
        overflow: hidden;
    `}

    &:last-child {
      margin-bottom: 0;
    }
    position: relative;
    ${(props) =>
      props.bg === 'none' &&
      `
      padding : 30px;
      background-color: transparent;
      box-shadow : none;
      margin-bottom : 0px;
    `}
    ${(props) =>
      props.displayType === 'column'
        ? `
      max-width: 580px;
      @media (max-width : 500px) {
        padding : 10px;
        box-shadow : none;
        border-radius : 0;
      }
    `
        : `
      @media (${props.theme.lydia.screen.lte.sm}) {
        padding : 15px;
        margin: 0px;
        ${
          props.displayType !== 'card' &&
          `
          box-shadow : none;
          border-radius : 0;
        `
        }
      }
    `}
    > h1 {
      margin-top: 0;
    }
    @media print {
      padding: 0;
      margin: 0;
      box-shadow: none;
    }
  }
`;

PagePaper.defaultProps = {
  elevation: 1,
};

export default PagePaper;
