import React from 'react';
import { bool, string } from 'prop-types';

import PagePaper from '@/components/blocks/PagePaper';

import Head from './head';

function CardPage({ children, displayType, bg, className, noHead, style, overflow, ...headProps }) {
  return (
    <PagePaper
      displayType={displayType}
      bg={bg}
      className={className}
      style={style}
      overflow={overflow}
    >
      {!noHead && <Head {...headProps} />}
      {children}
    </PagePaper>
  );
}

CardPage.propTypes = {
  bg: string,
  displayType: string,
  noHead: bool,
};

export default CardPage;
