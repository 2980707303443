import { type Dispatch, useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import appendQuery from 'append-query';
import { isBrowser } from '@refrens/disco';
import NextConfig from '@/config';

import Routes from '@/router';
import { getInitialState, StoreFactory } from '@/store';
import isDibella from '@/helpers/isDibella';
import { captureException } from '@/helpers/sentry';
import NProgress from '@/lib/nprogress';
import type { CustomAppProps, HydrationData, PageConfig } from './types';

const { publicRuntimeConfig } = NextConfig;

const { nodeEnv, authQuery: authQueryKey } = publicRuntimeConfig;

const isDev = nodeEnv === 'development';

/**
 * This hook is used to hydrate the page state on the client side.
 * This hook is used to mimic the getInitialProps behavior of NextJs on the client side.
 *
 * We are using this hook to eliminate SSR on pages where it is not required. Migrating these pages to CSR would have been a huge task. Hence, we have just replaced the `getInitialProps` of components by `withInitialProps` which is a static method on the component. This method is called by this hook to fetch the initial props for the component after the page is rendered on the client side or on page transitions.
 */
const useClientStateHydration = (props: CustomAppProps, store: any, setStore: Dispatch<any>) => {
  const { Component, pageProps: initialPageProps } = props || {};
  const { ssrProps } = initialPageProps || {};

  const router = useRouter();
  const { isReady: isRouterReady } = router;

  const [pageConfig, setPageConfig] = useState<PageConfig>({
    ready: !!initialPageProps?.isServerRendered,
    pauseComponentRender: false,
    isRouting: false,
    rerunInitialProps: false,
    isServerRendered: initialPageProps?.isServerRendered || false,
    componentIsSSR: initialPageProps?.componentIsSSR || false,
    pageProps: ssrProps,
    statusCode: ssrProps?.statusCode || 200,
    isNetworkError: initialPageProps?.isNetworkError || false,
    initialState: initialPageProps?.initialState || {},
    uniquePagePropsMatcher: undefined,
  });

  const {
    ready: isPageReady,
    rerunInitialProps,
    isRouting,
    isServerRendered,
    isNetworkError,
  } = pageConfig || {};

  /**
   * This ref is used to store the AbortController instance to abort the previous initialProps run request when a new request is made.
   * Usually this would happen on page transitions when the user navigates too fast to a new page before the previous page's initial props are fetched.
   * If the previous request is not aborted, it might lead to rendering the wrong page props on the page.
   */
  const abortControllerRef = useRef<AbortController | null>(null);

  /**
   * This effect is used to handle the route change events and reset the page config accordingly.
   * See: https://nextjs.org/docs/pages/api-reference/functions/use-router#routerevents
   */
  useEffect(() => {
    const handleRouteChange = (url: any, { shallow }) => {
      if (isDev) {
        console.info(
          `App is changing to ${url} from ${router.asPath} ${shallow ? 'with' : 'without'} shallow routing`,
        );
      }
      if (!shallow) {
        let isSameUrl = false;

        if (url && router.asPath) {
          // clean url from query params and hash and compare
          isSameUrl =
            router.asPath.split('?')?.[0]?.split?.('#')?.[0] ===
            url.split('?')?.[0]?.split?.('#')?.[0];
        }

        setPageConfig((prev) => ({
          ...prev,
          pauseComponentRender: !isSameUrl,
          rerunInitialProps: true,
          isRouting: true,
        }));

        if (abortControllerRef.current) {
          if (isDev) {
            console.info('handleRouteChange -> Aborting previous request');
          }
          abortControllerRef.current?.abort();
          abortControllerRef.current = null;
        }
      }
    };

    const handleRouteComplete = (url: any, { shallow }) => {
      if (isDev) {
        console.info(
          `App has changed to ${url} from ${router.asPath} ${shallow ? 'with' : 'without'} shallow routing`,
        );
      }
      if (!shallow) {
        setPageConfig((prev) => ({
          ...prev,
          isRouting: false,
        }));
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteComplete);

    // If the component is unmounted, unsubscribe from the event with the `off` method
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  }, [router]);

  const runInitialProps = async (signal: AbortSignal) => {
    const isServer = !isBrowser();

    if (isServer) {
      throw new Error('clientStateHydration should only be called on the client side');
    }

    let newPageProps: HydrationData = {};
    let updatedIsNetworkError = isNetworkError;
    // set default status code from response in case of error in ssr; else 200
    let updatedStatusCode = ssrProps?.statusCode || 200;
    let newInitialState: Record<string, any> = {};
    let lydiaStore = store || null;

    try {
      let queryBusinessResponse: any = null;
      // We need to parse the params from the path using the Routes.match method since we are using custom routes
      const { params } = (Routes.match(router.asPath) || {}) as any;
      const query = { ...(router.query || {}), ...params }; // set query as empty object if not present
      const { business: queryBusiness, [authQueryKey]: authQuery } = query;

      let token: string | null = null;
      /**
       * This is used to check if the component is a lean shell component which does not require a auth hydrated store.
       */
      const withDryStore = !!Component.LeanShell;
      const skipAuth = !!Component.withoutAuth;

      if (!withDryStore && !skipAuth) {
        if (authQuery) {
          token = authQuery;
        }
      }

      // get initial state of store from token
      newInitialState = await getInitialState(token, !!skipAuth);

      if (signal?.aborted) {
        // if request is aborted, return null
        return null;
      }

      if (!lydiaStore) {
        // create new store if not already initialized
        lydiaStore = StoreFactory(newInitialState, true, withDryStore);
        await lydiaStore.initPromise;
      } else if (lydiaStore?.isDry && !withDryStore) {
        // reinitialize store with new initial state (since we cannot reset the reference of store incase of SSR pages as we pass it to MobxProvider already)
        await lydiaStore.initStore(newInitialState, false);
      } else {
        // if store is present and not dry, check for state and return original store
        lydiaStore = StoreFactory(newInitialState, false, withDryStore);
      }

      if (signal?.aborted) {
        // if request is aborted, return null
        return null;
      }

      try {
        // business pages have business in query, don't run this for stateless pages when request is from dibella as private biz throws 403 err
        if (queryBusiness && (!isDibella() || !Component.isStatelessAllowed)) {
          let bizUrl = `/businesses/${queryBusiness}`;
          const bizQuery: Record<string, any> = {};
          if (Component.withProfileBadges) {
            bizQuery.withBadges = true;
          }
          if (Component.withOnlyProfileHooks) {
            bizQuery.onlyProfileHooks = true;
          }
          bizUrl = appendQuery(bizUrl, bizQuery);
          const businessResponse = await lydiaStore.api.get(bizUrl);

          if (signal?.aborted) {
            // if request is aborted, return null
            return null;
          }

          if (businessResponse.data) {
            queryBusinessResponse = businessResponse.data;
            // if business has a redirect, redirect to the new business page
            if (queryBusinessResponse.redirect) {
              const redirectUrl =
                window.location?.pathname?.replace?.(
                  queryBusiness,
                  queryBusinessResponse.redirect,
                ) || '/';
              router.replace(redirectUrl);
              return null;
            }
          }
          const isSelfBusiness = lydiaStore.isSelfBusiness(queryBusiness);
          if (Component.isProxyAllowed) {
            const isProxyBusiness = lydiaStore.isProxyBusiness(queryBusiness);
            if (isProxyBusiness) {
              updatedStatusCode = 200;
              newPageProps.isProxyBusiness = true;
              newPageProps.proxyBusiness = lydiaStore.proxyBusinesses.find(
                (b: any) => b?.urlKey === queryBusiness,
              );
            }
          } else if (isSelfBusiness || !Component.isPrivatePage || Component.isStatelessAllowed) {
            newInitialState.urlBusinessId = queryBusinessResponse?._id;
          } else if (Component.isPrivatePage) {
            updatedStatusCode = 403;
          }
        }
      } catch (err) {
        if (err.isNetworkError) {
          updatedStatusCode = 408;
          updatedIsNetworkError = true;
        } else {
          updatedStatusCode = (err.response && err.response.status) || 408;
        }
      }

      if (updatedStatusCode < 400) {
        if (
          !lydiaStore.auth &&
          !Component.withoutLogin &&
          !Component.isStatelessAllowed &&
          !Component.LeanShell
        ) {
          updatedStatusCode = 401;
        } else if (Component.withInitialProps) {
          NProgress.start();

          // NOTE - Should ignore the business user check if the page is a public page
          let isBusinessUser = !queryBusiness || !!Component.withoutLogin;
          if (queryBusiness) {
            isBusinessUser = lydiaStore?.auth?.businesses?.some(
              (b: { urlKey: string }) => b.urlKey === queryBusiness,
            );
          }

          try {
            if (!isBusinessUser) {
              const error = new Error('User does not have access to this business') as any;
              error.response = { status: 403 };
              throw error;
            }

            // withInitialProps is a static method on the component that is used to fetch data for the component; It is our own implementation and not NextJs's getInitialProps
            newPageProps = await Component.withInitialProps(
              { query, asPath: router.asPath, isProxyBusiness: newPageProps.isProxyBusiness },
              lydiaStore,
            );

            // if request is aborted, return null
            if (signal?.aborted) return null;
          } catch (err) {
            if (isDev) {
              console.error('Error in `withInitialProps` ->', err);
            }
            updatedIsNetworkError = err.isNetworkError;
            if (isBusinessUser) {
              captureException(err, undefined, {
                extra: {
                  page: router.pathname,
                  isNetworkError: updatedIsNetworkError,
                  statusCode: err.response?.status,
                },
                tags: {
                  componentInitialPropsError: true,
                },
              });
            }
            if (err.response && err.response.status) {
              updatedStatusCode = err.response.status;
              newPageProps.message = err.response.data ? err.response.data.message : err.message;
            } else {
              updatedStatusCode = updatedIsNetworkError ? 408 : 500;
              newPageProps.message = err.message;
            }
          }
          NProgress.done();
        }
      }

      if (router.asPath) {
        const { query: routeQuery, route = {} } = Routes.match(router.asPath) as any;
        newPageProps.pageRoute = {
          query: routeQuery,
          name: route?.name,
          asPath: router.asPath,
        };
      }

      if (queryBusinessResponse) {
        newPageProps.businessResponse = queryBusinessResponse;
      } else if (lydiaStore && lydiaStore?.business && lydiaStore.business?.urlKey) {
        const activeBusinessResponse = await lydiaStore.api.get(
          `/businesses/${lydiaStore.business.urlKey}`,
        );
        if (activeBusinessResponse.data) {
          newPageProps.activeBusinessResponse = activeBusinessResponse.data;
        }
      }
    } catch (e) {
      updatedIsNetworkError = e.isNetworkError;
      if (e.response && e.response.status) {
        updatedStatusCode = e.response.status;
        newPageProps.message = e.response.data ? e.response.data.message : e.message;
      } else {
        updatedStatusCode = e.isNetworkError ? 408 : 500;
        newPageProps.message = e.message;
        captureException(e);
        if (isDev) {
          throw e;
        }
      }
    } finally {
      if (!lydiaStore) {
        lydiaStore = StoreFactory(newInitialState, false, false);
      }
      if (setStore) {
        setStore(() => lydiaStore);
      }
    }

    /**
     * This is used to match a Component with its page props.
     * This is used to identify the page props for a specific page and avoid mixing page props of different pages.
     */
    const uniquePagePropsMatcher = router.pathname;

    return {
      ready: true,
      isRouting: false,
      pauseComponentRender: false,
      rerunInitialProps: false,
      componentIsSSR: false,
      isServerRendered: isServer,
      pageProps: newPageProps,
      statusCode: updatedStatusCode,
      isNetworkError: updatedIsNetworkError,
      initialState: newInitialState,
      uniquePagePropsMatcher,
    };
  };

  /**
   * This effect is used to run the initial props when the page is not ready or when the page is server rendered. This mimics the getInitialProps behavior of NextJs.
   * This effect will also run on page transitions to fetch the new page props.
   */
  useEffect(() => {
    if (isRouterReady && !isRouting && (!isPageReady || isServerRendered || rerunInitialProps)) {
      if (abortControllerRef.current) {
        if (isDev) {
          console.info('useEffect -> Aborting previous request');
        }
        abortControllerRef.current?.abort();
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      runInitialProps(abortController.signal)
        .then((newPageConfig) => {
          if (!abortController?.signal?.aborted && newPageConfig) {
            setPageConfig(newPageConfig);

            // assign page props matcher to the component
            Component.pagePropsMatcher = newPageConfig.uniquePagePropsMatcher;

            // cleanse sensitive data from query params
            if (router?.query && router.query[authQueryKey]) {
              const query = { ...router.query };
              delete query[authQueryKey];
              router.replace(
                {
                  pathname: router.pathname,
                  query,
                  hash: window.location.hash,
                },
                undefined,
                { shallow: true },
              );
            }
          }
        })
        .catch((err) => {
          // ideally should not reach here
          if (isDev) {
            console.error('useEffect -> runInitialProps', err);
          }
        });
    }
  }, [isRouterReady, isPageReady, rerunInitialProps, isRouting, isServerRendered]);

  return pageConfig;
};

export default useClientStateHydration;
