import React, { useCallback, useMemo } from 'react';
import { PremiumPopup, interceptPremiumFeatureMeta, useAccessibility } from '@refrens/jupiter';
import { inject, observer } from 'mobx-react';
import featureMapping from '@refrens/fence/premium/feature-popup-mapping.json';
import JupiterStyleOverride, {
  PremiumPopupWrapper,
} from '@/components/styles/JupiterStyleOverride';

const PremiumAccess = (props) => {
  const {
    store,
    featureKey: feature,
    defaultOpen,
    withoutModal,
    isExplainerModal,
    params,
    withoutMargin = false,
    children,
  } = props || {};

  const { onClick, onCancel, ...restParams } = params || {};
  const { activeBusiness, auth } = store || {};
  const { businesses } = auth || {};

  const businessRecord = useMemo(
    () => (businesses || []).find((b) => b?._id === activeBusiness),
    [activeBusiness, JSON.stringify(businesses || [])],
  );

  const { premium = {}, preferences = {} } = businessRecord || {};
  const { bulkExpenditure = false } = preferences || {};
  const { trialActivated = false, activated = false } = premium || {};
  const showTrialPopup = !(trialActivated || activated);

  const accessibility = useAccessibility();

  const featureMeta = useMemo(() => {
    if (Object.keys(featureMapping).includes(feature)) {
      return featureMapping[feature];
    }

    return interceptPremiumFeatureMeta(feature, businessRecord, accessibility);
  }, [feature, businessRecord, accessibility]);

  const resetPremiumPopup = useCallback(() => {
    if (!withoutModal) {
      store.showPremiumPopup('', false);
    }
    if (onCancel) {
      onCancel();
    }
  }, [withoutModal]);

  return (
    <>
      {children}
      {feature && (
        <JupiterStyleOverride>
          {!isExplainerModal && !bulkExpenditure && (
            <PremiumPopupWrapper
              maxWidth={showTrialPopup ? '388px' : '468px'}
              data-apply={!!feature && withoutModal}
              data-remove-margin={withoutMargin}
            >
              <PremiumPopup
                defaultOpen={!!feature && defaultOpen}
                withoutModal={!!feature && withoutModal}
                variant={showTrialPopup ? 'TRIAL' : 'PREMIUM'}
                feature={featureMeta?.feature}
                title={featureMeta?.title}
                description={featureMeta?.description}
                bannerImage={featureMeta?.bannerImage}
                bannerVideo={featureMeta?.bannerVideo}
                premiumType={featureMeta?.premiumType}
                onCancel={resetPremiumPopup}
                onClick={onClick}
                {...restParams}
              />
            </PremiumPopupWrapper>
          )}
          {isExplainerModal && (
            <PremiumPopupWrapper
              maxWidth='368px'
              data-apply={!!feature && withoutModal}
              data-remove-margin={withoutMargin}
            >
              <PremiumPopup
                defaultOpen={!!feature && defaultOpen}
                withoutModal={!!feature && withoutModal}
                showTag={false}
                variant='FEATURE'
                cta={{
                  label: 'Get Started',
                }}
                onClick={() => {
                  if (onClick) {
                    onClick();
                  }

                  resetPremiumPopup();
                }}
                feature={feature} // case sensitive
                title={featureMeta?.title}
                description={featureMeta?.description}
                bannerImage={featureMeta?.bannerImage}
                bannerVideo={featureMeta?.bannerVideo}
                onCancel={resetPremiumPopup}
                {...restParams}
              />
            </PremiumPopupWrapper>
          )}
        </JupiterStyleOverride>
      )}
    </>
  );
};

const PremiumAccessModule = inject('store')(observer(PremiumAccess));

export default PremiumAccessModule;
