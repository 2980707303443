import React from 'react';
import { Typography } from '@refrens/disco';
import Nbsp from 'react-nbsp';
import { inject, observer } from 'mobx-react';
import { ProfileWrapper, TextWrapper } from '@/components/styles/NotificationTemplateStyles';

const PortfolioBadgeAwarded = (props) => {
  const { params } = props;
  const { portfolio } = params || {};
  const { name: portfolioName = '' } = portfolio || {};

  return (
    <ProfileWrapper>
      <TextWrapper>
        <Typography inline size='h5'>
          Congratulations!
        </Typography>
        <Nbsp />
        <Typography bold inline size='h5'>
          {`${portfolioName || 'Your Project'}`}
        </Typography>
        <Nbsp />
        <Typography inline size='h5'>
          is Featured Project of the month. Share it to celebrate with your network 🎉
        </Typography>
      </TextWrapper>
    </ProfileWrapper>
  );
};

export default inject('store')(observer(PortfolioBadgeAwarded));
