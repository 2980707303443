import React from 'react';
import styled from 'styled-components';
import { Typography, UserAvatar } from '@refrens/disco';
import getOptimizedImage from '@refrens/birds/src/helpers/get-optimized-image';
import getSrcSet from '@refrens/birds/src/helpers/get-src-set';
import Nbsp from 'react-nbsp';
import { useAsync } from 'react-use';
import { inject, observer } from 'mobx-react';
import NextConfig from '@/config';
import getContextBusiness from '@/helpers/getContextBusiness';
import {
  ProfileWrapper,
  TextWrapper,
  ProfileAvatarWrapper,
} from '@/components/styles/NotificationTemplateStyles';

const { publicRuntimeConfig } = NextConfig;
const { isRefrensProd, imageUrl } = publicRuntimeConfig;
const { public: optimizedImageUrl } = imageUrl || {};

const PortfolioApplaud = (props) => {
  const { params, store } = props;
  const { actor, actorBusiness, portfolio } = params || {};
  const { name: userName, avatar, _id: actorId } = actor || {};
  const { name: bizName } = actorBusiness || {};
  const { name: portfolioName } = portfolio || {};

  const { value } = useAsync(async () => {
    return getContextBusiness(store, actorId);
  }, [actorId]);

  const { contextBusiness = {} } = value || {};
  return (
    <ProfileWrapper>
      <ProfileAvatarWrapper>
        <UserAvatar
          size={42}
          src={
            avatar && getOptimizedImage(avatar, { w: 88, h: 88 }, isRefrensProd, optimizedImageUrl)
          }
          srcSet={
            avatar &&
            getSrcSet(
              avatar,
              [
                { w: 88, h: 88 },
                { w: 42, h: 42 },
              ],
              isRefrensProd,
              optimizedImageUrl,
            )
          }
          name={userName}
        />
      </ProfileAvatarWrapper>
      <TextWrapper>
        <Typography bold inline size='h5'>
          {userName?.trim()?.split(' ')[0]}
        </Typography>
        <Nbsp />
        {(contextBusiness?.name || bizName) && (
          <Typography inline size='h5'>
            from {contextBusiness?.name || bizName}
          </Typography>
        )}
        <Nbsp />
        <Typography inline size='h5'>
          applauded your project
        </Typography>
        <Nbsp />
        <Typography bold inline size='h5'>
          {portfolioName}
        </Typography>
      </TextWrapper>
    </ProfileWrapper>
  );
};

export default inject('store')(observer(PortfolioApplaud));
