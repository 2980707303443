import React from 'react';
import { ReCAPTCHA } from 'react-google-recaptcha';
import { inject, observer } from 'mobx-react';
import { observable, makeObservable } from 'mobx';
import { object, func, string } from 'prop-types';
import awaitGlobal from 'await-global';
import NextConfig from '@/config';

import { LowLinkButton } from '@/components/blocks/Button';

const { publicRuntimeConfig } = NextConfig;
const { recaptchaKey } = publicRuntimeConfig;

class Recaptcha extends React.Component {
  visible = false;

  grecaptcha = null;

  constructor(props) {
    super(props);

    makeObservable(this, {
      visible: observable,
      grecaptcha: observable,
    });

    this.store = props.store;
    this.recaptchaRef = React.createRef();
    this.visibleCaptchaFailedCount = 0;
  }

  componentDidMount() {
    this.executeRecaptcha();
    awaitGlobal('grecaptcha').then((grecaptcha) => {
      this.grecaptcha = grecaptcha;
    });
  }

  onError = (err) => {
    const { onToken } = this.props;
    onToken(null);

    this.visible = true;
    this.resetAndExecuteRecaptcha();

    if (err && err.response && err.response.data.message === 'ReCaptchaTokenInvalid') {
      this.visibleCaptchaFailedCount += 1;
      return Promise.resolve();
    }

    return Promise.reject(err);
  };

  resetAndExecuteRecaptcha = () => {
    if (this.visible) {
      this.recaptchaRef.current.reset();
    } else {
      this.executeRecaptcha();
    }
  };

  executeRecaptcha = () => {
    const { pageName } = this.props;
    this.store.recaptchaExecute(pageName).then(this.onInvisibleRecaptchaChange);
  };

  onInvisibleRecaptchaChange = (token) => {
    const { onToken } = this.props;
    return onToken({ 'x-refrens-invisible-captcha': token });
  };

  onRecaptchaChange = (token) => {
    const { onToken } = this.props;
    return onToken({ 'x-refrens-captcha': token });
  };

  render() {
    const { crispReady, openHelp } = this.store;
    return (
      <>
        {this.visible && (
          <>
            {this.grecaptcha && (
              <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={recaptchaKey}
                onChange={this.onRecaptchaChange}
                grecaptcha={this.grecaptcha}
              />
            )}
            <br />
            {crispReady && (this.visibleCaptchaFailedCount > 1 || !this.grecaptcha) && (
              <>
                <LowLinkButton onClick={openHelp}>
                  Recaptcha Failed! Click here for help.
                </LowLinkButton>
                <br />
                <br />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

Recaptcha.propTypes = {
  store: object,
  onToken: func.isRequired,
  pageName: string.isRequired,
};

export default inject('store')(observer(Recaptcha));
