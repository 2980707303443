import React from 'react';
import styled from 'styled-components';
import { Typography } from '@refrens/disco';
import {
  FollowingUser,
  ClapOutline,
  Badge,
  BadgeProfile,
  OutlineDollar,
  AutoAssign,
} from '@refrens/disco/icons';
import moment from 'moment';

import ProfileFollowed from './NotificationTemplates/ProfileFollowed';
import PortfolioApplaud from './NotificationTemplates/PortfolioApplaud';
import PortfolioBadgeAwarded from './NotificationTemplates/PortfolioBadgeAwarded';
import ProfileBadgeAwarded from './NotificationTemplates/ProfileBadgeAwarded';
import NewLeadReceived from './NotificationTemplates/NewLeadReceived';
import ButtonGroupRenderer from './NotificationTemplates/ButtonGroupRenderer';
import NewLeadReOpen from './NotificationTemplates/NewLeadReOpen';
import NewLeadAutoAssign from './NotificationTemplates/NewLeadAutoAssign';

const Wrapper = styled.div`
  background-color: #f7faff;
  max-width: 650px;
  width: 100%;
  cursor: pointer;
  padding: 20px 20px 24px 20px;
  display: flex;
  border-bottom: 1px solid #e3e5e7;
  border-top: 1px solid #e3e5e7;
  flex-direction: column;
  &[data-read='true'] {
    background-color: #fff;
  }
  &[data-expanded='true'] {
    max-width: 100%;
  }
`;

const Header = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 0;
  }
`;

const Body = styled(Header)`
  && {
    margin-top: 12px;
  }
`;

const Footer = styled(Header)`
  && {
    display: flex;
    flex-direction: row;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderIcon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const TitleText = styled(Typography)`
  && {
    line-height: 0;
  }
`;

const notificationTypeObj = {
  PROFILE_FOLLOWED: {
    icon: (props) => <FollowingUser {...props} />,
    text: (props) => <ProfileFollowed {...props} />,
  },
  PORTFOLIO_APPLAUD: {
    icon: (props) => <ClapOutline {...props} />,
    text: (props) => <PortfolioApplaud {...props} />,
  },
  PORTFOLIO_BADGE_AWARDED: {
    icon: (props) => <Badge {...props} />,
    text: (props) => <PortfolioBadgeAwarded {...props} />,
  },
  PROFILE_BADGE_AWARDED: {
    icon: (props) => <BadgeProfile {...props} />,
    text: (props) => <ProfileBadgeAwarded {...props} />,
  },
  LEAD_CREATE: {
    icon: (props) => <OutlineDollar {...props} size={18} color='#516173' />,
    text: (props) => <NewLeadReceived {...props} />,
  },
  LEAD_REOPEN: {
    icon: (props) => <OutlineDollar {...props} size={18} color='#516173' />,
    text: (props) => <NewLeadReOpen {...props} />,
  },
  LEAD_AUTO_ASSIGNED: {
    icon: (props) => <AutoAssign {...props} size={18} color='#516173' />,
    text: (props) => <NewLeadAutoAssign {...props} />,
  },
};

const NotificationData = (props) => {
  const { notificationData = {}, fullPageNotification = false } = props;
  const { created_on: createdAt, message, seen_on: isRead } = notificationData || {};
  const { header, text, extra_data: extraData, actions } = message || {};

  const getParams = () => {
    try {
      return JSON.parse(extraData);
    } catch (e) {
      return {};
    }
  };

  const getNotificationType = (paramsData) => {
    const { messageTxt, notificationType } = paramsData || {};
    if (notificationType) return notificationType;
    if (messageTxt && !notificationType) return 'LEAD_CREATE';
    return null;
  };

  const params = getParams();
  const notificationType = getNotificationType(params);

  return (
    <Wrapper data-read={!!isRead} data-expanded={fullPageNotification}>
      <Header>
        <Title>
          {notificationType && (
            <HeaderIcon>{notificationTypeObj[notificationType]?.icon({ size: 16 })}</HeaderIcon>
          )}
          <TitleText variant='none'>{header}</TitleText>
        </Title>
        {createdAt && <Typography variant='none'>{moment(createdAt).fromNow()}</Typography>}
      </Header>
      <Body>
        {notificationType && (
          <div>{notificationTypeObj[notificationType]?.text({ params, actions })}</div>
        )}
        {!notificationType && <Typography size='h5'>{text}</Typography>}
      </Body>
      <Footer>
        {!!actions?.length && !notificationType && <ButtonGroupRenderer actions={actions} />}
      </Footer>
    </Wrapper>
  );
};

export default NotificationData;
