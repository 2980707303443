import { ButtonGroup, Menu } from '@refrens/disco';
import styled from 'styled-components';

// Till when we have a Flex solution
export const Row = styled.div`
  display: flex;
`;

// Till when we have a Flex solution
export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

// For Buttons (notice plural) which are used in a Row in Table Options
export const SuggestionButtonGroup = styled(ButtonGroup)`
  gap: var(--sizes-smallest);
`;

// For Menu background as Disco menu (unlike godly antd) has transparent background
export const StyledMenu = styled(Menu)`
  && {
    background-color: var(--color-white-lightest);
    box-shadow: var(--shadow-regular);

    ul > li > * {
      height: var(--sizes-h2);
      line-height: var(--sizes-h2);
    }

    .mobile-menu-item {
      @media (min-width: 568px) {
        display: none;
      }
    }
  }
`;

// Temporary fixes till antd menu gets replaced with Disco Menu
export const StyleFixedMenu = styled(Menu)`
  && {
    .ant-dropdown-menu-item {
      font-size: var(--sizes-regular);
      margin-left: 0.75rem;
      padding: var(--sizes-smallest) var(--sizes-small);
    }
  }
`;
