import React, { memo, useEffect, useMemo } from 'react';
import { string, object, bool, arrayOf } from 'prop-types';
import NextHead from 'next/head';
import { pageview } from '@refrens/jupiter';
import NextConfig from '@/config';

const { publicRuntimeConfig } = NextConfig;
const { staticDomain } = publicRuntimeConfig;

function renderOgTags(og, prefix = 'og') {
  return Array.prototype.concat
    .apply(
      [],
      Object.keys(og).map((key) => {
        const property = `${prefix}:${key}`;
        if (og[key]) {
          if (typeof og[key] === 'string') {
            return <meta key={property} property={property} content={og[key]} />;
          }
          if (Array.isArray(og[key])) {
            return og[key].map((o) => <meta key={property} property={property} content={o} />);
          }
          return renderOgTags(og[key], property);
        }
        return null;
      }),
    )
    .filter(Boolean);
}

const Head = (props) => {
  const {
    og,
    title = 'Refrens',
    description,
    canonical,
    noIndex = true,
    noFollow,
    jsonLd = [],
    pageParams,
  } = props;

  useEffect(() => {
    pageview(pageParams, 'LYDIA');
  }, []);

  const openGraph = {
    title,
    description,
    url: canonical,
    image: `${staticDomain}/public/icons/og.png`,
    ...og,
  };

  const robots = useMemo(() => {
    const bots = [];
    if (noIndex) {
      bots.push('noindex');
    }
    if (noFollow) {
      bots.push('nofollow');
    }
    return bots;
  }, [noIndex, noFollow]);

  return (
    <NextHead>
      <title>{title}</title>
      {!!description && <meta name='description' content={description} />}
      {!!canonical && <link rel='canonical' href={canonical} />}
      {robots.length > 0 && <meta name='robots' content={robots.join(', ')} />}
      {renderOgTags(openGraph)}

      <meta name='twitter:site' content='@RefrensApp' />
      <meta name='twitter:card' content={openGraph?.image ? 'summary_large_image' : 'summary'} />
      {/* eslint-disable react/no-danger */}
      {jsonLd.map((ld) => (
        <script
          key={ld['@type']}
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
        />
      ))}
      {/* eslint-enable react/no-danger */}
    </NextHead>
  );
};

Head.propTypes = {
  description: string,
  title: string,
  canonical: string,
  og: object,
  noIndex: bool,
  noFollow: bool,
  jsonLd: arrayOf(object),
};

export default memo(Head);
