import React, { memo, useCallback } from 'react';
import { Button } from '@refrens/disco';
import { ButtonWrapper } from '@/components/styles/NotificationTemplateStyles';

const ButtonGroupRenderer = (props) => {
  const { actions } = props;

  const handleClick = useCallback((url) => {
    if (typeof window !== 'undefined') {
      window.open(url, '_blank');
    }
  }, []);

  if (!Array.isArray(actions)) return null;

  return (
    <ButtonWrapper>
      {(actions || []).map((action, index) => {
        const { name, url } = action || {};
        return (
          <Button
            key={`button-${name}`}
            onClick={() => handleClick(url)}
            size='small'
            variant={index === 0 ? 'secondary' : 'neutral'}
            nature={index === 0 ? 'solid' : 'outlined'}
          >
            {name}
          </Button>
        );
      })}
    </ButtonWrapper>
  );
};

export default memo(ButtonGroupRenderer);
