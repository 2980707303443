import appendQuery from 'append-query';

/**
 * Provides context between user who is viewing the other user content
 * @param {*} store
 * @param {*} actor - folllowee id
 * @returns context business
 */
export default async function getContextBusiness(store, actor) {
  const { auth = {} } = store;
  const { data: userFollowers = {} } = await store.api.get(
    appendQuery(`users/${actor}/followers`, {
      follower: auth._id,
      type: 'USER',
    }),
  );
  const [follower = {}] = userFollowers.data || [];
  return { contextBusiness: follower?.contextBusiness };
}
