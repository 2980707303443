import * as Sentry from '@sentry/node';
import * as SentryIntegrations from '@sentry/integrations';
import { AuroraFlags } from '@refrens/jupiter';

import ignoreErrorList from './ignoreErrorList';

export const captureException = (err, ctx, params = {}) => {
  return Sentry.withScope((scope) => {
    if (err.message) {
      // De-duplication currently doesn't work correctly for SSR / browser errors
      // so we force deduplication by error message if it is present
      scope.setFingerprint([err.message]);
    }
    if (err.statusCode) {
      scope.setExtra('statusCode', err.statusCode);
    }
    scope.setTag('ssr', typeof window === 'undefined');

    // Add extra data from params if available
    if (params?.extra) {
      Object.keys(params.extra).forEach((key) => {
        scope.setExtra(key, params.extra[key]);
      });
    }
    // Add tags from params if available
    if (params?.tags) {
      Object.keys(params.tags).forEach((key) => {
        scope.setTag(key, params.tags[key]);
      });
    }

    // eslint-disable-next-line no-console
    console.trace(err);
    if (ctx) {
      const { req, res, errorInfo, query, pathname, apiresponsefailure } = ctx || {};
      if (res && res.statusCode) {
        scope.setExtra('statusCode', res.statusCode);
      }
      if (apiresponsefailure) {
        scope.setTag('apiresponsefailure', true);
      }
      if (typeof window !== 'undefined') {
        scope.setExtra('query', query);
        scope.setExtra('pathname', pathname);
      } else {
        const extras = {
          url: req?.url,
          method: req?.method,
          headers: req?.headers,
          params: req?.params,
          query: req?.query,
        };
        Object.keys(extras).forEach((key) => {
          if (extras[key] !== undefined && extras[key] !== null) {
            // set only extras that are not undefined || null
            scope.setExtra(key, extras[key]);
          }
        });
      }
      if (errorInfo) {
        Object.keys(errorInfo).forEach((key) => scope.setExtra(key, errorInfo[key]));
      }
    }

    return Sentry.captureException(err);
  });
};

export const sentryInit = (
  environment,
  sentryDSN,
  release = process.env.NEXT_PUBLIC_SENTRY_RELEASE,
) => {
  const isProd = environment === 'production' || environment === 'staging';

  /** @type {Sentry.NodeOptions} */
  const sentryOptions = {
    dsn: isProd ? sentryDSN : null,
    environment: process.env.NEXT_PUBLIC_REFRENS_ENV,
    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    autoBreadcrumbs: true,
    captureUnhandledRejections: true,
    ignoreErrors: ignoreErrorList,
    // key will be changed to denyUrls for version above 7
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // ignore webengage widget errors
      /widgets\.webengage\.com/i,
    ],
  };

  // When we're developing locally
  if (!isProd) {
    // Don't actually send the errors to Sentry
    sentryOptions.beforeSend = () => null;

    // Instead, dump the errors to the console
    sentryOptions.integrations = [
      SentryIntegrations.debugIntegration({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
      SentryIntegrations.extraErrorDataIntegration({
        captureErrorCause: true,
        depth: 3,
      }),
    ];
  } else {
    sentryOptions.beforeSend = (event) => {
      if (!event) return null;

      let error;

      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length === 1
      ) {
        [error] = event.exception.values;
        if (
          error.type === 'UnhandledRejection' &&
          error.value === 'Non-Error promise rejection captured with value: '
        ) {
          return null;
        }

        // block axios 401, 403, 404, 410 errors (causes too much noise)
        if (
          error.type === 'AxiosError' &&
          error.value.match(/Request failed with status code (401|403|404|410)/)
        ) {
          return null;
        }
      }

      /* eslint-disable no-param-reassign */
      // Modify the event here
      event.tags = event.tags || {};

      // add tag to identify errors originating from NextJs server
      event.tags.isServer = typeof window === 'undefined';

      const { isAurora, appVersion, platform } = AuroraFlags.userAgentInfo();

      // add tags to identify errors originating from mobile app webview
      event.tags.isMobileApp = !!isAurora;
      event.tags.mobileAppVersion = isAurora ? appVersion : '-';
      event.tags.mobileAppPlatform = isAurora ? platform : '-';
      /* eslint-enable no-param-reassign */

      return event;
    };
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    captureException,
  };
};
