import React from 'react';
import styled from 'styled-components';
import { Typography, Image } from '@refrens/disco';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
`;

const Description = styled(Typography)`
  && {
    margin-top: 12px;
    max-width: 320px;
  }
`;

const EmptyNotification = () => (
  <Wrapper>
    <Image
      src='https://assets.refrens.com/notification_empty_0f06d43ee1.svg'
      height='200px'
      width='200px'
    />
    <Typography size='h4'>No Notifications here</Typography>
    <Description variant='none'>
      Nothing to worry. We will notify you regarding important activities here.
    </Description>
  </Wrapper>
);

export default EmptyNotification;
