import React from 'react';
import styled from 'styled-components';
import { MarkdownViewer } from '@refrens/disco';

// eslint-disable-next-line import/prefer-default-export
export const MDViewer = styled(MarkdownViewer)`
  && {
    word-break: normal;
    overflow-wrap: anywhere;
    white-space: normal !important;
    .toastui-editor-contents {
      font-size: var(--sizes-h5) !important;
      line-height: 1.5em !important;
      p,
      em,
      b,
      strong,
      ol,
      ul,
      li {
        white-space: normal !important;
        font-size: var(--sizes-h5);
      }
      strong,
      b {
        font-weight: var(--typography-bold-font-weight);
      }
    }
    @media screen and (${(props) => props.theme.screens.lte.xs}) {
      .toastui-editor-contents {
        font-size: var(--sizes-small) !important;
        p,
        em,
        b,
        strong,
        ol,
        ul,
        li {
          font-size: var(--sizes-small);
        }
      }
    }
  }
`;

export const ThumbnailImage = styled.a`
  &,
  img {
    max-width: 64px;
    max-height: 64px;
    width: auto;
    height: auto;
  }
`;

export const ItemNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemName = ({
  itemName,
  itemHsn,
  itemUnit,
  itemThumbnail,
  isThumbnailAsSeparateDiv,
}) => {
  return (
    <ItemNameContainer className='item-name-container'>
      <div>
        {itemName}
        {itemHsn}
        {itemUnit}
      </div>
      {!isThumbnailAsSeparateDiv && itemThumbnail}
    </ItemNameContainer>
  );
};

export const MarketAnchorWrapper = styled.div.attrs(() => ({
  className: 'no-print no-pdf no-dibella',
}))`
  @media print {
    display: none;
  }

  > div {
    margin-bottom: var(--sizes-larger);
  }

  .class__mkt-anchor {
    width: 100%;
  }
`;
