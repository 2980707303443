/* eslint import/no-commonjs: [0] */
const theme = {
  fonts: {
    body: '"Inter", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;',
  },
  colors: {
    primary: '#6539c0',
    secondary: '#fe3e82',
    danger: '#EA453D',
    success: '#52c41a',
    warning: '#faad14',
    info: '#2db7f5',
    gray: '#201742',
    lightGray: '#746f88',
    disabled: 'rgba(158,158,158,0.87)',
    devider: '#e8e8e8',
    progress: '#f5222d',
    offWhite: '#ebe4e4',
    text: {
      light: '#fff',
      // dark: 'rgba(0,0,0,0.85)',
      dark: '#201742',
      secondary: 'rgba(0,0,0,0.50)',
      lightSecondary: 'rgba(255,255,255,0.7)',
      home: {
        copy: 'rgba(32, 23, 66, .6)',
        heading: 'rgba(32, 23, 66, 1)',
      },
    },
    bg: {
      body: '#f3f5f7',
      content: '#fff',
      input: '#fbfbfb',
    },
    social: {
      instagram: '#F00075',
      facebook: '#3B5998',
      twitter: '#1DA1F2',
      linkedin: '#0077B5',
      website: '#6539c0',
      whatsapp: '#25D366',
      gmail: '#E94034',
    },
    scale: ['#6600FF', '#9933FF', '#388E3C', '#201742'],
  },
  sizes: {
    header: {
      height: '56px',
    },
    footer: {
      height: '56px',
    },
    main: {
      width: '1000px',
      paddingTop: '56px',
    },
    largeMain: {
      width: '1200px',
    },
    aside: {
      width: '256px',
    },
  },
  spacing: {
    padding: '10px',
  },
  screen: {
    gte: {
      xxs: 'min-width: 411px',
      xs: 'min-width: 568px',
      s2: 'min-width: 660px',
      sm: 'min-width: 768px',
      md: 'min-width: 992px',
      lg: 'min-width: 1200px',
      xl: 'min-width: 1440px',
    },
    lte: {
      xxs: 'max-width : 411px',
      xs: 'max-width : 568px',
      s2: 'max-width: 660px',
      sm: 'max-width: 768px',
      md: 'max-width: 992px',
      lg: 'max-width: 1200px',
      xl: 'max-width: 1440px',
    },
  },
  breakpoints: {
    aside: 'md',
    inbox: 'xs',
  },
};

module.exports = theme;
