import { createGlobalStyle } from 'styled-components';

import theme from './theme';

const progressHeight = '4px';

/* eslint-disable-next-line no-unused-expressions */
const GlobalStyle = createGlobalStyle`
html{line-height:1.15;min-height:100vh}body{margin:0;height:100%;min-height:100vh}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}[hidden]{display:none}

#nprogress{pointer-events:none}#nprogress .bar{background:${
  theme.colors.progress
};position:fixed;z-index:1031;top:0;left:0;width:100%;height:${progressHeight}}#nprogress .peg{display:block;position:absolute;right:0;width:100px;height:100%;box-shadow:0 0 10px ${
  theme.colors.progress
},0 0 5px ${
  theme.colors.progress
};opacity:1;-webkit-transform:rotate(3deg) translate(0,-4px);-ms-transform:rotate(3deg) translate(0,-4px);transform:rotate(3deg) translate(0,-4px)}

#nprogress .spinner{
  display:block;
  position:fixed;
  z-index:1031;
  top:15px;
  right:50%;
}
#nprogress .spinner-icon{width:18px;height:18px;box-sizing:border-box;border:2px solid transparent;border-top-color:${
  theme.colors.progress
};border-left-color:${
  theme.colors.progress
};border-radius:50%;-webkit-animation:nprogress-spinner .4s linear infinite;animation:nprogress-spinner .4s linear infinite}.nprogress-custom-parent{overflow:hidden;position:relative}.nprogress-custom-parent #nprogress .bar,.nprogress-custom-parent #nprogress .spinner{position:absolute}@-webkit-keyframes nprogress-spinner{0%{-webkit-transform:rotate(0)}100%{-webkit-transform:rotate(360deg)}}@keyframes nprogress-spinner{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}

  html {
    scroll-behavior: smooth;
  }

  p {
    margin: 0;
  }

  [id] {
    scroll-behavior: smooth;
    scroll-margin-top: 86px;
  }

  body {
    font-family : ${theme.fonts.body};
    width : 100%!important;
    font-size: 14px;
    color: ${theme.colors.text.dark};
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #disco-portal-root {
    font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
    input, textarea {
      font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
    }
  }

  h1 {
    margin-top: 0;
    font-size: 1.8em;
    line-height: 1.5;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.5;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 1.17em;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 1em;
  }

  h5 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 0.83em;
  }

  ul, ol {
    font-size: 16px;
    margin-bottom: 16px;
  }

  hr {
    border: 0;
    height: 2px;
    background: ${theme.colors.devider};
    margin-bottom: 16px;
  }
  .sr {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  a {
    text-decoration : underline;
    color: ${theme.colors.primary}
  }

  .ehref {
    &:after{
      // eslint-disable-next-line prettier/prettier
      content : url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' fill='${
        theme.colors.primary
      }' width='1em' height='1em' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none'/><path d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z'/></svg>");
      margin-left : 2px;
      vertical-align : text-top;
    }
  }

  .ht {
    //padding-top : 60px;
    margin-top : -60px;
  }

  .antd-img-crop-modal {
    .ant-modal .ant-modal-body {
      display: flex;
      justify-content: center;
    }
  }

  .Toastify__toast-container {
    z-index: 9999999;
  }

  #crisp-chatbox {
    z-index: 500;
    @media (${(props) => props.theme.lydia.screen.lte.xs}) {
    > div {
      a {
        bottom: 80px !important;
      }
    }
    }
  }

  #buorg {
    z-index: 450;
  }

  @media print {
    -webkit-print-color-adjust: exact;
    @page {
      size: auto;   /* auto is the initial value */
    }
    html {
      zoom: 0.9;
    }
    .no-print, .no-print * {
      display: none !important;
    }
    #nprogress {
      display: none !important;
    }

    #webengage-notification-container {
      display: none !important;
    }

    #webklipper-publisher-widget-container {
      display: none !important;
    }

    .jupiter-universal-card {
      display: none !important;
    }

    .Toastify {
      display: none !important;
    }
  }

  @media screen {
    .print-only {
      display: none;
    }
  }
  @media speech {
    .print-only {
      display: none;
    }
  }

  @media screen and (${(props) => props.theme.lydia.screen.gte.xxs}) {
    .visible-xxs {
      display: block;
    }

    .hidden-xxs {
      display: none;
    }
  }

  .emoji {
    height: 1.2em;
    width: 1.2em;
  }

  .static-header-product {
    z-index: 12;
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .grecaptcha-badge { visibility: hidden; }


  .sticky-page-width-block {
    z-index: var(--z-index-overlay);
    position: sticky;
    left: 0px;
    width: 100vw;
    @media screen and (min-width: 769px) {
      width: calc(100vw - 75px);
    }
    @media screen and (min-width: 992px) {
      left: 10px;
      width: calc(100vw - 95px);
    }
    @media screen and (min-width: 1201px) {
      width: calc(100vw - 95px - (210px * var(--rf-aside-collapsed)));
    }
  }

  .sticky-page-width-table {
    .table-toolbar {
      position: sticky;
      left: 10px;
      width: calc(100vw - 35px);
      @media screen and (min-width: 769px) {
        left: 32px;
        width: calc(100vw - 139px);
      }
      @media screen and (min-width: 992px) {
        left: 42px;
        width: calc(100vw - 159px);
      }
      @media screen and (min-width: 1201px) {
        width: calc(100vw - 159px - (210px * var(--rf-aside-collapsed)));
      }
    }
    .highlight {
      background-color: #f7faff;
      margin: 20px 0 30px;
      padding: 20px;
      border-radius: 8px;
    }
    .advance-filters {
      z-index: 6;
    }
  }
`;

export default GlobalStyle;
