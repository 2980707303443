/**
 * @file router.ts
 * @description
 * This file is responsible for creating a named router instance and adding routes to it.
 * It also exports the router instance and its methods for use in other parts of the application.
 * The router instance is created using the `NamedRouter` class from `@/lib/nextJs/NamedRouter`.
 * The `route-manifest.json` file is used to define the routes and their names.
 */

import { NamedRouter } from '@/lib/nextJs/NamedRouter';
import routeManifest from './route-manifest.json';

const router = new NamedRouter();

/**
 * Converts a Next.js file path to a route pattern for matching using `path-to-regexp`.
 * This will convert dynamic segments (e.g., `[id]`) to named parameters (e.g., `:id`).
 * It will also remove any trailing `index` segments.
 */
const nextPathToPattern = (nextPath: string) => {
  if (!nextPath || nextPath === '/') {
    return nextPath;
  }
  return nextPath
    .replace(/\[(\.{3})?([^[\]]+)\]/g, ':$2') // Convert dynamic segments to named parameters
    .replace(/\/index$/, '') // Remove trailing 'index'
    .replace(/\/$/, ''); // Remove trailing '/'
};

routeManifest.forEach((route) => {
  if (!route.name) {
    throw new Error(
      `Route Manifest is missing a name${route.filePath ? ` for route: ${route.filePath}` : ''}`,
    );
  }
  if (!route.filePath) {
    throw new Error(
      `Route Manifest is missing a nextPath${route.name ? ` for route: ${route.name}` : ''}`,
    );
  }

  // Use the route pattern if provided (for exceptions), otherwise convert the nextPath to a pattern
  const pattern = route.pattern || nextPathToPattern(route.filePath);

  router.add(route.name, pattern, route.filePath);
});

export default router;

export const { Link, Router, routes } = router;
