import urlJoin from 'url-join';
import NextConfig from '@/config';

import Routes from '@/router';

const { publicRuntimeConfig } = NextConfig;

const { baseDomain = '' } = publicRuntimeConfig;

// Change url to correct route
function getRoutePath(route = '', params = {}) {
  if (!route) {
    throw new Error('Route is required');
  }
  return Routes.findByName(route)?.getAs(params);
}

export function getPath(route = '', params = {}, hash = '') {
  const path = getRoutePath(route, params);
  if (hash) {
    return `${path}#${hash}`;
  }

  return path;
}

export function getUrl(route = '', params = {}, hash = '', domain = '') {
  if (domain) {
    return urlJoin(domain, getPath(route, params, hash));
  }
  return urlJoin(baseDomain, getPath(route, params, hash));
}

export default getUrl;
